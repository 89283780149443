import React, { useState, useContext, useMemo, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { ApiContext } from "../../../UseContext/ApiCall";
import AddTags from "../../../Button/AddTags";
import imageCompression from 'browser-image-compression';
import { BASEAPIURL, BASE_URL_STATIC } from "../../../../../config";
import axios from "axios";
import parse from "node-html-parser";
import { IoAddOutline, IoCloseCircle } from "react-icons/io5";
import { BiTrash } from "react-icons/bi";
import CheckInpopup from "../../CheckInpopup";
import ProfileImage from "../../../UseContext/ProfileImage";
import EmojiPopup from "../../EmojiPopup";
import CreateGif from "../CreateGif";
import PostAdvanceSettingPopup from "./PostAdvanceSettingPopup";
import NotificationpostPopup from "../../Notification/NotificationpostPopup";
import { createNotification } from "../../../UseContext/ApiHelpers";

const CreatePhotoAndVideo2 = ({ setSelectedEmoji, selectedEmoji,
  setSelectedPhotoVideo, setCheckIn, setCameraOpen, setOpenGif,
  selectphotoAndVideo,
  location,
  setLocation,
  showPV, setShowPv2, setShowCreatePostPopup }) => {
  const [checkOpen, setCheckOpen] = useState(false)
  const [openEmoji, setOpenEmoji] = useState(false)
  const [description, setDescription] = useState("");
  const [tagsByCarouselItem, setTagsByCarouselItem] = useState([]);
  const [fileUrl, setFileUrl] = useState([])
  const [show, setShow] = useState(false);
  const [errorMsg] = useState('')
  const [postLoader, setPostLoader] = useState(false);
  // const [selectedImage, setSelectedImage] = useState({});
  const [activeIndex, setActiveIndex] = useState(0);

  const { config, loggedInUserName, setPosts, partnerId, loggedInUserId, socket, postType, postOption, PageContent, setCheckinType, gifType, setGifType, selectedAddLocation, setscAllposts, setSelectedAddLocation, setallDataPost, selectedImage, setSelectedImage } = useContext(ApiContext);

  const isPhotoVideo = true

  useMemo(() => {
    if (Array.isArray(selectphotoAndVideo)) {
      const urls = selectphotoAndVideo.map((file) => URL.createObjectURL(file));
      setFileUrl(urls);
      // Cleanup function for revoking object URLs
      return () => {
        urls.forEach((url) => URL.revokeObjectURL(url));
      };
    }

  }, [selectphotoAndVideo]);

  useEffect(() => {
    const root = parse(PageContent);
    setDescription(root.textContent)
  }, [PageContent])

  const inputRef = useRef();

  const handleAddMore = () => {
    inputRef.current.click()
  };

  useMemo(() => setDescription(PageContent), [PageContent]);

  useEffect(() => {
    const root = parse(PageContent);
    setDescription(root.textContent)
  }, [PageContent]);

  const handleSelectPhotoAndVideo = async (e) => {
    // const photoAndVideo = e.target.files;
    const selectedFiles = Array.from(e.target.files);
    setSelectedPhotoVideo(prev => [...selectedFiles, ...prev])
  };

  const handleDelete = () => {

    if (Array.isArray(selectphotoAndVideo) && selectphotoAndVideo.length === 1) {
      setShowCreatePostPopup(true);
      setShowPv2(false)
      setSelectedPhotoVideo([]);
    }

    else if (Array.isArray(selectphotoAndVideo) && selectphotoAndVideo.length > 1) {
      const updatedFiles = [...selectphotoAndVideo];
      updatedFiles.splice(activeIndex, 1); // Remove the file at the active index
      setSelectedPhotoVideo(updatedFiles);
      setActiveIndex(prev => prev - 1);
    }
  };


  // To handle create photo and video
  const handleCreatePhotoAndVideoPopup2 = async (location, selectPhotoAndVideo) => {
    try {
      setPostLoader(true);
      let mediaUrls = [];
      let uploadResponse = '';

      for (let i = 0; i < selectPhotoAndVideo.length; i++) {
        const formData = new FormData(); // Create a new FormData for each file
        if (selectPhotoAndVideo[i].type.slice(0, 5) === 'image') {
          const compressedPhotoAndVideo = await imageCompression(selectPhotoAndVideo[i], postOption);

          formData.append("mediaUrls", selectPhotoAndVideo[i].type === 'image/gif' ? selectPhotoAndVideo[i] : compressedPhotoAndVideo);
          // formData.append("postTumbnailUrl", selectedImage[i]); 
          // Add thumbnail for images
        } else if (selectPhotoAndVideo[i].type.slice(0, 5) === 'video') {
          formData.append("thumbnail", selectedImage[i])
          formData.append("mediaUrls", selectPhotoAndVideo[i]);
          formData.append("postTumbnailUrl", selectedImage[0]); // Add thumbnail for videos
        }
        formData.append("audio", 'https://webaudioapi.com/samples/audio-tag/chrono.mp3');
        formData.append("start", '');
        formData.append("end", '');

        // Make the API call to upload each file
        uploadResponse = await axios.post(`${BASEAPIURL}/post/postdataurl`, formData, config);
        // Get the media URL from the response and push it to mediaUrls array
        const uploadsdata = uploadResponse.data.mediaUrls[0]; // Access mediaUrls correctly
        mediaUrls.push(uploadsdata);
      }

      const mediaPostData = {
        category: "",
        maintype: postType,
        partnerUserId: partnerId,
        description: description,
        title: selectedAddLocation && selectedAddLocation.place,
        emotion: selectedEmoji && selectedEmoji.emotion,
        emotionName: selectedEmoji && selectedEmoji.emotionName,
        emotionIcon: selectedEmoji && selectedEmoji.icon,
        mediaUrls: mediaUrls,
        postTumbnailUrl: uploadResponse.data.postTumbnailUrl || '',
        type: 'post'
      };

      // Call the API to create the media post
      const datamediaPostData = await axios.post(`${BASEAPIURL}/post/createmediapost`, mediaPostData, config);
      const savedPost = datamediaPostData.data.post;

      // Handle uploaded files info and tags
      const uploadedFilesInfo = mediaUrls; // Use mediaUrls, not uploadResponse anymore

      const finalResponse = uploadedFilesInfo.map((fileInfo, index) => {
        const tags = tagsByCarouselItem[index] || [];
        return {
          ...fileInfo,
          tags: tags.map(tag => ({
            x: tag.xVal,
            y: tag.yVal,
            tagUserId: tag.tagUserId,
            fname: tag.fname,
            username: tag.username,
            profileImg: tag.profileImg,
            postedBy: loggedInUserId,
          })),
        };
      });


      // Update the tags in the post
      await axios.post(`${BASEAPIURL}/post/updatetagpost`, {
        id: savedPost._id,
        mediaUrls: finalResponse,
      }, config);

      const finalData = {
        ...savedPost,
        mediaUrls: finalResponse,
      };

      if (postType === 'postType') {
        setallDataPost(prev => [finalData, ...prev]);
      }
      else if (postType === 'scType') {
        setscAllposts(prev => [finalData, ...prev])
      }
      else {
        setPosts(prev => [finalData, ...prev])
      }
      setShowPv2(false);
      setShowCreatePostPopup(false);

      // For notification
      const notificationRes = await axios.post(`${BASEAPIURL}/user/createnotifications`, {
        postId: savedPost._id,
        type: 'tag'
      }, config);

      //notification for tag .........
      const taguserids = finalResponse[0].tags.map(item => item.tagUserId);

      async function createNotifications() {
        for (let ids of taguserids) {
          const data = {
            targetId: ids,
            userType: "user",
            targetEvent: "tag",
            targetType: savedPost.type,
            targetEventId: savedPost._id,
            notificationType: "email",
          };

          try {
            await createNotification(data, config);

          } catch (error) {
            console.error("Error creating notification for user", ids, error);
          }
        }
      }
      createNotifications();

      notificationRes.data.tagdata.forEach(e => {
        const notificationData = {
          type: e.type,
          action: e.action,
          senderUserId: e.senderUserId,
          receiverUserId: e.receiverUserId,
          message: e.message,
          createdAt: e.createdTime,
          fname: e.fname,
          postId: e.postId,
          postImg: e.postImg,
          profileImg: e.profileImg,
          tumbnailImg: e.tumbnailImg,
        };

        socket.emit("send_message_emilo_notification", notificationData);
      });
    } catch (err) {
      setShowPv2(false);
      setPostLoader(false);
      setTagsByCarouselItem([])
    }
    finally {
      setPostLoader(false);
    }
  };


  const handleAddLocation = () => {
    setCheckOpen(true);
    setShowPv2(false);
  };

  const handleCancleLocation = () => {
    setSelectedAddLocation(null);
  };

  const handleClose = () => {
    setShowPv2(false);
    setCheckOpen(false);
    setSelectedAddLocation(null);
    setSelectedEmoji(null);
    setSelectedPhotoVideo([]);
    setTagsByCarouselItem([]);
    setGifType("");
    setDescription("");
    setFileUrl([]);
    setActiveIndex(0);
  }

  // ----- Advance Setting -----

  const [showPostAdvanceSetttingPopup, setShowPostAdvanceSetttingPopup] = useState(false);

  return (
    <>
      <PostAdvanceSettingPopup
        showPostAdvanceSetttingPopup={showPostAdvanceSetttingPopup}
        setShowPostAdvanceSetttingPopup={setShowPostAdvanceSetttingPopup}
        setShowPv2={setShowPv2}
      />

      <NotificationpostPopup show={show} setShow={setShow} errorMsg={errorMsg} />

      <CreateGif gifType={gifType} setShowPv2={setShowPv2} setOpenEmoji={setOpenEmoji} setSelectedPhotoVideo={setSelectedPhotoVideo} selectphotoAndVideo={selectphotoAndVideo} selectedEmoji={selectedEmoji} setSelectedEmoji={setSelectedEmoji} openEmoji={openEmoji} close={() => setOpenGif(false)} setShowCreatePostPopup={setShowCreatePostPopup} />

      <CheckInpopup location={location} open={checkOpen} close={() => { setCheckOpen(false); }} setShowPv2={setShowPv2} setLocation={setLocation} checkinType="post" />

      <section>
        <Modal show={showPV} onHide={() => { handleClose() }} className='create-post m-0' backdrop="static" centered >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            {
              openEmoji
                ?
                <>
                  <div className="row">
                    <p className="col-1" style={{ cursor: "pointer" }}
                      onClick={() => { setOpenEmoji(false) }}
                    >
                      <FontAwesomeIcon icon={faAngleLeft} className="back-icon" />
                    </p>
                    <p className="col-11 fs-6 fw-bold text-center mb-2">
                      Create Post
                    </p>
                  </div>

                  <EmojiPopup setOpenEmoji={setOpenEmoji} selectedEmoji={selectedEmoji} setSelectedEmoji={setSelectedEmoji} />
                </>
                :
                <>
                  <section className="create-reels-1" id="create-reels-1">
                    <div className="row">
                      <p className="col-1" style={{ cursor: "pointer" }}
                        onClick={() => { setShowCreatePostPopup(true); setShowPv2(false); setSelectedPhotoVideo([]) }}
                      >
                        <FontAwesomeIcon icon={faAngleLeft} className="back-icon" />
                      </p>
                      <p className="col-11 fs-6 fw-bold text-center mb-2">
                        Create Post
                      </p>
                    </div>

                    <div className="userDetail">
                      <div className="imgBox" style={{ marginLeft: '15px' }}>
                        <ProfileImage userId={loggedInUserId} height='50' width='50' borderRadius='50%' />
                      </div>
                      <div className="nameBox">
                        <div className="d-flex flex-md-row align-items-center">
                          <span className="fs-6">
                            {loggedInUserName}
                            {selectedEmoji && (
                              <span className="ms-1 d-inline-block" onClick={() => setOpenEmoji(true)}>
                                {selectedEmoji.emotion}
                                <img style={{ width: "20px", display: "inline", marginLeft: "5px", marginRight: "5px" }} src={selectedEmoji.icon} alt="icon" />
                                {selectedEmoji.emotionName}
                              </span>
                            )}
                          </span>
                        </div>

                        <div className="add-location-div">
                          <i className="fas fa-map-marker-alt" style={{ padding: '3px 3px 3px 0px' }} />

                          {selectedAddLocation ?
                            <>
                              <small className="text-truncate" style={{ maxWidth: "300px", cursor: 'pointer' }}>{selectedAddLocation.place}</small>
                              <span className="fs-5 cp" style={{ color: 'rgb(207 99 109)' }} onClick={handleCancleLocation}> <IoCloseCircle /></span>
                            </>
                            :
                            <small className="cp" onClick={handleAddLocation}>Add Location</small>
                          }

                          {/* <small onClick={handleAddLocation} className="cp">Add Location</small> */}
                          {/* <AddLocation location={location} setLocation={setLocation} /> */}
                        </div>
                      </div>
                    </div>

                    <div>
                      <textarea
                        style={{ resize: "none", width: "97%" }}
                        className='m-2 border border-1 rounded-2 p-2'
                        name="textArea"
                        id="textArea"
                        rows="3"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        //  defaultValue={}
                        placeholder="description"
                      ></textarea>
                    </div>

                    <div className="position-relative">

                      <div className="d-flex justify-content-between align-items-center m-2">
                        <button onClick={handleAddMore} className="pv_btn" style={{ color: "#64A6F0" }}> <IoAddOutline className="fw-bold" size={15} /> Add </button>

                        <button className="pv_dlt" onClick={handleDelete}>
                          <BiTrash size={16} />
                        </button>
                      </div>

                      <AddTags photoVideo={isPhotoVideo} tagsByCarouselItem={tagsByCarouselItem} setTagsByCarouselItem={setTagsByCarouselItem} selectphotoAndVideo={selectphotoAndVideo} fileUrl={fileUrl} setCheckOpen={setCheckOpen} setShowPv2={setShowPv2} selectedImage={selectedImage} setSelectedImage={setSelectedImage} />

                      <input type="file" className="d-none" ref={inputRef} onChange={handleSelectPhotoAndVideo} accept="image/jpeg, image/png, image/gif, video/mp4, video/quicktime, video/webm" multiple />

                    </div>

                    {/* {thumbnail &&
                      <GetThumbnai videoIndex={videoIndex} selectedImage={selectedImage} setSelectedImage={setSelectedImage} file={selectphotoAndVideo[videoIndex]} status={status} setStatus={setStatus} />
                    } */}

                    <div className="iconBox iconBox_new">

                      <span className="c_new" onClick={() => { setCameraOpen(true); setShowPv2(false); }} style={{ cursor: "pointer" }}>
                        <img src={`${BASE_URL_STATIC}/create-icon2.png`} alt="" />
                        <span>Camera </span>
                      </span>

                      <span className="c_new" onClick={handleAddMore} style={{ cursor: "pointer" }}>
                        <img src={`${BASE_URL_STATIC}/create-icon3.png`} alt="create-icon3" />
                        <span> photo/video </span>
                      </span>


                      <span className="c_new" style={{ cursor: 'pointer' }} onClick={() => { setOpenEmoji(true); }}>
                        <img src={`${BASE_URL_STATIC}/create-icon8.png`} alt="" />
                        <span>Activity </span>
                      </span>

                      <span className="c_new" style={{ cursor: 'pointer' }} onClick={() => { setCheckinType("post"); setCheckIn(true); setShowPv2(false); }}>
                        <img src={`${BASE_URL_STATIC}/create-icon9.png`} alt="" />
                        <span> Check in </span>
                      </span>

                      <span className="c_new" style={{ cursor: 'pointer' }} onClick={() => { setOpenGif(true); setShowPv2(false); setGifType("post") }}>
                        <img src={`${BASE_URL_STATIC}/create-icon10.png`} alt="" />
                        <span>GIF </span>
                      </span>

                      <span className="c_new" style={{ cursor: 'pointer' }}>
                        <img src={`${BASE_URL_STATIC}/create-icon13.png`} alt="" />
                        <span>Collage </span>
                      </span>

                    </div>

                    <div className='col-12 m-auto'>
                      <button disabled={postLoader} onClick={() => handleCreatePhotoAndVideoPopup2(location, selectphotoAndVideo)} className='text_post_btn'>{postLoader ? <span className='flex justify-content-center'><FontAwesomeIcon icon={faCircleNotch} style={{ color: "snow" }} spin /> posting...</span> : 'post'}</button>
                    </div>

                    <div className="text-center text-primary cursor-pointer mt-2"
                      onClick={() => {
                        setShowPostAdvanceSetttingPopup(true);
                        setShowPv2(false);
                      }}
                    >Advance Setting</div>
                  </section>
                </>
            }
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
};

export default CreatePhotoAndVideo2;