import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PhoneInput from "react-phone-number-input";
// import { ApiUrl, GenerateSecretAdminUrl, headerAPI, VerifyTokenAdminUrl } from '../../../../config';
// import { CCard, CCardBody, CButton, CRow, CCol } from '@coreui/react';
// import PropTypes from 'prop-types'
// import { cilChevronRight, cilEnvelopeLetter, cilPhone, cilSettings } from '@coreui/icons';
// import CIcon from '@coreui/icons-react';
import copy from 'copy-to-clipboard';
import ToggleButton from '../../../../Common/ToggleButton';
import { BASEAPIURL, headerUserAPI } from '../../../../../config';
import { CButton, CForm } from '@coreui/react';

const TwoFactorPage = () => {
    const [confirmOff, setConfirmOff] = useState(false);
    const [Message, setMessage] = useState({ status: 0, message: '' });
    function showMessage(message) {
        if (message.status && Number(message.status) === 1) {
            return <div className='text-success mt-2'>{message.message}</div>;
        }
        return <div className='text-danger mt-2'>{message.message}</div>;
    }
    const [TwoFactor, setTwoFactorValue] = useState(false);
    function setTwoFactor(v) {
        localStorage.setItem('TwoFactorValue', v ? 1 : 0);
        setTwoFactorValue(v);
    }
    const [Type, setType] = useState('');
    const [secret, setSecret] = useState('');
    const [qrCodeUrl, setQrCodeUrl] = useState('');
    const [token, setToken] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobileOnly] = useState('');
    function setMobile(v) {
        setMessage({ status: 0, message: '' });
        setMobileOnly(v);
        setDeviceTitle(`My Mobile ${v}`);
    }
    const [DeviceTitle, setDeviceTitle] = useState('');
    const [verificationResult, setVerificationResult] = useState(null);
    const [buttonText, setButtonText] = useState('Copy key');

    function clearOldData() {
        setDeviceTitle('');
        setToken('');
        setVerificationResult(null);
        setMessage({ status: 0, message: '' });
    }

    const generateSecret = async () => {
        try {
            const headers = headerUserAPI();
            const response = await axios.post(`${BASEAPIURL}/user/generate-secret`, { get: new Date() }, { headers: headers });
            if (response.data && response.data.secret) {
                setSecret(response.data.secret);
                setQrCodeUrl(response.data.qrCodeUrl);
            } else {
                setSecret('');
                setQrCodeUrl('');
            }
        } catch(e) {
            setSecret('');
            setQrCodeUrl('');
        }
    };

    const verifyToken = async () => {
        const headers = headerUserAPI();
        if (DeviceTitle && token) {
            const response = await axios.post(`${BASEAPIURL}/user/verify-token`, { token, secret, title: DeviceTitle }, { headers: headers });
            setVerificationResult(response.data.verified);
            if (response.data.verified) {
                getVerificationMethods();
                setType('listAuthApp');
            }
            return true;
        } else {
            alert('The device name and code are mandatory.');
            return false;
        }
    };

    const handleClick = () => {
        copy(secret);
        setButtonText("copied");
    };

    async function TwoFactorOption(value) {
        if (!value) {
            setConfirmOff(true);
            return false;
        }
        await updateTwoFactorOption(value);
        return true;
    }

    async function updateTwoFactorOption(value) {
        setTwoFactor(value)
        try {
            const headers = headerUserAPI();
            const data = await axios.post(`${BASEAPIURL}/user/switch2Factor`, { value, ...Message }, { headers: headers })
            setMessage({ ...Message, ...data.data });
            if (data.data.status) {
                setTimeout(() => {
                    setConfirmOff(false);
                }, 2000);
            }
            // response.data.loggedToken
            // handle success or perform any necessary action
        } catch (error) {
            console.error('Error making post request:', error.message)
            // handle error or display error message
        }
    }

    const [verificationMethods, setVerificationMethods] = useState('')
    async function getVerificationMethods() {
        try {
            const headers = headerUserAPI();
            const response = await axios.post(`${BASEAPIURL}/user/verification-method`, { get: new Date() }, { headers: headers });
            if (response.data && response.data.data && response.data.data.length > 0) {
                setVerificationMethods(response.data.data); // Assuming verificationMethods is the correct data
            } else {
                setVerificationMethods([]);
            }
            if (response.data && response.data.twoFactor) {
                setTwoFactor(true);
            } else {
                setTwoFactor(false);
            }
        } catch (error) {
            console.error('Error making post request:', error.message);
            // You could also handle displaying error feedback to the user here
        }
    }

    async function deleteAuthApp(v) {
        try {
            const headers = headerUserAPI();
            await axios.post(`${BASEAPIURL}/user/deleteAuthApp`, v, { headers: headers });
            getVerificationMethods();
        } catch (error) {
            console.error('Error making post request:', error.message);
            // You could also handle displaying error feedback to the user here
        }
    }

    // for Mobile Verifiaction will be here
    async function generateMobileOtp(v) {
        if (!mobile && mobile !== true && mobile !== '') {
            setMessage({ status: 0, message: 'Please Enter your Mobile to Continue.' });
            return false;
        }
        const tokenExists = verificationMethods.some(item => item.token === mobile);
        if (tokenExists) {
            setMessage({ status: 0, message: `The mobile number (${mobile}) is already linked to your account for two-factor authentication.` });
            return false;
        }
        try {
            const headers = headerUserAPI();
            await axios.post(`${BASEAPIURL}/user/mobile/generateOtp`, {...v, mobile}, { headers: headers });
            setType('verifyMobile')
        } catch (error) {
            setMessage({ status: 0, message: error.message });
            // You could also handle displaying error feedback to the user here
        }
    }
    async function verifyMobileOtp(v) {
        if (!mobile && mobile !== true && mobile !== "") {
            setMessage({ status: 0, message: 'Please Enter your Mobile to Continue.' });
            return false;
        }
        try {
            const headers = headerUserAPI();
            const data = await axios.post(`${BASEAPIURL}/user/mobile/verifyOtp`, {...v, mobile, otp: token, title: DeviceTitle}, { headers: headers });
            const temp = data.data;
            setMessage({ status: temp.status, message: temp.message });
            if (temp.status) {
                getVerificationMethods();
                setType('listMobile')
            }
        } catch (error) {
            setMessage({ status: 0, message: error.message });
            // You could also handle displaying error feedback to the user here
        }
    }
    // for Mobile Verifiaction will be here

    // for email Verifiaction will be here
    async function generateEmailOtp(v) {
        if (!email && email !== '') {
            setMessage({ status: 0, message: 'Please Enter your Email to Continue.' });
            return false;
        }
        const tokenExists = verificationMethods.some(item => item.token === email);
        if (tokenExists) {
            setMessage({ status: 0, message: `The Email (${email}) is already linked to your account for two-factor authentication.` });
            return false;
        }
        try {
            const headers = headerUserAPI();
            await axios.post(`${BASEAPIURL}/user/email/generateOtp`, {...v, email}, { headers: headers });
            setType('verifyEmail')
        } catch (error) {
            setMessage({ status: 0, message: error.message });
            // You could also handle displaying error feedback to the user here
        }
    }
    async function verifyEmailOtp(v) {
        if (!email && email !== '') {
            setMessage({ status: 0, message: 'Please Enter your Email to Continue.' });
            return false;
        }
        try {
            const headers = headerUserAPI();
            const data = await axios.post(`${BASEAPIURL}/user/email/verifyOtp`, {...v, email, otp: token, title: DeviceTitle}, { headers: headers });
            const temp = data.data;
            setMessage({ status: temp.status, message: temp.message });
            if (temp.status) {
                getVerificationMethods();
                setType('listEmail')
            }
        } catch (error) {
            setMessage({ status: 0, message: error.message });
            // You could also handle displaying error feedback to the user here
        }
    }
    // for email Verifiaction will be here

    function maskedValue(phoneNumber, slice = 4) {
        // Remove spaces and special characters for simplicity
        // const cleaned = phoneNumber.replace(/[^\d]/g, '');
        const cleaned = phoneNumber;
    
        // Check if phone number has at least 4 digits
        if (cleaned.length <= 4) {
            return cleaned; // No need to mask if the number is too short
        }
    
        // Mask all digits except the last 2 or last 4 based on length
        const maskLength = cleaned.length > slice + 3 ? cleaned.length - slice : cleaned.length - 2;
        const maskedPart = '*'.repeat(maskLength);
        const visiblePart = cleaned.slice(-slice); // Get last 4 digits for long numbers, else last 2 digits
    
        return `${maskedPart}${visiblePart}`;
    }

    useEffect(() => {
        getVerificationMethods();
        // Create a new link element for the CSS file
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = '/assets/css/TwoFactorOtherPage.css'; // Replace with the path to your CSS file
        // Append the link element to the document head
        document.head.appendChild(link);
        // Cleanup function to remove the link when the component unmounts
        return () => {
            document.head.removeChild(link);
        };
      }, []);

    return (
        <div className="card boxShadow rounded-0">
            <div className="card-body">
                {Type === '' && (
                    <>
                        {confirmOff && (
                            <CForm
                                className="mb-4 border border-gray-300 p-4 rounded-md shadow-md"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    updateTwoFactorOption(false);
                                }}
                            >
                                <div className="form-group">
                                    <h6 className="text-danger">
                                        Please enter your current password to disable two-factor authentication.
                                    </h6>
                                    <input
                                        required
                                        placeholder="Enter your current password"
                                        type="password"
                                        name="password"
                                        className="form-control"
                                        onChange={(e) => {
                                            setMessage({ ...Message, password: e.target.value, message: '' });
                                        }}
                                    />
                                </div>
                                {showMessage(Message)}
                                <div className="form-group mt-2 d-flex justify-content-between">
                                    <button type="submit" className="btn btn-sm btn-success">Confirm</button>
                                    <button
                                        type="button"
                                        className="btn btn-sm btn-danger"
                                        onClick={() => {
                                            setConfirmOff(false);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                                <div style={{ clear: 'both' }}></div>
                            </CForm>
                        )}
                        <h5 className='mb-4'>
                            Two-factor authentication
                            <div className='pull-right'>
                                <ToggleButton Option={TwoFactor} setOption={TwoFactorOption} />
                            </div>
                            <div style={{ clear: 'both' }}></div>
                        </h5>
                        <p className='mb-2'>If we detect a login attempt from an unrecognized device or browser, we'll require both a code and your password for added security.</p>
                        {TwoFactor && (
                            <>
                                <div
                                    className="p-3 two_facetor_type"
                                    onClick={() => {
                                        const filteredData = verificationMethods.filter(item => item.type === "authApp");
                                        clearOldData();
                                        if (filteredData && filteredData.length > 0) {
                                            setType('listAuthApp');
                                        } else {
                                            setType('authApp');
                                            generateSecret();
                                        }
                                    }}
                                >
                                    <div className="arrow pull-right mt-3">
                                        <i className="fa fa-arrow-right me-3"></i>
                                    </div>
                                    <div className='d-flex' style={{ alignItems: 'center' }}>
                                        <i className="fa fa-cogs me-3"></i>
                                        <div>
                                            <b>Authentication app</b>
                                            <br />
                                            <small>You&apos;ll get a login code from your authentication app.</small>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className="p-3 two_facetor_type"
                                    onClick={() => {
                                        const filteredData = verificationMethods.filter(item => item.type === "mobile");
                                        clearOldData();
                                        if (filteredData && filteredData.length > 0) {
                                            setType('listMobile');
                                        } else {
                                            setType('mobile');
                                            generateSecret();
                                        }
                                    }}
                                >
                                    <div className="arrow pull-right mt-3">
                                        <i className="fa fa-arrow-right me-3"></i>
                                    </div>
                                    <div className='d-flex' style={{ alignItems: 'center' }}>
                                        <i className="fa fa-phone me-3"></i>
                                        <div>
                                            <b>Text message</b>
                                            <br />
                                            <small>We&apos;ll send a code to your mobile via SMS.</small>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className="p-3 two_facetor_type"
                                    onClick={() => {
                                        clearOldData();
                                        const filteredData = verificationMethods.filter(item => item.type === "email");
                                        if (filteredData && filteredData.length > 0) {
                                            setType('listEmail');
                                        } else {
                                            setType('email');
                                        }
                                    }}
                                >
                                    <div className="arrow pull-right mt-3">
                                        <i className="fa fa-arrow-right me-3"></i>
                                    </div>
                                    <div className='d-flex' style={{ alignItems: 'center' }}>
                                        <i className="fa fa-envelope me-3"></i>
                                        <div>
                                            <b>Email</b>
                                            <br />
                                            <small>We&apos;ll send a code to your email.</small>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                )}

                {Type === 'listAuthApp' && (
                    <div>
                        {verificationMethods.filter(item => item.type === "authApp").map((s) => (
                            <div key={s._id}>
                                <div style={{ float: 'right' }}>
                                    <a
                                        href="/"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            deleteAuthApp(s);
                                        }}
                                    >
                                        <i className="text-danger fa fa-trash"></i>
                                    </a>
                                </div>
                                <b>
                                    {s.title}
                                    <br />
                                    <small>Added on {s.createdAt}</small>
                                </b>
                                <div style={{ clear: 'both' }}></div>
                                <hr />
                            </div>
                        ))}
                        <center>
                            <a
                                href="/"
                                onClick={(e) => {
                                    generateSecret();
                                    e.preventDefault();
                                    setType('authApp');
                                }}
                                className="btn btn-sm btn-success"
                            >
                                Add New Authentication app
                            </a>
                            &nbsp;&nbsp;
                            <a
                                href="/"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setType('');
                                    clearOldData();
                                }}
                                className="btn btn-sm btn-secondary"
                            >
                                Back
                            </a>
                        </center>
                    </div>
                )}

                {Type === 'authApp' && (
                    <div>
                        {/* {console.error(verificationMethods)}     */}
                        {qrCodeUrl && (
                            <div>
                                <div className='card'>
                                    <h4 className='mb-4' style={{ fontSize: "22px", textAlign: "left", color: "#3E4347" }}>Setup Instruction</h4>
                                    <h4 style={{ fontSize: "22px", textAlign: "left", color: "#006DFF" }}>Step 1</h4>
                                    <p style={{ fontSize: "17px ", color: "#000000", fontWeight: "bold" }}>Download an authentication app.</p>
                                    <p style={{ color: "#848484" }}>We recommend downloading Duo Mobile or Google Authenticator if you don&apos;t have them installed.</p>
                                    <div className="d-flex  flex-wrap">
                                        <a target="_BLANK" rel="noopener noreferrer" className="btn m-1 btn-sm text-white btn-secondary" href="https://play.google.com/store/apps/details?id=com.duosecurity.duomobile">Duo Android</a>
                                        <a target="_BLANK" rel="noopener noreferrer" className="btn m-1 btn-sm text-white btn-secondary" href="https://apps.apple.com/us/app/duo-mobile/id422663827">Duo iPhone</a>
                                        <a target="_BLANK" rel="noopener noreferrer" className="btn m-1 btn-sm text-white btn-secondary" href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2">Google Authenticator Android</a>
                                        <a target="_BLANK" rel="noopener noreferrer" className="btn m-1 btn-sm text-white btn-secondary" href="https://apps.apple.com/us/app/google-authenticator/id388497605">Google Authenticator iPhone</a>
                                    </div>

                                    <h4 className='mt-4' style={{ fontSize: "22px", textAlign: "left", color: "#006DFF" }}>Step 2</h4>
                                    <p style={{ fontSize: "17px ", color: "#000000", fontWeight: "bold" }}>Scan this barcode/QR Code or copy the key.</p>
                                    <p style={{ color: "#848484" }}>We recommend downloading Duo Mobile or Google Authenticator if you dont have them installed.</p>

                                    <div className="card-body p-3 border border col-lg-9" style={{
                                        background: '#F5F5F5',
                                        borderRadius: '23px',
                                        opacity: 1
                                    }}>
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="card-body">
                                                    <img src={qrCodeUrl} alt="QR Code" width={170} style={{ borderRadius: "15px" }} />
                                                </div>
                                            </div>

                                            <div className="col-md-2" style={{ position: "relative" }}>
                                                <p className='text-center mt-5' style={{ position: "absolute", top: "20px" }}>OR</p>
                                            </div>

                                            <div className="col-md-5">
                                                <div className="card mt-3" style={{ border: 'none', borderRadius: "10px", }}>
                                                    <div className="card-body text-center h-6">
                                                        <p style={{ fontWeight: "bold" }}>{secret}</p>
                                                        <button onClick={handleClick} style={{ borderRadius: "15px", background: '#5367F9 0% 0% no-repeat padding-box' }}>{buttonText}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h4 className='mt-4' style={{ fontSize: "22px", textAlign: "left", color: "#006DFF" }} >Step 3</h4>
                                <p style={{ fontSize: "17px", color: "#000000", fontWeight: "bold" }}>copy and enter 6-digit code  </p>
                                <p style={{ color: "#848484" }}>After the barcode/QR code is scanned or the key is entered, your authentication app will generate a 6-digit code. <br />
                                    Copy the code and then come back to Facebook to enter it.</p>

                                <div className="d-flex mt-2 justify-content-between">
                                    <button
                                        type="button"
                                        className="btn btn-success"
                                        onClick={() => {
                                            setType('verifyAuthApp');
                                            clearOldData();
                                        }}
                                    >
                                        Next
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        onClick={() => {
                                            setType('')
                                            clearOldData();
                                        }}
                                    >
                                        Back
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                )}

                {Type === 'verifyAuthApp' && (
                    <CForm
                        onSubmit={(e) => {
                            e.preventDefault();
                            setVerificationResult(null);
                            verifyToken();
                        }}
                    >
                        <p className="mt-3" style={{ fontSize: "17px", color: "#000000", fontWeight: "bold" }}>
                            Name this device. This will help identify each device associated with the account.
                        </p>
                        <input
                            className="form-control"
                            required
                            type="text"
                            value={DeviceTitle}
                            onChange={(e) => {
                                setDeviceTitle(e.target.value);
                                setVerificationResult(null);
                            }}
                            placeholder="Name this Device"
                        />
                        <p className="mt-3" style={{ fontSize: "17px", color: "#000000", fontWeight: "bold" }}>
                            Enter the code from the Authenticator App below:
                        </p>
                        <input
                            className="form-control"
                            type="text"
                            required
                            value={token}
                            onChange={(e) => {
                                setToken(e.target.value);
                                setVerificationResult(null);
                            }}
                            placeholder="Enter token"
                        />
                        {verificationResult !== null && verificationResult !== "none" && (
                            <p>{verificationResult ? (
                                <div className="text-success mt-2">You have verified Auth App.</div>
                            ) : (
                                <div className="text-danger mt-2">This code isn't right. Please try again.</div>
                            )}</p>
                        )}
                        <div className="d-flex mt-2 mb-3 justify-content-between">
                            <button type="submit" className="btn btn-success">
                                Verify Token
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() => {
                                    setType('authApp')
                                    clearOldData();
                                }}
                            >
                                Back
                            </button>
                        </div>
                    </CForm>
                )}

                {Type === 'listMobile' && (
                    <div>
                        {verificationMethods.filter(item => item.type === "mobile").map((s) => (
                            <div key={s._id}>
                                <div style={{ float: 'right' }}>
                                    <a
                                        href="/"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            deleteAuthApp(s);
                                        }}
                                    >
                                        <i className="text-danger fa fa-trash"></i>
                                    </a>
                                </div>
                                <b>
                                    {s.title}
                                    <br />
                                    <small>Added {maskedValue(s.token, 4)} on {s.createdAt}</small>
                                </b>
                                <div style={{ clear: 'both' }}></div>
                                <hr />
                            </div>
                        ))}
                        <center>
                            <a
                                href="/"
                                onClick={(e) => {
                                    generateSecret();
                                    e.preventDefault();
                                    setType('mobile');
                                }}
                                className="btn btn-sm btn-success"
                            >
                                Add New Mobile
                            </a>
                            &nbsp;&nbsp;
                            <a
                                href="/"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setType('');
                                    clearOldData();
                                }}
                                className="btn btn-sm btn-secondary"
                            >
                                Back
                            </a>
                        </center>
                    </div>
                )}
                {Type === 'mobile' && (
                    <CForm
                        onSubmit={(e) => {
                            e.preventDefault();
                            generateMobileOtp();
                        }}
                    >
                        <p className="mt-2 mb-2" style={{ fontSize: "17px", color: "#000000", fontWeight: "bold" }}>
                            Enter the Mobile number below:
                        </p>
                        <PhoneInput
                            value={mobile}
                            required
                            className="TwoFactorMobile"
                            onChange={setMobile}
                            defaultCountry="IN"
                            placeholder="Mobile"
                        />
                        {showMessage(Message)}
                        <div className="d-flex mt-3 justify-content-between">
                            <CButton type="submit" className="btn btn-success">
                                Next
                            </CButton>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() => {
                                    setType('');
                                    clearOldData();
                                }}
                            >
                                Back
                            </button>
                        </div>
                    </CForm>
                )}
                {Type === 'verifyMobile' && (
                    <CForm
                        onSubmit={(e) => {
                            e.preventDefault();
                            verifyMobileOtp();
                        }}
                    >
                        <p className="mt-3" style={{ fontSize: "17px", color: "#000000", fontWeight: "bold" }}>
                            Name this Mobile. This will help identify each mobile associated with the account.
                        </p>
                        <input
                            className="form-control"
                            type="text"
                            required
                            value={DeviceTitle}
                            onChange={(e) => {
                                setDeviceTitle(e.target.value)
                                setMessage({ status: 0, message: '' });
                            }}
                            placeholder="Enter token"
                        />
                        <p className="mt-3" style={{ fontSize: "17px", color: "#000000", fontWeight: "bold" }}>
                            Enter the OTP below:
                        </p>
                        <input
                            className="form-control"
                            type="number"
                            required
                            value={token}
                            onChange={(e) => {
                                setToken(e.target.value)
                                setMessage({ status: 0, message: '' });
                            }}
                            placeholder="Enter OTP"
                        />
                        {showMessage(Message)}
                        <div className="d-flex mt-2 justify-content-between">
                            <button type="submit" className="btn btn-success">
                                Next
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() => {
                                    setType('mobile');
                                    clearOldData();
                                }}
                            >
                                Back
                            </button>
                        </div>
                    </CForm>
                )}

                {Type === 'listEmail' && (
                    <div>
                        {verificationMethods.filter(item => item.type === "email").map((s) => (
                            <div key={s._id}>
                                <div style={{ float: 'right' }}>
                                    <a
                                        href="/"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            deleteAuthApp(s);
                                        }}
                                    >
                                        <i className="text-danger fa fa-trash"></i>
                                    </a>
                                </div>
                                <b>
                                    {s.title}
                                    <br />
                                    <small>Added {maskedValue(s.token, 10)} on {s.createdAt}</small>
                                </b>
                                <div style={{ clear: 'both' }}></div>
                                <hr />
                            </div>
                        ))}
                        <center>
                            <a
                                href="/"
                                onClick={(e) => {
                                    generateSecret();
                                    e.preventDefault();
                                    setType('email');
                                }}
                                className="btn btn-sm btn-success"
                            >
                                Add New Email
                            </a>
                            &nbsp;&nbsp;
                            <a
                                href="/"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setType('');
                                    clearOldData();
                                }}
                                className="btn btn-sm btn-secondary"
                            >
                                Back
                            </a>
                        </center>
                    </div>
                )}
                {Type === 'email' && (
                    <CForm
                        onSubmit={(e) => {
                            e.preventDefault();
                            generateEmailOtp();
                        }}
                    >
                        <p className="mt-2 mb-2" style={{ fontSize: "17px", color: "#000000", fontWeight: "bold" }}>
                            Enter the Email below:
                        </p>
                        <input
                            value={email}
                            required
                            type="email"
                            className="form-control"
                            onChange={(e) => {
                                setDeviceTitle("My Personal Email");
                                setEmail(e.target.value);
                            }}
                            placeholder="Email Address"
                        />
                        {showMessage(Message)}
                        <div className="d-flex mt-3 justify-content-between">
                            <CButton type="submit" className="btn btn-success">
                                Next
                            </CButton>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() => {
                                    setType('');
                                    clearOldData();
                                }}
                            >
                                Back
                            </button>
                        </div>
                    </CForm>
                )}
                {Type === 'verifyEmail' && (
                    <CForm
                        onSubmit={(e) => {
                            e.preventDefault();
                            verifyEmailOtp();
                        }}
                    >
                        <p className="mt-3" style={{ fontSize: "17px", color: "#000000", fontWeight: "bold" }}>
                            Name this EMail. This will help identify each email associated with the account.
                        </p>
                        <input
                            className="form-control"
                            required
                            type="text"
                            value={DeviceTitle}
                            onChange={(e) => {
                                setDeviceTitle(e.target.value)
                                setMessage({ status: 0, message: '' });
                            }}
                            placeholder="Enter token"
                        />
                        <p className="mt-3" style={{ fontSize: "17px", color: "#000000", fontWeight: "bold" }}>
                            Enter the OTP below:
                        </p>
                        <input
                            className="form-control"
                            type="number"
                            required
                            value={token}
                            onChange={(e) => {
                                setToken(e.target.value)
                                setMessage({ status: 0, message: '' });
                            }}
                            placeholder="Enter OTP"
                        />
                        {showMessage(Message)}
                        <div className="d-flex mt-2 justify-content-between">
                            <button type="submit" className="btn btn-success">
                                Next
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() => {
                                    setType('email');
                                    clearOldData();
                                }}
                            >
                                Back
                            </button>
                        </div>
                    </CForm>
                )}


            </div>
        </div>
    );
};

// TwoFactorPage.propTypes = { UserData: PropTypes.any }

export default TwoFactorPage;
