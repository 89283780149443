import React from "react";
import DatePlannerCenterBlock from "./DatePlannerCenterBlock";
import DatePlannerRightBlock from "./DatePlannerRightBlock";


const DatePlanner = () => {

  return (
    <>
      <section className="sc-datePlanner">
        {/* Center Block */}
        <DatePlannerCenterBlock />

        {/* Right Block */}
        <DatePlannerRightBlock />
      </section>
    </>
  );
};

export default DatePlanner;
