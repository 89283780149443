import React, { useState, useContext, useEffect} from 'react';
import Modal from "react-bootstrap/Modal";
import { ApiContext } from '../../../UseContext/ApiCall';
import { Link } from "react-router-dom";
import FollowUnfollowButton from '../../../Button/FollowUnfollowButton';
import { AwsFileUrl, BASEAPIURL, BASEURL, BASE_URL_STATIC } from '../../../../../config';
import { Button, ButtonGroup } from 'react-bootstrap'; // Import React-Bootstrap Button and ButtonGroup  
import axios from 'axios';
import { followlistApi } from '../../../UseContext/ApiHelpers';
import Spinner from 'react-bootstrap/Spinner';


const ProfileFollowerPopup = ({ showFollowerPopup, setShowFollowerPopup }) => {

  const [searchQuery, setSearchQuery] = useState('');
  const [Followlist, setFollowerList] = useState([])
  const [apiCall, setApiCall] = useState(true)
  const [friendreq, setFriendreq] = useState(false)
  const [activeTab, setActiveTab] = useState('followers'); // Default to 'followers' tab
  const [followerSearchUsersList, setFollowerSearchUsersList] = useState([]); // To hold search results

  const { loggedInUserId, followerList = [], followingList = [], pendingList = [], config } = useContext(ApiContext);

  const followlist = async (list, status) => {
    followlistApi(list, status, config)
    .then((res) => {
      setFollowerList(res.data);
      setApiCall(false);
    }).catch((err) => {
      console.log("err : ", err)
      setApiCall(false);
    })
  };

  useEffect(() => {
    followlist("followerID", 1)
  }, [])

  // Effect hook to reset search query and results when tab changes
  useEffect(() => {
    setSearchQuery(''); // Reset search query on tab switch
    setFollowerSearchUsersList([]); // Clear search results
  }, [activeTab]);

  // Effect hook to set active tab to 'followers' when modal is opened
  useEffect(() => {
    if (showFollowerPopup) {
      setActiveTab('followers'); // Automatically set to 'followers' when modal opens
    }
  }, [showFollowerPopup]);

  const handleFollowerSearch = (e) => {
    setSearchQuery(e.target.value); // Update search query

    const query = e.target.value.toLowerCase(); // Normalize for case-insensitive search
    if (activeTab === 'followers') {
      setFollowerSearchUsersList(
        followerList.filter((user) => user.fname.toLowerCase().includes(query))
      );
    } else if (activeTab === 'following') {
      setFollowerSearchUsersList(
        followingList.filter((user) => user.fname.toLowerCase().includes(query))
      );
    } else if (activeTab === 'pending') {
      setFollowerSearchUsersList(
        pendingList.filter((user) => user.fname.toLowerCase().includes(query))
      );
    }
  };

  const getTabContent = () => {
    switch (activeTab) {
      case 'followers':
        return followerSearchUsersList.length > 0 ? followerSearchUsersList : followerList;
      case 'following':
        return followerSearchUsersList.length > 0 ? followerSearchUsersList : followingList;
      case 'pending':
        return followerSearchUsersList.length > 0 ? followerSearchUsersList : pendingList;
      default:
        return [];
    }
  };

  const tabContent = getTabContent();


  const handleAcceptandFollow = async (type, userID,) => {
    try {
      if (type === 'accept') {
        await axios.post(`${BASEAPIURL}/user/acceptefollowersreq`, { followingID: userID }, config);

      } else {

        await axios.post(`${BASEAPIURL}/user/deletefollowersreq`, { followingID: userID }, config);

      }


    } catch (error) {
      console.error('Error in accepting/rejecting follow request:', error);
    }
  };

  const handleFollowClick = (action) => {
    // Call followlist with the appropriate ID based on action
    const followId = action === 'your requested' ? "followerID" : "followingID";
    followlist(followId, 3);

    // Set the friend request state based on action
    if (action !== 'your requested') {
      setFriendreq(true);
      setApiCall(false);
    } else {
      setFriendreq(false);
    }
  };

  return (
    <section>
      <Modal show={showFollowerPopup} onHide={() => setShowFollowerPopup(false)} centered backdrop="static" keyboard={false} className="create-call" >
        <Modal.Header closeButton style={{ paddingRight: '10px' }}>&nbsp;</Modal.Header>
        <Modal.Body>
          <div className="pfp-tabs mb-3">
            <ButtonGroup className="w-100">
              {['followers', 'following', 'pending'].map((tab) => (
                <Button key={tab} variant={activeTab === tab ? 'primary' : 'outline-secondary'} className="flex-fill"
                  onClick={() => {
                    setActiveTab(tab); // Set the active tab when a tab is clicked
                    setSearchQuery(''); // Clear search query when switching tabs
                    setFollowerSearchUsersList([]); // Clear search results
                    if(tab==="followers"){
                      followlist('followingID', 1)
                    } else if (tab==="following"){
                      followlist('followerID' , 1) 
                    };
                    setFriendreq(false);
                  }}
                >
                  {tab.charAt(0).toUpperCase() + tab.slice(1)}
                </Button>
              ))}
            </ButtonGroup>
          </div>
          
          <div className="text-center text-muted">
            {activeTab === 'pending' &&
              ['your requested', 'pending requested'].map((action, index) => (
                <Button
                  key={index}
                  variant={action === 'your requested' ? 'primary' : 'outline-secondary'} // Green for "Approve All", Red for "Reject All"
                  className={`mb-2 custom-button ${index !== 0 ? 'ms-2' : ''}`}
                  onClick={() => handleFollowClick(action)}
                  style={{
                    padding: '5px 10px',
                    borderRadius: '8px',
                    fontSize: '13px',
                    fontWeight: 'bold',
                  }}
                >
                  {action.charAt(0).toUpperCase() + action.slice(1)}
                </Button>
              ))}
          </div>

          {/* Search */}
          <div className="pfp-search mb-3">
            <form className="pfp-search-form">
              <img className="pfp-search-img" src={`${BASE_URL_STATIC}/icon/Search-2.svg`} alt="search" />
              <label htmlFor="followerSearch">
                <input
                  type="text"
                  id="followerSearch"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={handleFollowerSearch}
                />
              </label>
            </form>
            <span className="pfp-search-filter ms-2 ms-md-0">Filter</span>
          </div>

          {/* Tab Content */}
          <div className="follow_pop">
            {
              apiCall
              ? 
              <div className="d-flex justify-content-center align-items-center">
                <Spinner animation="border" variant="primary" />
              </div>
              : 
              !apiCall 
              && 
              Followlist.length > 0 
              && 
              !friendreq 
              ? 
              <div className="px-2">
                {Followlist.map((user, index) => (
                  <div className="d-flex align-items-center mb-2 mb-lg-3" key={user._id || index}>
                    <img
                      className="pfp-profile rounded-circle"
                      src={AwsFileUrl + user.profile || `${BASE_URL_STATIC}/dummy.png`}
                      alt={user.fname}
                      onError={(e) => (e.target.src = `${BASE_URL_STATIC}/dummy.png`)}
                    />

                    <Link
                      to={`${BASEURL}/profile/${user.userId}`}
                      className="ms-2 ms-sm-3 me-auto text-dark"
                      onClick={() => setShowFollowerPopup(false)}
                    >
                      <h6 className="pfp-name fw-bold">{user.fname}</h6>
                      <small className="text-secondary">
                        {user.userName || 'username06@'}
                      </small>
                    </Link>

                    {user.userId !== loggedInUserId && (
                      <FollowUnfollowButton otherUserId={user.userId} />
                    )}
                  </div>
                ))}
              </div>
              :
              Followlist.length > 0 && friendreq && !apiCall 
              ?
              <div>
                {
                  Followlist && Followlist && Followlist.map((follower, index) => (
                    <div className='d-flex align-items-center mb-2 mb-lg-3' key={index}>

                      <img className='pfp-profile rounded-circle' src={AwsFileUrl + follower.profile} alt={follower.fname} onError={(e) => e.target.src = `${BASE_URL_STATIC}/profile-img1.jpg`} />

                      <Link to={`${BASEURL}/profile/${follower._id}`} className='ms-2 ms-sm-3 me-auto text-dark' >
                        <h6 className='pfp-name fw-bold'>{follower.fname}</h6>
                        <small className='text-secondary'>{follower.username}</small>
                      </Link>

                      <div className='flex'>
                        <button type="button" className="followUnfollowBtn" onClick={() => {


                          handleAcceptandFollow('accept', follower.userId)
                        }}>

                          Accept
                        </button>

                        <button type="button" className="followUnfollowBtn bg-danger" onClick={() => handleAcceptandFollow('reject', follower.userId)}>
                          Reject
                        </button>
                      </div>
                    </div>
                  ))
                }
              </div> 
              :
              <div className="text-center text-muted">No {activeTab} found.</div>
            }
          </div>


          {/* delete krna h */}
          {
            tabContent.map((value, index) => (
              <div key={index}>{value}</div>
            ))
          }
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default ProfileFollowerPopup;
