import React, { useState } from 'react';
import { AwsFileUrl } from '../../config';
import FooterContent from '../../Components/Emilo/Helper/Footer.js';
import './information.css';

const HelpFAQs = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    const toggleQuestion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const faqs = [
        {
            question: "How do I create an account on Emilo?",
            answer:
                "To create an account, click on the 'Sign Up' button on the homepage. Provide your email address, create a password, and fill in the required information. Then, verify your email address to activate your account."
        },
        {
            question: "How can I reset my password?",
            answer:
                "If you forget your password, click on the 'Forgot Password' link on the login page. Enter your registered email address, and we’ll send you instructions to reset your password."
        },
        {
            question: "How can I report inappropriate content?",
            answer:
                "To report inappropriate content, click on the 'Report' button available on posts or messages. Select a reason for reporting, and our moderation team will review the content."
        },
        {
            question: "How do I delete my account?",
            answer:
                "If you wish to delete your account, go to 'Settings' > 'Account' > 'Delete Account'. Follow the instructions to confirm the deletion. Please note that this action is irreversible."
        },
        {
            question: "What kind of content is prohibited on Emilo?",
            answer:
                "Prohibited content includes hate speech, explicit material, spam, or anything that violates our Community Guidelines. Visit our Community Guidelines page for a detailed list of restrictions."
        }
    ];

    return (
        <>
            <div className="container mx-auto p-4">
                <div className='mx-auto text-center cursor-pointer'>
                    <a href="/">
                        <img src={`${AwsFileUrl}/images/logo.png`} style={{ maxHeight: '130px' }} alt="logo" />
                    </a>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="border border-gray-300 my-5 p-5 rounded shadow-lg bg-white">
                            <h1 className="text-xl font-bold mb-4 text-center">Help & FAQs</h1>
                            <div>
                                {faqs.map((faq, index) => (
                                    <div key={index} className="mb-4 border border-gray-300 p-3 rounded shadow-sm">
                                        <div
                                            className="flex justify-between items-center py-2 cursor-pointer text-lg font-semibold"
                                            onClick={() => toggleQuestion(index)}
                                        >
                                            <span className='faqQus'>{faq.question}</span>
                                            <span>
                                                {activeIndex === index ? '−' : '+'}
                                            </span>
                                        </div>
                                        {activeIndex === index && (
                                            <p className="faqAns text-sm text-gray-700 mt-2">
                                                {faq.answer}
                                            </p>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterContent />
        </>
    );
};

export default HelpFAQs;
