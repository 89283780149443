import React, { useContext, useEffect } from 'react';
import LeftBlock from '../../Home/LeftBlock/LeftBlock';
import { ApiContext } from '../../UseContext/ApiCall';


const Check = ({ className }) => {

  const {
    loggedInUserId,
    buttonText,
    getFollowStatus,
    handleFollowUnfollow,
  } = useContext(ApiContext);

  const otherUserId = loggedInUserId === '651d073758406d2f4c6861ce' ? '652e8b7fadbbca68853d6dce' : '651d073758406d2f4c6861ce';

  useEffect(() => {
    if(loggedInUserId !== '' && loggedInUserId !== undefined) {
      getFollowStatus(otherUserId);
    };
  }, [loggedInUserId]);

  return (
    <>
      <section className={`section-main ${className || ''}`}>
        <div className="container-fluid p-0">
          <div className="row no-gutters">
            {/* Left Block */}
            <div className="col-lg-3 d-none d-lg-block shadow z-1">
              <LeftBlock />
            </div>

            {/* Right Block */}
            <div className="col-lg-9 pt-5 overflow-y-scroll scrollbar-hidden px-3" style={{ background: "lightblue", height: '100vh' }}>
              <h3 className='pt-4 text-center fw-bold'>Check</h3>

              <button type="button" className='btn btn-primary' onClick={() => handleFollowUnfollow(otherUserId)}>
                {buttonText === '' ? 'Follow' : buttonText}
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Check;