import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <section className="footer-area">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="w-100 p-2 mx-auto bg-light">
                <Link to="/privacyPolicy" className="text-dark text-decoration-underline me-3">Privacy</Link>
                <Link to="/terms" className="text-dark text-decoration-underline me-3">Terms</Link>
                <Link to="/about" className="text-dark text-decoration-underline me-3">About</Link>
                <Link to="/CommunityGuidelines" className="text-dark text-decoration-underline me-3">Community Guidelines</Link>
                <Link to="/ReportAnything" className="text-dark text-decoration-underline me-3">Report</Link>
                <Link to="/help" className="text-dark text-decoration-underline ">Help</Link>
              </div>
              <div className="copyright text-center">
                <p>E-Milo &copy; {new Date().getFullYear()} - All Rights Reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
