import React, { useContext } from 'react'
import LeftBlock from '../../Home/LeftBlock/LeftBlock'
import { ApiContext } from '../../UseContext/ApiCall'
import { useEffect } from 'react';
import { AwsFileUrl } from '../../../../config';


const SuggestedUsers = ({className}) => {

  const {
    suggestedUsers,
    getSuggestedUsers,
  } = useContext(ApiContext);

  console.log('suggestedUsers : ', suggestedUsers);
  
  useEffect(() => {
    getSuggestedUsers();
  }, []);

  return (
    <>
      <section className={`section-main ${className || ''}`}>
        <div className="container-fluid p-0">
          <div className="row no-gutters">
            {/* Left Block */}
            <div className="col-lg-3 d-none d-lg-block shadow z-1">
              <LeftBlock />
            </div>

            {/* Right Block */}
            <div className="col-lg-9 pt-5 overflow-y-scroll scrollbar-hidden px-3" style={{ background: "lightblue", height: '100vh' }}>
              <h3 className='pt-4 text-center fw-bold'>Suggested Users</h3>

              <div className='w-50 mx-auto bg-white overflow-y-scroll p-2' style={{ maxHeight: '75vh' }}>
                {
                  suggestedUsers && suggestedUsers.length > 0
                  && 
                  suggestedUsers.map((value, index) => (
                    <div className='mb-3 d-flex align-items-center gap-3'>
                      <img src={AwsFileUrl+value.profile} className='rounded-circle border' style={{ height: '50px', width: '50px' }} alt="profile" />

                      <div className='me-auto'>
                        <h5 className='mb-0 fw-semibold'>{value.name}</h5>
                        <span className='text-secondary'>{value.userName || '----------'}</span>
                      </div>

                      <button type="button" className='btn btn-primary btn-sm'>{value.button}</button>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default SuggestedUsers