import React, { useContext, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import { businessContext } from '../../UseContext/BusinessContext';
import Slider from 'react-slick';
import { AwsFileUrl } from '../../../../config';
import toast from 'react-hot-toast';


const UpdateEventPopup = () => {

  const { 
    event, setEvent,
    showUpdateEventPopup, setShowUpdateEventPopup, 
    eventImages, setEventImages,
    // updateEventBanner,
    deleteEventBanner,
    updateEvent,
  } = useContext(businessContext);
  
  console.log('eventImages : ', eventImages);

  // ----- Update Banner -----

  const fileInputRef = useRef(null);

  const handleDivClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
  
    if ((eventImages.length + event.images.length + files.length) <= 5) {
      setEventImages((prevImages) => [...prevImages, ...files]);
    } else {
      toast.error(`You can only select up to 5 images`);
    };
  };

  // remove evnet image 
  const removeEventImage = (indexToRemove) => {
    setEventImages((prevImages) =>
      prevImages.filter((_, index) => index !== indexToRemove)
    );
  };

  const settings = {
    dots: true,           
    infinite: false,
    speed: 500,           
    slidesToShow: 1,        
    slidesToScroll: 1,        
    adaptiveHeight: true,     
  };

  return (
    <>
      <Modal show={showUpdateEventPopup} onHide={() => setShowUpdateEventPopup(false)} keyboard={false} className="create-post" backdrop="static" centered>
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
        <section>
          <h5 className='text-center mb-4 fw-semibold'>Update Event</h5>

          {
            event
            &&
            <div className='overflow-y-scroll ps-1 pe-2' style={{ height: '75vh' }}>
              {/* Add/Update/Delete banner */}
              <>
                {
                  event.images
                  &&
                  <div className='mb-3'>
                    {/* Add */}
                    <>
                      {
                        (eventImages.length + event.images.length) < 5
                        &&
                        <div className='p-1 bg-secondary-subtle rounded-circle d-inline-block mb-2 pt-0 cursor-pointer' onClick={handleDivClick}>
                          <img src="/images/business/icons/add-circle.svg" style={{ height: '20px' }} alt="add" />

                          <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} accept='image/*' multiple />
                        </div>
                      }
                    </>

                    <Slider className="job-images-slider" {...settings}>
                      {/* Local Images */}
                      {
                        eventImages && eventImages.length > 0 
                        &&
                        eventImages.map((image, index) => (
                          <div className='position-relative' key={index}>
                            <div className='border rounded-3'>
                              <img src={URL.createObjectURL(image)} className='rounded-3 object-fit-contain' style={{ height: "300px", width: "100%" }} alt={`Product ${index}`} />
                            </div>

                            {/* Delete */}
                            <div className='position-absolute top-0 end-0 mt-3 me-3 p-1 rounded-3 shadow-sm' style={{ backgroundColor: '#4b4b4b96' }} onClick={() => removeEventImage(index)}>
                              <img src="/images/icons/delete-white.svg" style={{ height: '20px' }} alt="delete" />
                            </div>
                          </div>
                        ))
                      }

                      {/* Live Images */}
                      {
                        event && event.images && event.images.length > 0
                        &&
                        event.images.map((value, index) => (
                          <div className='position-relative' key={index}>
                            <div className='border rounded-3'>
                              <img src={`${AwsFileUrl}${value.image}`} className='rounded-3 object-fit-contain' style={{ height: "300px", width: "100%" }} alt={`Product ${index}`} />
                            </div>

                            {/* Delete */}
                            <>
                              {
                                (event.images.length) > 1
                                &&
                                <div className='position-absolute top-0 end-0 mt-3 me-3 p-1 rounded-3 shadow-sm' style={{ backgroundColor: '#4b4b4b96' }} onClick={() => deleteEventBanner(value._id)}>
                                  <img src="/images/icons/delete-white.svg" style={{ height: '20px' }} alt="delete" />
                                </div>
                              }
                            </>
                          </div>
                        ))
                      }
                    </Slider>
                  </div>
                }
              </>

              <h6 className='fw-semibold mb-2'>Title</h6>
              <input type="text" className='form-control mb-4' placeholder='Enter Title...' 
                value={event.title} onChange={(e) => setEvent((prevEvent) => ({ ...prevEvent, title: e.target.value }))}
              />

              <h6 className='fw-semibold mb-2'>Sub Title</h6>
              <input type="text" className='form-control mb-4' placeholder='Enter Sub Title...' 
                value={event.subTitle} onChange={(e) => setEvent((prevEvent) => ({ ...prevEvent, subTitle: e.target.value }))}
              />  

              <h6 className='fw-semibold mb-2'>About</h6>
              <textarea class="form-control mb-4" placeholder="Write Something Here About Event..."
                value={event.about} onChange={(e) => setEvent((prevEvent) => ({ ...prevEvent, about: e.target.value }))}
              ></textarea>

              <h6 className='fw-semibold mb-2'>Mode</h6>
              <select class="form-select mb-4" value={event.mode} onChange={(e) => setEvent((prevEvent) => ({ ...prevEvent, mode: e.target.value }))} aria-label="Default select example" >
                <option value="virtual">Virtual</option>
                <option value="inPerson">In Person</option>
              </select>

              <h6 className='fw-semibold mb-2'>Address</h6>
              <textarea class="form-control mb-4" placeholder="Enter Address..."
                value={event.address} onChange={(e) => setEvent((prevEvent) => ({ ...prevEvent, address: e.target.value }))}
              ></textarea>

              <h6 className='fw-semibold mb-2'>Mobile</h6>
              <input type="text" className='form-control mb-4' placeholder='Enter Mobile...' 
                value={event.mobile} onChange={(e) => setEvent((prevEvent) => ({ ...prevEvent, mobile: e.target.value }))}
              />

              <h6 className='fw-semibold mb-2'>Email</h6>
              <input type="text" className='form-control mb-4' placeholder='Enter Email...' 
                value={event.email} onChange={(e) => setEvent((prevEvent) => ({ ...prevEvent, email: e.target.value }))}
              />

              <h6 className='fw-semibold mb-2'>Website Link</h6>
              <input type="text" className='form-control mb-4' placeholder='Enter Website Link...' 
                value={event.websiteLink} onChange={(e) => setEvent((prevEvent) => ({ ...prevEvent, websiteLink: e.target.value }))}
              />

              <h6 className='fw-semibold mb-2'>Ticket Link</h6>
              <input type="text" className='form-control mb-4' placeholder='Enter Ticket Link...' 
                value={event.ticketLink} onChange={(e) => setEvent((prevEvent) => ({ ...prevEvent, ticketLink: e.target.value }))}
              />

              <div className='d-flex gap-3 align-items-center justify-content-between mb-2'>
                <h6 className='fw-semibold mb-0'>Repeat Event</h6>

                <div className="form-check form-switch m-0">
                  <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' 
                    checked={event.repeatEvent === 'on'} onChange={(e) => setEvent((prevEvent) => ({ ...prevEvent, repeatEvent: e.target.checked ? 'on' : 'off' }))}
                  />
                </div>
              </div>

              <select class="form-select mb-4" value={event.repeatEventOption} disabled={event.repeatEvent === 'off'} aria-label="Default select example"
                onChange={(e) => setEvent((prevEvent) => ({ ...prevEvent, repeatEventOption: e.target.value }))} 
              >
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
                <option value="yearly">Yearly</option>
              </select>

              {/* Start & End Date */}
              <div className='border rounded-3 p-3 d-flex flex-column flex-sm-row gap-3 mb-4'>
                <div className='flex-grow-1'>
                  <h6 className='fw-semibold mb-2'>Start Date</h6>
                  <input type="date" className='form-control' min={new Date().toISOString().split("T")[0]}
                    value={event.startDate} onChange={(e) => setEvent((prevEvent) => ({ ...prevEvent, startDate: e.target.value, endDate: '' }))} 
                  />
                </div>

                <div className='flex-grow-1'>
                  <h6 className='fw-semibold mb-2'>End Date</h6>
                  <input type="date" className='form-control' min={event.startDate || new Date().toISOString().split("T")[0]} disabled={event.startDate === ''} 
                    value={event.endDate} onChange={(e) => setEvent((prevEvent) => ({ ...prevEvent, endDate: e.target.value }))} 
                  />
                </div>
              </div>

              {/* Start & End Time */}
              <>
                {
                  event.startDate && event.endDate
                  &&
                  <div className='border rounded-3 p-3 d-flex flex-column flex-sm-row gap-3 mb-4'>
                    <div className='flex-grow-1'>
                      <h6 className='fw-semibold mb-2'>Start Time</h6>
                      <input type="time" className='form-control' min={new Date().toTimeString().split(" ")[0].slice(0, 5)}
                        value={event.startTime} onChange={(e) => setEvent((prevEvent) => ({ ...prevEvent, startTime: e.target.value, endTime: '' }))} 
                      />
                    </div>

                    <div className='flex-grow-1'>
                      <h6 className='fw-semibold mb-2'>End Time</h6>
                      <input type="time" className='form-control' min={event.startTime || new Date().toTimeString().split(" ")[0].slice(0, 5)} disabled={event.startTime === ''}
                        value={event.endTime} onChange={(e) => setEvent((prevEvent) => ({ ...prevEvent, endTime: e.target.value }))} 
                      />
                    </div>
                  </div>
                }
              </>

              <div className='border rounded-3 p-3 mb-4'>
                <div className='d-flex gap-3 align-items-center justify-content-between mb-2'>
                  <h6 className='fw-semibold mb-0'>RSVP's</h6>

                  <div className="form-check form-switch m-0">
                    <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' 
                      checked={event.rsvp === 'on'} onChange={(e) => setEvent((prevEvent) => ({ ...prevEvent, rsvp: e.target.checked ? 'on' : 'off' }))}
                    />
                  </div>
                </div>

                <div className='d-flex gap-3 align-items-center justify-content-between'>
                  <h6 className='fw-semibold mb-0'>Boost Event</h6>

                  <div className="form-check form-switch m-0">
                    <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' 
                      checked={event.boostEvent === 'on'} onChange={(e) => setEvent((prevEvent) => ({ ...prevEvent, boostEvent: e.target.checked ? 'on' : 'off' }))}
                    />
                  </div>
                </div>
              </div>

              <button type="button" className='btn btn-primary rounded-pill w-100' onClick={updateEvent}>Update</button>
            </div>
          }
          </section>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default UpdateEventPopup
