import React, { useEffect } from 'react';
import { AwsFileUrl } from '../../config';
import FooterContent from '../../Components/Emilo/Helper/Footer.js';
import './information.css';

const CommunityGuidelines = () => {
    useEffect(() => {
        // For Initialization or Data Fetching if required
    }, []);

    return (
        <>
            <div className="container mx-auto p-4">
                <div className='mx-auto text-center cursor-pointer'>
                    <a href="/">
                        <img src={`${AwsFileUrl}/images/logo.png`} style={{ maxHeight: '130px' }} alt="logo" />
                    </a>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="border border-gray-300 my-5 p-5 rounded shadow-lg bg-white">
                            <section className="docstaBlock pb-0" style={{ textAlign: 'left' }}>
                                <div className="container p-0">
                                    <div className="row align-items-center">
                                        <div className="col-lg-12">
                                            <div className="">
                                                <h1 className="mb-3">Community Guidelines</h1>
                                                <p className="mb-2">Effective Date: 2024-08-19</p>
                                                <p className="mb-4">
                                                    At Emilo, we aim to create a welcoming, safe, and inclusive environment for everyone.
                                                    To achieve this, we have established the following community guidelines. By using our
                                                    platform, you agree to abide by these guidelines, promoting respectful and constructive
                                                    interactions.
                                                </p>

                                                <h4 className="mb-3">1. Respect and Inclusivity</h4>
                                                <ul className="mb-4">
                                                    <li>Treat all members with respect, regardless of their background, ethnicity, gender, religion, or other personal characteristics.</li>
                                                    <li>Harassment, bullying, hate speech, or discrimination in any form will not be tolerated.</li>
                                                    <li>Foster a culture of kindness, empathy, and understanding.</li>
                                                </ul>

                                                <h4 className="mb-3">2. Content Sharing Guidelines</h4>
                                                <h5 className="mb-2">2.1 Acceptable Content</h5>
                                                <ul>
                                                    <li>Share content that is positive, engaging, and relevant to the community.</li>
                                                    <li>Ensure all posts, images, and videos comply with local laws and platform rules.</li>
                                                </ul>
                                                <h5 className="mb-2">2.2 Prohibited Content</h5>
                                                <ul className="mb-4">
                                                    <li>Do not post or share graphic violence, explicit content, or pornography.</li>
                                                    <li>Avoid spreading misleading, false, or harmful information, including fake news and scams.</li>
                                                    <li>Do not promote self-harm, drug use, or illegal activities.</li>
                                                    <li>Avoid using explicit language, offensive slurs, or defamatory remarks.</li>
                                                </ul>

                                                <h4 className="mb-3">3. Protect Privacy and Security</h4>
                                                <ul className="mb-4">
                                                    <li>Do not share anyone's private information (e.g., address, phone number, or photos) without their explicit consent.</li>
                                                    <li>Respect intellectual property rights by only posting content you own or have permission to use.</li>
                                                    <li>Protect your own account information and do not share your credentials.</li>
                                                </ul>

                                                <h4 className="mb-3">4. Prohibited Behavior</h4>
                                                <h5 className="mb-2">4.1 Spam and Advertising</h5>
                                                <ul>
                                                    <li>Do not post spam, repeated advertisements, or self-promotion unrelated to the community.</li>
                                                </ul>
                                                <h5 className="mb-2">4.2 Impersonation</h5>
                                                <ul>
                                                    <li>Do not impersonate other users, public figures, or Emilo staff.</li>
                                                </ul>
                                                <h5 className="mb-2">4.3 Malicious Activities</h5>
                                                <ul className="mb-4">
                                                    <li>Refrain from engaging in hacking, phishing, or spreading malware on the platform.</li>
                                                </ul>

                                                <h4 className="mb-3">5. Reporting Violations</h4>
                                                <p className="mb-4">
                                                    If you witness behavior or content that violates these guidelines, report it through our in-app
                                                    reporting tools or by contacting us at <strong>info@emilo.in</strong>. Our team will review and
                                                    take appropriate action, which may include removing content, warning the user, or suspending
                                                    their account.
                                                </p>

                                                <h4 className="mb-3">6. Account Safety and Usage</h4>
                                                <ul className="mb-4">
                                                    <li>Use only one personal account. Creating multiple accounts to bypass suspensions or violate policies is prohibited.</li>
                                                    <li>Ensure your username, profile image, and bio adhere to our guidelines.</li>
                                                </ul>

                                                <h4 className="mb-3">7. Moderation and Enforcement</h4>
                                                <p className="mb-4">
                                                    Emilo reserves the right to moderate content, enforce guidelines, and take action at
                                                    our discretion. Violations may result in content removal, account suspension, or a
                                                    permanent ban based on severity.
                                                </p>

                                                <h4 className="mb-3">8. Continuous Improvement</h4>
                                                <p className="mb-4">
                                                    Our guidelines may evolve to reflect the needs of the community and legal requirements.
                                                    Regular updates will be posted, and continued use of Emilo constitutes acceptance of
                                                    these changes.
                                                </p>

                                                <h4 className="mb-3">9. Contact Us</h4>
                                                <p className="mb-2">
                                                    For any questions or feedback about these guidelines, please reach out to us at:
                                                </p>
                                                <ul className="mb-0">
                                                    <li><strong>Email:</strong> info@emilo.in</li>
                                                    <li><strong>Address:</strong> Raipur</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
            <FooterContent />
        </>
    );
};

export default CommunityGuidelines;
