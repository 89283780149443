import React, { useState, useContext, useRef, useEffect } from "react";
import { ApiContext } from "../../UseContext/ApiCall.js";
import Modal from "react-bootstrap/Modal";
import ProfileImage from "../../UseContext/ProfileImage.js";
// import AddLocation from "./AddLocation.js";

// import CreatePhotoAndVideo1 from "./CreatePhotoAndVideo/CreatePhotoAndVideo1.jsx";
import CreateMemes1 from "./CreateMemes/CreateMemes1.jsx";
import CreateReels1 from "./CreateReels/CreateReels1";
import CreateBestClips1 from "./CreateBestClips/CreateBestClips1.js";
import toast from "react-hot-toast";
import { BASE_URL_STATIC } from "../../../../config.js";
import TextPostPopup from "./TextPostPopup.jsx";
import CreatePhotoAndVideo2 from "./CreatePhotoAndVideo/CreatePhotoAndVideo2.js";
import { bgImages } from "../../Data/EducationData.jsx";
import Slider from "react-slick";
import CameraPopup from "../CameraPopup.jsx";
import CheckInpopup from "../CheckInpopup.jsx";
import Loader from "../../../Loader.jsx";
import TextPreviewPopoup from "./TextPreviewPopoup.jsx";
import EmojiPopup from "../EmojiPopup.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import CreateGif from "./CreateGif.jsx";
import MusicSelect from "./MusicSelect.jsx";
import parse from "node-html-parser";
import { IoCloseCircle } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

import CreatePhotoAndVideoCollage2 from "./CreatePhotoCollage/CreatePhotoAndVideoCollage2.js";


const CreateNewPostPopup = ({ showCreatePostPopup, setShowCreatePostPopup }) => {

  const [location, setLocation] = useState('');
  const [textPreviewOpen, setTextPreviewOpen] = useState(false)
  const [photoAndVideo, setPhotoAndVideo] = useState();
  const [selectphotoAndVideo, setSelectedPhotoVideo] = useState([])
  const [photoAndVideoType, setPhotoAndVideoType] = useState('');
  const [allbg, setAllbg] = useState(false)
  const [selectedBg, setSelectedBg] = useState()
  const [activeIndex, setActiveIndex] = useState(1);
  const [selectedEmoji, setSelectedEmoji] = useState("")

  const [showPV, setShowPv2] = useState(false);
  const [showPVpopup, setShowPVpopup] = useState(false);
  const [showCreateMemesPopup1, setShowCreateMemesPopup1] = useState(false);
  const [showCreateReelsPopup1, setShowCreateReelsPopup1] = useState(false);
  const [showCreateBestClipsPopup1, setShowCreateBestClipsPopup1] = useState(false);
  const [openEmoji, setOpenEmoji] = useState(false)


  const [cameraOpen, setCameraOpen] = useState(false)
  const [checkIn, setCheckIn] = useState(false)
  const [openGif, setOpenGif] = useState(false)
  const [openMusic, setOpenMusic] = useState(false)

  const navigate = useNavigate();



  

  const {
    loggedInUserId, loggedInUserName, PageContent, setPageContent, checkinType,
    setCheckinType, gifType, setGifType, selectedAddLocation, setSelectedAddLocation,
    setPostType, postType
  } = useContext(ApiContext);


  useEffect(() => {
    if ((PageContent === "") || ((PageContent) && (PageContent.length < 1))) {
      setPageContent("")
    }
  }, [PageContent])

  useEffect(() => {
    if (PageContent.length <= 200) {
      setSelectedBg(bgImages[1])
    }
  }, [])


  useEffect(() => {
    const handleUrlChange = () => {
      const url = window.location.href;

      if (url.includes('businessType')) {
        setPostType('businessType');
      }

      else if (url.includes('SoftCorner')) {
        setPostType('scType');
      }

      else {
        setPostType('postType');
      }
    };

    // Initial check
    handleUrlChange();

    // Listen for URL changes
    window.addEventListener('popstate', handleUrlChange);

    return () => {
      window.removeEventListener('popstate', handleUrlChange);
    };
  }, []);


  useEffect(() => {
    if (showCreatePostPopup === false) {
      setShowCreatePostPopup(false);
      setSelectedAddLocation('')

      // setSelectedBg(bgImages[0]);
      setAllbg(false);
      setOpenEmoji(false)
    }
  }, [showCreatePostPopup])

  // useMemo(() => {
  // }, [PageContent])


  const handleText = () => {
    // if(!allbg){
    const root = parse(PageContent);
    setPageContent(root.textContent)
    setTextPreviewOpen(true);
    setShowCreatePostPopup(false);
    // }
  }

  const handleCancleLocation = () => {
    setSelectedAddLocation(null)
  }


  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2
        }
      }
    ]
  };

  const handleBgErr = () => {

    if (PageContent.length < 200) {
      setAllbg(!allbg)
      const root = parse(PageContent);
      setPageContent(root.textContent)
    }
    else if (PageContent.length > 200) {
      toast.error("Text is too long")
      setAllbg(false)
    }
  }

  // console.log("location" , location);

  const contentRef = useRef(null);

  // const handleCreateTextPost = async (e) => {
  //   e.preventDefault()
  //   try {
  //     setPostLoader(true)
  //     await axios.post("https://emilonode.sjain.io/post/uploaduserpost", { description: PageContent, type: "textpost", title: location }, config).then(() => {
  //       setPostLoader(false)
  //       setPageContent("")
  //       setShowCreatePostPopup(false)
  //     })
  //   } catch (error) {
  //     setPostLoader(false)
  //   }
  // };

  // Input ref for photo and video
  const inputRef = useRef(null);
  const inputRef2 = useRef(null);

  // It will open choose photo and video section on click
  const handleClickPhotoAndVideo = () => {
    setCheckinType("post")
    inputRef.current.click();
  };

  const handleClickPhotoAndVideo2 = () => {
 
    setCheckinType("collage")
    inputRef2.current.click();
    
    
  };
  

  const handleBgSelection = (e, i) => {
    if (PageContent.length > 0) {
      setSelectedBg(e);
      setActiveIndex(i);
      setTextPreviewOpen(true);
      setShowCreatePostPopup(false);
    }
  }

  // To get selected file of create photo and video popup
  const handleSelectPhotoAndVideo = async (e) => {
    const photoAndVideo = e.target.files;
    const selectedFiles = Array.from(e.target.files);

    setPhotoAndVideo(photoAndVideo[0]);
    setSelectedPhotoVideo(selectedFiles)
    setPhotoAndVideoType(photoAndVideo.type);
    handleShowCreatePhotoAndVideoPopup1();
  };

  const handleSelectPhotoAndVideo2 = async (e) => {
    const photoAndVideo = e.target.files;
    const selectedFiles = Array.from(e.target.files);

    setPhotoAndVideo(photoAndVideo[0]);
    setSelectedPhotoVideo(selectedFiles)
    setPhotoAndVideoType(photoAndVideo.type);
    handleShowCreatePhotoAndVideoPopup2();
  };


  // Create Photo And Video Popup
  const handleShowCreatePhotoAndVideoPopup1 = () => {
    setShowCreatePostPopup(false)
    setShowPv2(true);
  };

  const handleShowCreatePhotoAndVideoPopup2 = () => {
    setShowCreatePostPopup(false)
    setShowPVpopup(true);
  };

  // Create Memes Popup
  const handleShowCreateMemesPopup1 = () => {
    setShowCreateMemesPopup1(true);
    setShowCreatePostPopup(false);
  };

  // Create Reel Popup
  const handleShowCreateReelsPopup1 = () => {
    setShowCreateReelsPopup1(true);
    setShowCreatePostPopup(false);
  };

  // Create Best Clip Popup
  const handleShowCreateBestClipPopup1 = () => {
    setShowCreateBestClipsPopup1(true);
    setShowCreatePostPopup(false);
  };

  const handleCloseModal = () => {
    setShowCreatePostPopup(false);
    setPageContent("");
    setSelectedBg(bgImages[1]);
    setAllbg(false);
    setOpenEmoji(false)
    setSelectedAddLocation('')
  }

  const stripHTMLTags = (html) => {
    const parser = new DOMParser();
    const parsedHtml = parser.parseFromString(html, 'text/html');
    return parsedHtml.body.textContent || ""; // Extract only the text content
  };

  const decodeHTMLEntities = (html) => {
    const textArea = document.createElement("textarea");
    textArea.innerHTML = html;
    return textArea.value;
  };

  const cleanPageContent = (htmlContent) => {
    const textWithoutHtml = stripHTMLTags(htmlContent);
    return decodeHTMLEntities(textWithoutHtml);
  };




  return (
    <>
    

      <TextPreviewPopoup setAllbg={setAllbg} selectedAddLocation={selectedAddLocation} setCheckIn={setCheckIn} handleCancleLocation={handleCancleLocation} setSelectedEmoji={setSelectedEmoji} selectedEmoji={selectedEmoji} contentRef={contentRef} allbg={allbg} activeIndex={activeIndex} setActiveIndex={setActiveIndex} bgImages={bgImages} PageContent={PageContent} setPageContent={setPageContent} setSelectedBg={setSelectedBg} setShowCreatePostPopup={setShowCreatePostPopup} selectedBg={selectedBg} open={textPreviewOpen} close={() => setTextPreviewOpen(false)} />

      {/* Create Post Popup */}
      {/* show={showCreatePostPopup} */}
      <Modal show={showCreatePostPopup} onHide={() => { handleCloseModal() }} keyboard={false} className="create-post" backdrop="static" centered>
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          <div className="row">
            {
              openEmoji
              &&
              <p className="col-1" style={{ cursor: "pointer" }} onClick={() => { setOpenEmoji(false) }}>
                <FontAwesomeIcon icon={faAngleLeft} className="back-icon" />
              </p>
            }

            <p className={`${openEmoji ? 'col-11' : 'col-12'} fs-6 text-black fw-bold text-center mb-2`}>Create  Post</p>
          </div>

          {
            openEmoji
              ?
              <EmojiPopup setOpenEmoji={setOpenEmoji} selectedEmoji={selectedEmoji} setSelectedEmoji={setSelectedEmoji} />
              :
              <div>
                <div className="userDetail">
                  <div className="imgBox" >
                    <ProfileImage userId={loggedInUserId} height='50' width='50' borderRadius='50%' />
                  </div>

                  <div className="nameBox">
                    <div className="d-flex flex-md-row align-items-center">
                      <span className="fs-6">
                        <span className="fw-bold">{loggedInUserName}</span>
                        {selectedEmoji && (
                          <span className="ms-1 d-inline-block fs-14 text-secondary" onClick={() => setOpenEmoji(true)}>
                            {selectedEmoji.emotion}
                            <img style={{ width: "20px", display: "inline", marginLeft: "5px", marginRight: "5px" }} src={selectedEmoji.icon} alt="icon" />
                            {selectedEmoji.emotionName}
                          </span>
                        )}
                      </span>
                    </div>

                    <div className="add-location-div">
                      <i className="fas fa-map-marker-alt" style={{ padding: '3px 3px 3px 0px' }} />
                      {/* <AddLocation location={location} setLocation={setLocation} /> */}
                      {selectedAddLocation ?
                        <>
                          <small className="text-truncate" style={{ maxWidth: "300px", cursor: 'pointer' }} onClick={() => { setCheckIn(true); setShowCreatePostPopup(false); }}>{selectedAddLocation.place}</small>
                          <span className="fs-5 cp" style={{ color: 'rgb(207 99 109)' }} onClick={handleCancleLocation}> <IoCloseCircle /></span>
                        </>
                        :
                        <small className="cp" onClick={() => { setCheckIn(true); setShowCreatePostPopup(false); setCheckinType("text") }}>Add Location</small>
                      }
                    </div>
                  </div>
                </div>

                <div ref={contentRef} className="bg_style" >
                  <div className="postBox position-relative">
                    <div className="text-editor">
                      {((PageContent.length < 200) && allbg) ?

                        <TextPostPopup allbg={allbg} setAllbg={setAllbg} PageContent={PageContent} setPageContent={setPageContent} />
                        :
                        <textarea
                          rows={11}
                          value={cleanPageContent(PageContent)} // Clean the HTML content before rendering
                          onChange={(e) => setPageContent(e.target.value)}
                        ></textarea>

                      }
                    </div>
                    {!allbg && <div className="loader-hw">
                      <Loader />
                    </div>}

                    {/* <div className="text_opener" dangerouslySetInnerHTML={{ __html: PageContent }}}></div> */}
                  </div>

                </div>

                <div className="bg_image_head">
                  {PageContent.length < 200 && allbg && <div className="slider-container">
                    <Slider {...settings}>
                      {bgImages.map((e, i) => (
                        <div key={i} className="bg-images" onClick={() => { handleBgSelection(e, i) }}>
                          {e.img === '' ?
                            <span className="no_bg cp">
                              <img src='/images/none.jpg'
                                alt=""
                                style={{
                                  border: activeIndex === i && "2px solid #64A6F0",
                                }}
                                onClick={() => {
                                  setAllbg(false);
                                  handleText();
                                }} />
                            </span>
                            :
                            <img src={e.img} alt="" />}
                        </div>
                      ))}
                    </Slider></div>}
                </div>

                <div className={`iconBox ${postType === 'scType' ? 'w-100 ms-0' : 'ms-3'} `}>
                  <span to="/" className="c_new" onClick={() => { setCameraOpen(true); setShowCreatePostPopup(false); }} style={{ cursor: "pointer" }}>
                    <img src={`${BASE_URL_STATIC}/create-icon2.png`} alt="" />
                    Camera
                  </span>

                  <span
                    className="c_new"
                    // onClick={() => setPhotoAndVideo(!photoAndVideo)}
                    onClick={handleClickPhotoAndVideo}
                  >
                    <input type="file" name="photoAndVideo" id="photoAndVideo" ref={inputRef} onChange={handleSelectPhotoAndVideo} accept="image/jpeg,image/png,image/gif,video/mp4,video/mpeg,video/quicktime" style={{ display: 'none' }} multiple />
                    <img src={`${BASE_URL_STATIC}/create-icon3.png`} alt="create-icon3" style={{ margin: '0px 0px 5px 0px' }} />
                    <span>
                      Photo/Video
                    </span>
                  </span>

                  {postType !== 'scType' && <span
                    className="c_new"
                    onClick={handleShowCreateMemesPopup1}
                  >
                    <img src={`${BASE_URL_STATIC}/create-icon4.png`} alt="" />
                    <span>
                      Memes
                    </span>
                  </span>}

                  {postType !== 'scType' &&
                    <span className="c_new"
                      onClick={handleShowCreateReelsPopup1}
                    >
                      <img src={`${BASE_URL_STATIC}/create-icon5.png`} alt="" />
                      <span>Flix</span>
                    </span>}

                  {postType !== 'scType' && <span
                    className="c_new"
                    onClick={handleShowCreateBestClipPopup1}
                  >
                    <img src={`${BASE_URL_STATIC}/create-icon6.png`} alt="" />
                    <span>Best clips</span>
                  </span>}

                  {
                    postType !== 'scType'
                    &&
                    <span className="d-flex flex-column align-items-center cursor-pointer"
                      onClick={() => {
                        navigate(`/zegocloud/host`);
                        setShowCreatePostPopup(false);
                      }}
                    >
                      <img src={`${BASE_URL_STATIC}/create-icon7.png`} alt="icon" />
                      <span>eshow</span>
                    </span>
                  }

                  {postType !== 'scType' && <span className="c_new" onClick={() => { setOpenEmoji(true); }}>
                    <img src={`${BASE_URL_STATIC}/create-icon8.png`} alt="" />
                    <span>Activity</span>
                  </span>}

                  <span className="c_new" onClick={() => { setCheckIn(true); setShowCreatePostPopup(false); setCheckinType("check-in") }}>
                    <img src={`${BASE_URL_STATIC}/create-icon9.png`} alt="" />
                    <span>Check in</span>
                  </span>

                  <span className="c_new" onClick={() => { setOpenGif(true); setShowCreatePostPopup(false); setGifType("gif") }}>
                    <img src={`${BASE_URL_STATIC}/create-icon10.png`} alt="" />
                    <span>GIF</span>
                  </span>

                  <span className="c_new" onClick={() => { setOpenMusic(true); setShowCreatePostPopup(false); }} >
                    <img src={`${BASE_URL_STATIC}/create-icon11.png`} alt="" />
                    <span>Music</span>
                  </span>

                  <span className="c_new" onClick={handleBgErr}>
                    <img src={`${BASE_URL_STATIC}/create-icon12.png`} alt="" />
                    <span>Background</span>
                  </span>

                  {/* <span className="c_new" onClick={() => toast.error("use mobile app for Grid")}>
                    <img src={`${BASE_URL_STATIC}/create-icon13.png`} alt="" />
                    <span>Collage</span>
                  </span> */}

               

                  <span
                    className="c_new"
                    // onClick={() => setPhotoAndVideo(!photoAndVideo)}
                    onClick={handleClickPhotoAndVideo2}
                  >
                    <input type="file" name="photoAndVideo" id="photoAndVideo" ref={inputRef2} onChange={handleSelectPhotoAndVideo2} accept="image/jpeg,image/png,image/gif,video/mp4,video/mpeg,video/quicktime" style={{ display: 'none' }} multiple />
                    <img src={`${BASE_URL_STATIC}/create-icon13.png`} alt="Collage Icon" />
                    <span>
                    Collage
                    </span>
                  </span>





                </div>

                <div className="text-center row justify-content-center mt-4">
                  <button onClick={() => {
                    setTextPreviewOpen(true);
                    setShowCreatePostPopup(false);
                  }} disabled={PageContent === "" && selectedEmoji === ""} className="text_post_btn col-8">Next</button>
                </div>
              </div>
          }
        </Modal.Body>
      </Modal>

      {/* Create Photo And Video Popup 1 */}
      {/* <CreatePhotoAndVideo1
        setSelectedPhotoVideo={setSelectedPhotoVideo} handleSelectPhotoAndVideo={handleSelectPhotoAndVideo} inputRef={inputRef}  selectphotoAndVideo={selectphotoAndVideo}
        location={location} setLocation={setLocation}
        setPhotoAndVideo={setPhotoAndVideo} photoAndVideo={photoAndVideo} photoAndVideoType={photoAndVideoType}
        handleShowCreateReelsPopup1={handleShowCreateReelsPopup1} handleShowCreateBestClipPopup1={handleShowCreateBestClipPopup1}
      /> */}

      <CameraPopup open={cameraOpen} close={() => setCameraOpen(false)} setShowCreatePostPopup={setShowCreatePostPopup} showPV={showPV} setShowPv2={setShowPv2} selectphotoAndVideo={selectphotoAndVideo} setSelectedPhotoVideo={setSelectedPhotoVideo} setPhotoAndVideo={setPhotoAndVideo} setLocation={setLocation} location={location} />

      <CheckInpopup checkinType={checkinType} setShowPv2={setShowPv2} setSelectedEmoji={setSelectedEmoji} openEm={openEmoji} selectedEmoji={selectedEmoji} setCheckIn={setCheckIn} setOpenEmoji={setOpenEmoji} open={checkIn} close={() => { setCheckIn(false); }} setShowCreatePostPopup={setShowCreatePostPopup} />

      <CreatePhotoAndVideo2 setCameraOpen={setCameraOpen} setOpenGif={setOpenGif}
        setCheckIn={setCheckIn} showPV={showPV} setShowPv2={setShowPv2} setSelectedEmoji={setSelectedEmoji} selectedEmoji={selectedEmoji}
        selectphotoAndVideo={selectphotoAndVideo} setSelectedPhotoVideo={setSelectedPhotoVideo}
        location={location} setPhotoAndVideo={setPhotoAndVideo} setLocation={setLocation} photoAndVideo={photoAndVideo} setShowCreatePostPopup={setShowCreatePostPopup} photoAndVideoType={photoAndVideoType} />


< CreatePhotoAndVideoCollage2 setCameraOpen={setCameraOpen} setOpenGif={setOpenGif}

        setCheckIn={setCheckIn} showPVpopup={showPVpopup} setShowPVpopup={setShowPVpopup} setSelectedEmoji={setSelectedEmoji} selectedEmoji={selectedEmoji}
        selectphotoAndVideo={selectphotoAndVideo} setSelectedPhotoVideo={setSelectedPhotoVideo}
        location={location} setPhotoAndVideo={setPhotoAndVideo} setLocation={setLocation} photoAndVideo={photoAndVideo} setShowCreatePostPopup={setShowCreatePostPopup} photoAndVideoType={photoAndVideoType} />




      <CreateReels1 selectedEmoji={selectedEmoji} handleCancleLocation={handleCancleLocation}
        showCreateReelsPopup1={showCreateReelsPopup1} setShowCreateReelsPopup1={setShowCreateReelsPopup1} setSelectedEmoji={setSelectedEmoji}
        location={location} setLocation={setLocation}
      />

      {/* Create Reels Memes 1 */}
      <CreateMemes1 selectedEmoji={selectedEmoji} showCreateMemesPopup1={showCreateMemesPopup1} setShowCreateMemesPopup1={setShowCreateMemesPopup1} handleShowCreateMemesPopup1={handleShowCreateMemesPopup1} />

      <CreateGif gifType={gifType} setShowPv2={setShowPv2} setOpenEmoji={setOpenEmoji} setSelectedPhotoVideo={setSelectedPhotoVideo} selectphotoAndVideo={selectphotoAndVideo} selectedEmoji={selectedEmoji} setSelectedEmoji={setSelectedEmoji} openEmoji={openEmoji} open={openGif} close={() => setOpenGif(false)} setShowCreatePostPopup={setShowCreatePostPopup} />

      {/* Create Reels Popup 1 */}


      <MusicSelect location={location} setLocation={setLocation} setSelectedEmoji={setSelectedEmoji} openEmoji={openEmoji} selectedEmoji={selectedEmoji} open={openMusic} close={() => setOpenMusic(false)} setOpenEmoji={setOpenEmoji} />

      {/* Create Best Clips Popup 1 */}
      <CreateBestClips1 showCreateBestClipsPopup1={showCreateBestClipsPopup1} setShowCreateBestClipsPopup1={setShowCreateBestClipsPopup1} location={location} setLocation={setLocation} />
    </>
  );
};

export default CreateNewPostPopup;
