import React, { useEffect } from 'react';
import { AwsFileUrl } from '../../config';
import FooterContent from '../../Components/Emilo/Helper/Footer.js';
import './information.css';

const PrivacyPolicy = () => {
    useEffect(() => {
        // For Anythings
        // For Anythings
    }, []);

    return (
        <>
            <div className="container mx-auto p-4">
                <div className='mx-auto text-center cursor-pointer'>
                    <a href="/">
                        <img src={`${AwsFileUrl}/images/logo.png`} style={{ maxHeight: '130px' }} alt="logo" />
                    </a>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className="border border-gray-300 my-5 p-5 rounded shadow-lg bg-white">
                            <section className="docstaBlock pb-0" style={{ textAlign: 'justify' }}>
                                <div className="container p-0">
                                    <div className="row align-items-center">
                                        <div className="col-lg-12">
                                            <div className="">
                                                <h1 className="mb-3">Privacy Policy</h1>
                                                <p className="mb-2">Effective Date: 2024-07-01</p>
                                                <p className="mb-4">
                                                    At Emilo, we value your trust and are committed to safeguarding
                                                    your personal information. This Privacy Policy explains how we
                                                    collect, use, store, and protect your data when you use our
                                                    platform. By accessing Emilo, you agree to the terms outlined in
                                                    this policy.
                                                </p>

                                                <h4 className="mb-3">1. Information We Collect</h4>
                                                <h5 className="mb-2">1.1 Personal Information</h5>
                                                <ul>
                                                    <li>Name, email address, phone number, and profile details when you sign up.</li>
                                                    <li>Optional information you add to your profile, such as location, interests, or photos.</li>
                                                </ul>
                                                <h5 className="mb-2">1.2 Usage Information</h5>
                                                <ul>
                                                    <li>Details of your interactions with the platform, such as pages viewed, searches performed, and actions taken.</li>
                                                    <li>Log data, such as IP addresses, device information, and browser type.</li>
                                                </ul>
                                                <h5 className="mb-2">1.3 Content and Communications</h5>
                                                <ul className="mb-4">
                                                    <li>Content you create or share, including posts, messages, comments, and uploads.</li>
                                                    <li>Communication metadata, such as message timestamps and participant information.</li>
                                                </ul>

                                                <h4 className="mb-3">2. How We Use Your Information</h4>
                                                <p className="mb-2">We use the data we collect to:</p>
                                                <ul className="mb-4">
                                                    <li>Provide, personalize, and improve your experience on Emilo.</li>
                                                    <li>Facilitate communication and connections with other users.</li>
                                                    <li>Deliver targeted content and recommendations.</li>
                                                    <li>Ensure the security and functionality of the platform.</li>
                                                    <li>Send notifications and updates related to your account and activities.</li>
                                                </ul>

                                                <h4 className="mb-3">3. Data Sharing and Disclosure</h4>
                                                <p className="mb-2">We do not sell or rent your personal information to third parties. However, we may share data in the following situations:</p>
                                                <ul className="mb-4">
                                                    <li>
                                                        <strong>With Your Consent:</strong> Sharing data with specific users or services, as authorized by you.
                                                    </li>
                                                    <li>
                                                        <strong>Service Providers:</strong> Collaborating with trusted third-party providers who assist us in operating the platform (e.g., hosting, analytics).
                                                    </li>
                                                    <li>
                                                        <strong>Legal Requirements:</strong> Complying with applicable laws, regulations, or valid legal processes.
                                                    </li>
                                                    <li>
                                                        <strong>Protection:</strong> Safeguarding the rights, safety, and integrity of Emilo, its users, and the public.
                                                    </li>
                                                </ul>

                                                <h4 className="mb-3">4. Your Privacy Choices</h4>
                                                <p className="mb-2">You have control over your privacy settings:</p>
                                                <ul className="mb-4">
                                                    <li>
                                                        <strong>Manage Your Information:</strong> Update or delete profile details and uploaded content.
                                                    </li>
                                                    <li>
                                                        <strong>Notification Preferences:</strong> Customize the notifications and emails you receive.
                                                    </li>
                                                    <li>
                                                        <strong>Account Deletion:</strong> Request account deletion if you no longer wish to use our platform.
                                                    </li>
                                                </ul>

                                                <h4 className="mb-3">5. Data Security</h4>
                                                <p className="mb-4">
                                                    We implement robust security measures to protect your personal
                                                    information, including encryption, access controls, and regular
                                                    system monitoring. While we strive to protect your data, no
                                                    system is completely secure. Please report any security concerns
                                                    to [insert contact email].
                                                </p>

                                                <h4 className="mb-3">6. Children’s Privacy</h4>
                                                <p className="mb-4">
                                                    Emilo is not intended for individuals under the age of 13. We do
                                                    not knowingly collect personal data from children. If we discover
                                                    that a child under 13 has provided information, we will promptly
                                                    delete it.
                                                </p>

                                                <h4 className="mb-3">7. Changes to This Privacy Policy</h4>
                                                <p className="mb-4">
                                                    We may update this Privacy Policy from time to time. Any
                                                    significant changes will be communicated through a notice on our
                                                    platform. Continued use of Emilo after changes are posted
                                                    signifies your acceptance of the updated policy.
                                                </p>

                                                <h4 className="mb-3">8. Contact Us</h4>
                                                <p className="mb-2">
                                                    If you have any questions, concerns, or feedback regarding this
                                                    Privacy Policy, please contact us at:
                                                </p>
                                                <ul className="mb-0">
                                                    <li>
                                                        <strong>Email:</strong> info@emilo.in
                                                    </li>
                                                    <li>
                                                        <strong>Address:</strong> Raipur
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
            <FooterContent />
        </>
    )
}

export default PrivacyPolicy
