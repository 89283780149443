import React, { useEffect } from 'react'
import { AwsFileUrl } from '../../config'
import FooterContent from '../../Components/Emilo/Helper/Footer.js'
import './information.css';

const AboutCompany = () => {
    useEffect(() => {
        // For Anythings
        // For Anythings
    }, []);

    return (
        <>
            <div className="container mx-auto p-4">
                <div className='mx-auto text-center cursor-pointer'>
                    <a href="/">
                        <img src={`${AwsFileUrl}/images/logo.png`} style={{ maxHeight: '130px' }} alt="logo" />
                    </a>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className="border border-gray-300 my-5 p-5 rounded shadow-lg bg-white">
                            <section className="docstaBlock pb-0" style={{ textAlign: 'justify', }}>
                                <div className="container p-0">
                                    <div className="row align-items-center">
                                        <div className="col-lg-12">
                                            <div className="docBox">
                                                <h2 className="mb-3">Welcome to Emilo: Your Social Connection Hub</h2>
                                                <p className="mb-3">Emilo is more than just a social networking platform-it's your gateway to connecting with the people and communities that matter most in your life. In a fast-paced world, Emilo brings together simplicity, personalization, and innovation to ensure that every interaction feels meaningful and every moment shared is truly valued.</p>
                                                <p>Whether you're here to share your favorite memories, express your thoughts, or dive into exciting new interests, Emilo offers something for everyone. From close-knit interactions with friends and family to exploring global communities that align with your passions, Emilo is designed to enhance the way you engage with the world.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row align-items-center">
                                        <div className="col-lg-5">
                                            <div className="imgBox">
                                                <img src="images/about.webp" alt="" />
                                            </div>
                                        </div>
                                        <div className="col-lg-7">
                                            <div className="textBox mt-4">
                                                <h3 className="mb-3">Why Choose Emilo?</h3>
                                                <ul className="pl-3">
                                                    <li className="mb-2">
                                                        <i className="fas fa-check-circle" />&nbsp;
                                                        <b>Effortless Connection:</b> Stay in touch with friends and family, no matter where they are.
                                                    </li>
                                                    <li className="mb-2">
                                                        <i className="fas fa-check-circle" />&nbsp;
                                                        <b>Personalized Experience:</b> Tailor your feed to reflect your passions and preferences.
                                                    </li>
                                                    <li className="mb-2">
                                                        <i className="fas fa-check-circle" />&nbsp;
                                                        <b>Engaging Communities:</b> Join groups, forums, and discussions on topics you love.
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-check-circle" />&nbsp;
                                                        <b>Secure & Private:</b> Your data and conversations are safe with our advanced privacy measures.
                                                    </li>
                                                </ul>
                                                <h3 className="mt-4">Our Mission</h3>
                                                <p className="pl-3">At Emilo, we aim to empower meaningful connections by fostering an inclusive and dynamic digital environment.</p>
                                                <h3 className="mt-4">Get Started Today!</h3>
                                                <p className="pl-3">Dive into the world of connection, creativity, and collaboration. Join Emilo and make every moment count.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
            <FooterContent />
        </>
    )
}

export default AboutCompany
