import React, { useContext, useEffect } from 'react';
import AddSpecialDaysPopup from '../Popup/SpecialDays/AddSpecialDaysPopup';
import { BASE_URL_STATIC } from '../../../config';
import { ScApiContext } from '../useContext/softCornerContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';


const SpecialDaysCenterBlock = () => {

  const {
    setShowAddSpecialDaysPopup,
    specialDay, setSpecialDay,
    specialDays, 
    // setSpecialDays, 
    filteredSpecialDays, setFilteredSpecialDays,
    getSpecialDays,
  } = useContext(ScApiContext);

  const formatDate = (dateTime) => {
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr',
      'May', 'Jun', 'Jul', 'Aug',
      'Sep', 'Oct', 'Nov', 'Dec'
    ];
  
    const inputDate = new Date(dateTime);
  
    const month = monthNames[inputDate.getMonth()];
    const day = inputDate.getDate().toString().padStart(2, '0');
    const year = inputDate.getFullYear();
  
    const formattedDateString = `${month} ${day}, ${year}`;
  
    return formattedDateString;
  };

  // handle search
  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();

    if(searchValue === '') {
      if(specialDays.length > 0) {
        setSpecialDay(specialDays[0]);
        setFilteredSpecialDays([]);
      };
    } else {
      setSpecialDay({
        _id: '',
        title: '',
        category: '',
        categoryOther: '',
        location: '',
        lat: '',
        lng: '',
        dateTime: '',
        setCustomAlert: 'false',
        alerts: [],
        customDateTime: '',
        note: '',
      });

      const filtered = specialDays.filter((day) =>
        day.title.toLowerCase().includes(searchValue) 
        || 
        day.location.toLowerCase().includes(searchValue)
      );

      setFilteredSpecialDays(filtered);
    };
  };

  // handle click add special day button 
  const handleAddSpecialDay = () => {
    setShowAddSpecialDaysPopup(true);

    setSpecialDay({
      _id: '',
      title: '',
      category: '',
      categoryOther: '',
      location: '',
      lat: '',
      lng: '',
      dateTime: '',
      setCustomAlert: 'false',
      alerts: [],
      customDateTime: '',
      note: '',
    });
  };

  // next year same date aane me kitne din bache h
  const handleRemainingDays = (dateTime) => {
    // Step 1: Parse the dateTime to create a date object
    const startDate = new Date(dateTime);
  
    // Check if the dateTime is valid
    if (isNaN(startDate.getTime())) {
      return "Invalid date"; // Return a message for invalid date
    }
  
    // Step 2: Create a date object for the same date next year
    const nextYearDate = new Date(startDate);
    nextYearDate.setFullYear(startDate.getFullYear() + 1); // Increment year by 1
  
    // Step 3: Calculate the difference in days
    const currentDate = new Date();
    const timeDifference = nextYearDate - currentDate; // Time difference in milliseconds
  
    // If the next year's date is already in the past, return 0 days remaining
    if (timeDifference < 0) {
      return 0; // No remaining days if the target date has already passed
    }
  
    const remainingDays = Math.ceil(timeDifference / (1000 * 3600 * 24)); // Convert milliseconds to days
  
    return remainingDays;
  };

  useEffect(() => {
    getSpecialDays();
  }, []);

  return (
    <>
      {/* Add special days popup */}
      <AddSpecialDaysPopup />

      <section className='sc-specialDays-centerBlock mt-5 px-5 pb-3
      '>
        {/* Search & Add */}
        <div className="d-flex align-items-center gap-3 mt-5 mb-4">
          <div className="d-flex align-items-center bg-white rounded-pill ps-4 shadow-sm flex-grow-1">
            <img src={`${BASE_URL_STATIC}/search.png`} style={{ height: '20px' }} alt="search" />
            <input type="search" onChange={handleSearch} className="form-control rounded-pill border-0 shadow-none" placeholder="Search by title & location..." />
          </div>

          <button type="button" className="btn bg-white shadow-sm d-flex align-items-center gap-1 rounded-pill" onClick={handleAddSpecialDay}>
            <img src={`${BASE_URL_STATIC}/SoftCorner/sc-addAlbum.png`} style={{ height: '20px' }} alt="add" />
            <span>Add Special Days</span>
          </button>
        </div>

        {/* Special Days */}
        <div className='row flex-column col-12 mx-auto gap-3 px-0'>
          {
            // agar filtered special days h to usko show kro
            filteredSpecialDays && filteredSpecialDays.length > 0
            ?
            filteredSpecialDays.map((value, index) => (
              <div key={index} className='d-flex gap-3 align-items-center p-3 rounded-3 shadow-sm cursor-pointer' style={{ backgroundColor: `${value._id === specialDay._id ? "#FF6969" : "#fff"}` }} 
                onClick={() => setSpecialDay(value)}
              >
                <FontAwesomeIcon style={{ color: `${value._id === specialDay._id ? "snow" : "#BFBFBF"}` }} icon={faBars} />

                <div className={`${value._id === specialDay._id && 'bg-white'} rounded-circle p-2`} style={{ border: '1px solid #FF6969' }}>
                  <img src={`${BASE_URL_STATIC}/SoftCorner/sc-sd-coffee.png`} style={{ height: '30px', width: '30px' }} alt="sc-sd-heart" />
                </div>

                <div className='d-flex flex-column align-items-start me-auto'>
                  <p className='fs-20' style={{ color: `${value._id === specialDay._id ? "snow" : ""}` }}>{value.title}</p>
                  <small style={{ color: `${value._id === specialDay._id ? "#fdfdfd" : "#2676E1"}` }}>{value.location}</small>
                </div>

                <div className='d-flex flex-column align-items-end'>
                  <p className='fs-20 ' style={{ color: `${value._id === specialDay._id ? "snow" : ""}` }}>{handleRemainingDays(value.dateTime)} days left</p>
                  <small style={{ color: `${value._id === specialDay._id ? "#fdfdfd" : "#BFBFBF"}` }}>{formatDate(value.dateTime)}</small>
                </div>
              </div>
            ))
            :
            // nahi to special days ki list show karo
            specialDays && specialDays.length > 0 
            && 
            specialDays.map((value, index) => (
              <div key={index} className='d-flex gap-3 align-items-center p-3 rounded-3 shadow-sm cursor-pointer' style={{ backgroundColor: `${value._id === specialDay._id ? "#FF6969" : "#fff"}` }} 
                onClick={() => setSpecialDay(value)}
              >
                <FontAwesomeIcon style={{ color: `${value._id === specialDay._id ? "snow" : "#BFBFBF"}` }} icon={faBars} />

                <div className={`${value._id === specialDay._id && 'bg-white'} rounded-circle p-2`} style={{ border: '1px solid #FF6969' }}>
                  <img src={`${BASE_URL_STATIC}/SoftCorner/sc-sd-coffee.png`} style={{ height: '30px', width: '30px' }} alt="sc-sd-heart" />
                </div>

                <div className='d-flex flex-column align-items-start me-auto'>
                  <p className='fs-20' style={{ color: `${value._id === specialDay._id ? "snow" : ""}` }}>{value.title}</p>
                  <small style={{ color: `${value._id === specialDay._id ? "#fdfdfd" : "#2676E1"}` }}>{value.location}</small>
                </div>

                <div className='d-flex flex-column align-items-end'>
                  <p className='fs-20 ' style={{ color: `${value._id === specialDay._id ? "snow" : ""}` }}>{handleRemainingDays(value.dateTime)} days left</p>
                  <small style={{ color: `${value._id === specialDay._id ? "#fdfdfd" : "#BFBFBF"}` }}>{formatDate(value.dateTime)}</small>
                </div>
              </div>
            ))
          }
        </div>
      </section>
    </>
  )
}

export default SpecialDaysCenterBlock