import React, { useState, useContext, useMemo, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { ApiContext } from "../../../UseContext/ApiCall";


import { BASEAPIURL, BASE_URL_STATIC } from "../../../../../config";
import axios from "axios";
import parse from "node-html-parser";
import { IoAddOutline, IoCloseCircle } from "react-icons/io5";
import { BiTrash } from "react-icons/bi";
import CheckInpopup from "../../CheckInpopup";
import ProfileImage from "../../../UseContext/ProfileImage";
import EmojiPopup from "../../EmojiPopup";
import CreateGif from "../CreateGif";
import PostAdvanceSettingPopup from "./PostAdvanceSettingPopup";
import NotificationpostPopup from "../../Notification/NotificationpostPopup";

import { Button } from "react-bootstrap";
import { RxCross2 } from "react-icons/rx";


const CreatePhotoAndVideoCollage2 = ({ setSelectedEmoji, selectedEmoji,
  setSelectedPhotoVideo, setCheckIn, setCameraOpen, setOpenGif,
  selectphotoAndVideo,
  location,
  setLocation,
showPVpopup, setShowPVpopup, setShowCreatePostPopup }) => {
  const [checkOpen, setCheckOpen] = useState(false)
  const [isEditing, setIsEditing] = useState(false);
  const [openEmoji, setOpenEmoji] = useState(false)
  const [description, setDescription] = useState("");
  const [setTagsByCarouselItem] = useState([]);
  const [fileUrl, setFileUrl] = useState([])
  const [show, setShow] = useState(false);
  const [errorMsg] = useState('')
  const [postLoader, setPostLoader] = useState(false);
  // const [selectedImage, setSelectedImage] = useState({});
  const [ setActiveIndex] = useState(0);


  const { config, loggedInUserName, partnerId, loggedInUserId, postType, PageContent, setCheckinType, gifType, setGifType, selectedAddLocation, setSelectedAddLocation } = useContext(ApiContext);



  useMemo(() => {
    if (Array.isArray(selectphotoAndVideo)) {
      const urls = selectphotoAndVideo.map((file) => URL.createObjectURL(file));
      setFileUrl(urls);
      // Cleanup function for revoking object URLs
      return () => {
        urls.forEach((url) => URL.revokeObjectURL(url));
      };
    }

  }, [selectphotoAndVideo]);





  const handleScreenshot = () => {
    setPostLoader(true);
    const element = document.querySelector(".screenshot-container");
    if (!element) return;

    // Create a canvas element
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    // Set canvas dimensions based on the element
    const rect = element.getBoundingClientRect();
    canvas.width = rect.width;
    canvas.height = rect.height;

    // Draw each image inside the grid onto the canvas
    const images = element.querySelectorAll("img");
    let imagesLoaded = 0;

    images.forEach((img, index) => {
      const imgRect = img.getBoundingClientRect();
      const offsetX = imgRect.left - rect.left;
      const offsetY = imgRect.top - rect.top;

      const newImage = new Image();
      newImage.src = img.src;

      newImage.onload = () => {
        context.drawImage(newImage, offsetX, offsetY, imgRect.width, imgRect.height);
        imagesLoaded++;

        // Check if all images have loaded
        if (imagesLoaded === images.length) {
          // Convert the canvas to a Blob
          canvas.toBlob(function (blob) {
            const formData = new FormData();
            formData.append("mediaUrls", blob, "screenshot.png");

            // Send the image data to the backend to get the media URL
            axios.post(`${BASEAPIURL}/post/postdataurl`, formData, config)
              .then(data => {
                console.log("data xyz", data.data)
                const mediaUrls = data.data.mediaUrls || ''; // You might want to adjust this to match your API response

                // Construct media post data
                const mediaPostData = {
                  category: "",
                  maintype: postType,
                  partnerUserId: partnerId,
                  description: description,
                  title: selectedAddLocation && selectedAddLocation.place,
                  emotion: selectedEmoji && selectedEmoji.emotion,
                  emotionName: selectedEmoji && selectedEmoji.emotionName,
                  emotionIcon: selectedEmoji && selectedEmoji.icon,
                  mediaUrls: mediaUrls, // Ensure mediaUrls is an array if that's expected
                  postTumbnailUrl: data.data.postTumbnailUrl || '', // Assuming this is a URL for the thumbnail
                  type: 'post'
                };
                let savedPost;

                // Call the API to create the media post
                axios.post(`${BASEAPIURL}/post/createmediapost`, mediaPostData, config)
                  .then(response => {
                    console.log("Post created successfully:", response.data.post);
                    savedPost = response.data.post;

                    setPostLoader(false);
                  })
                  .catch(error => {
                    console.error("Error creating post:", error);
                    setPostLoader(false);
                  });
                const finalData = {
                  ...savedPost,
                 
                  
                };

                console.log("Upload successful:", finalData);
                // setallDataPost(prev => [savedPost , ...prev]);

              })
              .catch(error => {
                console.error("Error uploading the screenshot:", error);
                setPostLoader(false);
              });
          }, "image/png");
        }


      };
    });
  };







  // const handleFileChange = (e) => {
  //   const files = e.target.files;
  //   const fileArray = Array.from(files).map((file) =>
  //     URL.createObjectURL(file)
  //   );
  //   setImages(fileArray);
  //   setShowModal(true);
  // };


  useEffect(() => {
    const root = parse(PageContent);
    setDescription(root.textContent)
  }, [PageContent])

  const inputRef = useRef();

  const handleAddMore = () => {
    inputRef.current.click()
  };

  useMemo(() => setDescription(PageContent), [PageContent]);

  useEffect(() => {
    const root = parse(PageContent);
    setDescription(root.textContent)
  }, [PageContent]);

  const handleSelectPhotoAndVideo = async (e) => {
    // const photoAndVideo = e.target.files;
    const selectedFiles = Array.from(e.target.files);
    setSelectedPhotoVideo(prev => [...selectedFiles, ...prev])
  };


  // const handleAddImages = (e) => {
  //   const files = e.target.files;
  //   const fileArray = Array.from(files).map((file) =>
  //     URL.createObjectURL(file)
  //   );
  //   setImages((prevImages) => [...prevImages, ...fileArray]);
  // };


  // const handleDelete = () => {

  //   if (Array.isArray(selectphotoAndVideo) && selectphotoAndVideo.length === 1) {
  //     setShowCreatePostPopup(true);
  //     setShowPVpopup(false)
  //     setSelectedPhotoVideo([]);
  //   }

  //   else if (Array.isArray(selectphotoAndVideo) && selectphotoAndVideo.length > 1) {
  //     const updatedFiles = [...selectphotoAndVideo];
  //     updatedFiles.splice(activeIndex, 1); // Remove the file at the active index
  //     setSelectedPhotoVideo(updatedFiles);
  //     setActiveIndex(prev => prev - 1);
  //   }
  // };


  // To handle create photo and video
  // const handleCreatePhotoAndVideoPopup2 = async (location, selectPhotoAndVideo) => {
  //   try {
  //     setPostLoader(true);
  //     let mediaUrls = [];
  //     let uploadResponse = '';

  //     for (let i = 0; i < selectPhotoAndVideo.length; i++) {
  //       const formData = new FormData(); // Create a new FormData for each file
  //       if (selectPhotoAndVideo[i].type.slice(0, 5) === 'image') {
  //         const compressedPhotoAndVideo = await imageCompression(selectPhotoAndVideo[i], postOption);

  //         formData.append("mediaUrls", selectPhotoAndVideo[i].type === 'image/gif' ? selectPhotoAndVideo[i] : compressedPhotoAndVideo);
  //         // formData.append("postTumbnailUrl", selectedImage[i]); 
  //         // Add thumbnail for images
  //       } else if (selectPhotoAndVideo[i].type.slice(0, 5) === 'video') {
  //         formData.append("thumbnail", selectedImage[i])
  //         formData.append("mediaUrls", selectPhotoAndVideo[i]);
  //         formData.append("postTumbnailUrl", selectedImage[0]); // Add thumbnail for videos
  //       }
  //       formData.append("audio", 'https://webaudioapi.com/samples/audio-tag/chrono.mp3');
  //       formData.append("start", '');
  //       formData.append("end", '');

  //       // Make the API call to upload each file
  //       uploadResponse = await axios.post(`${BASEAPIURL}/post/postdataurl`, formData, config);
  //       // Get the media URL from the response and push it to mediaUrls array
  //       const uploadsdata = uploadResponse.data.mediaUrls[0]; // Access mediaUrls correctly
  //       mediaUrls.push(uploadsdata);
  //     }

  //     const mediaPostData = {
  //       category: "",
  //       maintype: postType,
  //       partnerUserId: partnerId,
  //       description: description,
  //       title: selectedAddLocation && selectedAddLocation.place,
  //       emotion: selectedEmoji && selectedEmoji.emotion,
  //       emotionName: selectedEmoji && selectedEmoji.emotionName,
  //       emotionIcon: selectedEmoji && selectedEmoji.icon,
  //       mediaUrls: mediaUrls,
  //       postTumbnailUrl: uploadResponse.data.postTumbnailUrl || '',
  //       type: 'post'
  //     };

  //     // Call the API to create the media post
  //     const datamediaPostData = await axios.post(`${BASEAPIURL}/post/createmediapost`, mediaPostData, config);
  //     const savedPost = datamediaPostData.data.post;

  //     // Handle uploaded files info and tags
  //     const uploadedFilesInfo = mediaUrls; // Use mediaUrls, not uploadResponse anymore

  //     const finalResponse = uploadedFilesInfo.map((fileInfo, index) => {
  //       const tags = tagsByCarouselItem[index] || [];
  //       return {
  //         ...fileInfo,
  //         tags: tags.map(tag => ({
  //           x: tag.xVal,
  //           y: tag.yVal,
  //           tagUserId: tag.tagUserId,
  //           fname: tag.fname,
  //           username: tag.username,
  //           profileImg: tag.profileImg,
  //           postedBy: loggedInUserId,
  //         })),
  //       };
  //     });


  //     // Update the tags in the post
  //     await axios.post(`${BASEAPIURL}/post/updatetagpost`, {
  //       id: savedPost._id,
  //       mediaUrls: finalResponse,
  //     }, config);

  //     const finalData = {
  //       ...savedPost,
  //       mediaUrls: finalResponse,
  //     };

  //     if (postType === 'postType') {
  //       setallDataPost(prev => [finalData, ...prev]);
  //     }
  //     else if (postType === 'scType') {
  //       setscAllposts(prev => [finalData, ...prev])
  //     }
  //     else {
  //       setPosts(prev => [finalData, ...prev])
  //     }
  //     setShowPVpopup(false);
  //     setShowCreatePostPopup(false);

  //     // For notification
  //     const notificationRes = await axios.post(`${BASEAPIURL}/user/createnotifications`, {
  //       postId: savedPost._id,
  //       type: 'tag'
  //     }, config);

  //     //notification for tag .........
  //     const taguserids = finalResponse[0].tags.map(item => item.tagUserId);

  //     async function createNotifications() {
  //       for (let ids of taguserids) {
  //         const data = {
  //           targetId: ids,
  //           userType: "user",
  //           targetEvent: "tag",
  //           targetType: savedPost.type,
  //           targetEventId: savedPost._id,
  //           notificationType: "email",
  //         };

  //         try {
  //           await createNotification(data, config);

  //         } catch (error) {
  //           console.error("Error creating notification for user", ids, error);
  //         }
  //       }
  //     }
  //     createNotifications();

  //     notificationRes.data.tagdata.forEach(e => {
  //       const notificationData = {
  //         type: e.type,
  //         action: e.action,
  //         senderUserId: e.senderUserId,
  //         receiverUserId: e.receiverUserId,
  //         message: e.message,
  //         createdAt: e.createdTime,
  //         fname: e.fname,
  //         postId: e.postId,
  //         postImg: e.postImg,
  //         profileImg: e.profileImg,
  //         tumbnailImg: e.tumbnailImg,
  //       };

  //       socket.emit("send_message_emilo_notification", notificationData);
  //     });
  //   } catch (err) {
  //     setShowPVpopup(false);
  //     setPostLoader(false);
  //     setTagsByCarouselItem([])
  //   }
  //   finally {
  //     setPostLoader(false);
  //   }
  // };


  const handleAddLocation = () => {
    setCheckOpen(true);
    setShowPVpopup(false);
  };

  const handleCancleLocation = () => {
    setSelectedAddLocation(null);
  };

  const handleClose = () => {
    setShowPVpopup(false);
    setCheckOpen(false);
    setSelectedAddLocation(null);
    setSelectedEmoji(null);
    setSelectedPhotoVideo([]);
    setTagsByCarouselItem([]);
    setGifType("");
    setDescription("");
    setFileUrl([]);
    setActiveIndex(0);
  }

  // ----- Advance Setting -----


  // const handleSubmit = () => {
  //   if (fileUrl.length > 0) {
  //     console.log("Images uploaded:", fileUrl);
  //   } else {
  //     alert("Please select images first");
  //   }
  // };

  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  const removeImage = (index) => {
    const updatedImages = fileUrl.filter((_, i) => i !== index);
    setFileUrl(updatedImages);
  };

  // const handleAddImages = (e) => {
  //   const files = e.target.files;
  //   const fileArray = Array.from(files).map((file) =>
  //     URL.createObjectURL(file)
  //   );
  //   setImages((prevImages) => [...prevImages, ...fileArray]);
  // };

  const getImageStyle = (index) => {
    if (isEditing) {
      return {}; // Uniform size in edit mode
    } else if (fileUrl.length === 2 && index === 0) {
      return { gridColumn: "span 2" }; // Custom styling for specific cases
    } else if (fileUrl.length >= 5) {
      if (index < 2) return { gridColumn: "span 2" };
      if (index >= 3 && index < 5) return { gridColumn: "span 3" };
    } else {
      return {};
    }
  };

  const getImageHeight = () => {
    return isEditing ? "auto" : "200px"; // Uniform height for edit mode
  };









  const [showPostAdvanceSetttingPopup, setShowPostAdvanceSetttingPopup] = useState(false);

  return (
    <>
      <PostAdvanceSettingPopup
        showPostAdvanceSetttingPopup={showPostAdvanceSetttingPopup}
        setShowPostAdvanceSetttingPopup={setShowPostAdvanceSetttingPopup}
        setShowPVpopup={setShowPVpopup}
      />

      <NotificationpostPopup show={show} setShow={setShow} errorMsg={errorMsg} />

      <CreateGif gifType={gifType} setShowPVpopup={setShowPVpopup} setOpenEmoji={setOpenEmoji} setSelectedPhotoVideo={setSelectedPhotoVideo} selectphotoAndVideo={selectphotoAndVideo} selectedEmoji={selectedEmoji} setSelectedEmoji={setSelectedEmoji} openEmoji={openEmoji} close={() => setOpenGif(false)} setShowCreatePostPopup={setShowCreatePostPopup} />

      <CheckInpopup location={location} open={checkOpen} close={() => { setCheckOpen(false); }} setShowPVpopup={setShowPVpopup} setLocation={setLocation} checkinType="post" />

      <section>
        <Modal show={showPVpopup} onHide={() => { handleClose() }} className='create-post m-0' backdrop="static" centered >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            {
              openEmoji
                ?
                <>
                  <div className="row">
                    <p className="col-1" style={{ cursor: "pointer" }}
                      onClick={() => { setOpenEmoji(false) }}
                    >
                      <FontAwesomeIcon icon={faAngleLeft} className="back-icon" />
                    </p>
                    <p className="col-11 fs-6 fw-bold text-center mb-2">
                      Create Post
                    </p>
                  </div>

                  <EmojiPopup setOpenEmoji={setOpenEmoji} selectedEmoji={selectedEmoji} setSelectedEmoji={setSelectedEmoji} />
                </>
                :
                <>
                  <section className="create-reels-1" id="create-reels-1">
                    <div className="row">
                      <p className="col-1" style={{ cursor: "pointer" }}
                        onClick={() => { setShowCreatePostPopup(true); setShowPVpopup(false); setSelectedPhotoVideo([]) }}
                      >
                        <FontAwesomeIcon icon={faAngleLeft} className="back-icon" />
                      </p>
                      <p className="col-11 fs-6 fw-bold text-center mb-2">
                        Create Post
                      </p>
                    </div>

                    <div className="userDetail">
                      <div className="imgBox" style={{ marginLeft: '15px' }}>
                        <ProfileImage userId={loggedInUserId} height='50' width='50' borderRadius='50%' />
                      </div>
                      <div className="nameBox">
                        <div className="d-flex flex-md-row align-items-center">
                          <span className="fs-6">
                            {loggedInUserName}
                            {selectedEmoji && (
                              <span className="ms-1 d-inline-block" onClick={() => setOpenEmoji(true)}>
                                {selectedEmoji.emotion}
                                <img style={{ width: "20px", display: "inline", marginLeft: "5px", marginRight: "5px" }} src={selectedEmoji.icon} alt="icon" />
                                {selectedEmoji.emotionName}
                              </span>
                            )}
                          </span>
                        </div>

                        <div className="add-location-div">
                          <i className="fas fa-map-marker-alt" style={{ padding: '3px 3px 3px 0px' }} />

                          {selectedAddLocation ?
                            <>
                              <small className="text-truncate" style={{ maxWidth: "300px", cursor: 'pointer' }}>{selectedAddLocation.place}</small>
                              <span className="fs-5 cp" style={{ color: 'rgb(207 99 109)' }} onClick={handleCancleLocation}> <IoCloseCircle /></span>
                            </>
                            :
                            <small className="cp" onClick={handleAddLocation}>Add Location</small>
                          }

                          {/* <small onClick={handleAddLocation} className="cp">Add Location</small> */}
                          {/* <AddLocation location={location} setLocation={setLocation} /> */}
                        </div>
                      </div>
                    </div>

                    <div>
                      <textarea
                        style={{ resize: "none", width: "97%" }}
                        className='m-2 border border-1 rounded-2 p-2'
                        name="textArea"
                        id="textArea"
                        rows="3"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        //  defaultValue={}
                        placeholder="description"
                      ></textarea>
                    </div>

                    <div className="position-relative">

                      <div className="d-flex justify-content-between align-items-center m-2">
                        <button onClick={handleAddMore} className="pv_btn" style={{ color: "#64A6F0" }}> <IoAddOutline className="fw-bold" size={15} /> Add </button>

                        <button className="pv_dlt" onClick={toggleEdit}>
                          <BiTrash size={16} />
                        </button>
                      </div>


                      {/* <AddTags photoVideo={isPhotoVideo} tagsByCarouselItem={tagsByCarouselItem} setTagsByCarouselItem={setTagsByCarouselItem} selectphotoAndVideo={selectphotoAndVideo} fileUrl={fileUrl} setCheckOpen={setCheckOpen} setShowPVpopup={setShowPVpopup} selectedImage={selectedImage} setSelectedImage={setSelectedImage} /> */}

                      <input type="file" className="d-none" ref={inputRef} onChange={handleSelectPhotoAndVideo} accept="image/jpeg, image/png, image/gif, video/mp4, video/quicktime, video/webm" multiple style={{ display: "non" }} />



                      <div
                        // className="row row-cols-1 row-cols-sm-2 row-cols-md-3  g-3 mx-2 my-2  screenshot-container"
                        style={{ maxHeight: "600px", }}
                        // className={`${fileUrl.length > 5 ? 'row-cols-sm-3' : 'row-cols-md-2'} row g-3 mx-2 my-2 screenshot-container`}
                        className={`${fileUrl.length === 1 ? 'row-cols-md-1' :
                          fileUrl.length === 2 ? 'row-cols-md-2' :
                            fileUrl.length === 3 ? 'row-cols-md-3' :
                              fileUrl.length === 4 ? 'row-cols-md-2' :
                                fileUrl.length === 5 ? 'row-cols-md-2' : 'row-cols-sm-3'} row g-3 mx-2 my-2 screenshot-container`}



                      >

                        {/* Display images */}
                        {fileUrl.slice(0, isEditing ? fileUrl.length : 10).map((image, index) => (
                          <div
                            key={index}
                            className="col position-relative"
                            style={{ height: getImageHeight(), width: fileUrl.length === 5 && index === 4 ? "600px" : "" }}
                          >
                            <img
                              src={image}
                              alt={`Preview ${index}`}
                              className="w-100 h-100 object-fit-cover rounded"
                              style={getImageStyle(index)}
                            />
                            {/* Watermark for Additional Images */}
                            {index === 5 && fileUrl.length > 5 && !isEditing && (
                              <div className="position-absolute top-0 start-0 w-100 h-100 bg-dark bg-opacity-50 d-flex justify-content-center align-items-center text-white fs-1 fw-bold">
                                +{fileUrl.length - 5}
                              </div>
                            )}

                            {/* Delete Button */}
                            {isEditing && (
                              <Button
                                variant="danger"
                                size="sm"
                                className="position-absolute top-0 end-0 m-2"
                                onClick={() => removeImage(index)}
                              >
                                <RxCross2 />
                              </Button>
                            )}

                          </div>
                        ))}
                      </div>




                    </div>

                    {/* {thumbnail &&
                      <GetThumbnai videoIndex={videoIndex} selectedImage={selectedImage} setSelectedImage={setSelectedImage} file={selectphotoAndVideo[videoIndex]} status={status} setStatus={setStatus} />
                    } */}

                    <div className="iconBox iconBox_new">

                      <span className="c_new" onClick={() => { setCameraOpen(true); setShowPVpopup(false); }} style={{ cursor: "pointer" }}>
                        <img src={`${BASE_URL_STATIC}/create-icon2.png`} alt="" />
                        <span>Camera </span>
                      </span>

                      <span className="c_new" onClick={handleAddMore} style={{ cursor: "pointer" }}>
                        <img src={`${BASE_URL_STATIC}/create-icon3.png`} alt="create-icon3" />
                        <span> photo/video </span>
                      </span>


                      <span className="c_new" style={{ cursor: 'pointer' }} onClick={() => { setOpenEmoji(true); }}>
                        <img src={`${BASE_URL_STATIC}/create-icon8.png`} alt="" />
                        <span>Activity </span>
                      </span>

                      <span className="c_new" style={{ cursor: 'pointer' }} onClick={() => { setCheckinType("post"); setCheckIn(true); setShowPVpopup(false); }}>
                        <img src={`${BASE_URL_STATIC}/create-icon9.png`} alt="" />
                        <span> Check in </span>
                      </span>

                      <span className="c_new" style={{ cursor: 'pointer' }} onClick={() => { setOpenGif(true); setShowPVpopup(false); setGifType("post") }}>
                        <img src={`${BASE_URL_STATIC}/create-icon10.png`} alt="" />
                        <span>GIF </span>
                      </span>

                      <span className="c_new" style={{ cursor: 'pointer' }}>
                        <img src={`${BASE_URL_STATIC}/create-icon13.png`} alt="" />
                        <span>Collage </span>
                      </span>

                    </div>

                    <div className='col-12 m-auto'>
                      <button disabled={postLoader} onClick={() => handleScreenshot(location, selectphotoAndVideo)} className='text_post_btn'>{postLoader ? <span className='flex justify-content-center'><FontAwesomeIcon icon={faCircleNotch} style={{ color: "snow" }} spin /> posting...</span> : 'post'}</button>
                    </div>

                    <div className="text-center text-primary cursor-pointer mt-2"
                      onClick={() => {
                        setShowPostAdvanceSetttingPopup(true);

                        setShowPVpopup(false);
                      }}
                    >Advance Setting</div>
                  </section>
                </>
            }
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
};

export default CreatePhotoAndVideoCollage2;
