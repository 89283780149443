import axios from "axios";
import { AwsFileUrl, BASEAPIURL } from "../../../config";
import toast from "react-hot-toast";




//----------- POST Event (like || view || share || download)
export const postEvent = async (postId, eventType, postedBy, postType, config) => {
  try {
    await axios.post(`${BASEAPIURL}/post/postEvent`, { postId, eventType, postedBy, postType }, config)
  }
  catch (error) {
    return null;
  }
};

//----------- checkin post -------------
export const createCheckIn = async (obj, config) => {
  try {
    await axios.post(`${BASEAPIURL}/post/uploaduserpost`, obj, config);
  }
  catch (error) {
    return null;
  }
};

//----------- social Media User-------------
export const socialMediaUser = async (data) => {
  console.log("data", data)
  try {
    const res = axios.post(`${BASEAPIURL}/user/socialMediaUser`, {
      email: data.email,
      fname: data.fname,
      lname: data.lname,
      username: data.username,
      password: data.password,
      data : data.data,

    })

    return res
  } catch (error) {
    return null;
  }
};


//-------------------------------Trending Peoples---------------------------

export const getTrendingPeoples = async (config) => {
  try {
    const res = axios.post(`${BASEAPIURL}/user/trendinguser`, {}, config)

    return res
  } catch (error) {
    return null;
  }
};

//-------------------------------get User Posts---------------------------

export const getUserPosts = async () => {
  try {
    const res = axios.post(`${BASEAPIURL}/post/allpost`)
    return res.data.listpost;
  } catch (error) {
    return null;
  }
};

//------------------------------------------------------Create user-----------------------------------------
export const signupuser = async (userInfo) => {
  try {
    const formData = new FormData();
    formData.append('email', userInfo.email);
    formData.append('password', userInfo.password);
    formData.append('fname', userInfo.firstName);
    formData.append('lname', userInfo.lastName);
    formData.append('gender', userInfo.gender);
    formData.append('mobile', userInfo.phoneNumber);
    formData.append('username', userInfo.username);
    formData.append('mname', userInfo.middleName);
    formData.append('image', userInfo.profileImage)
    formData.append('dob', userInfo.dob)

    const res = await axios.post(`${BASEAPIURL}/user/usercreate`, formData);
    return res;

  } catch (error) {
    console.error("Error Creating the User:", error);
  }
};

//------------------------------------------------------Check Number-----------------------------------------


export const checkNumber = async (mobile) => {
  try {
    const res = await axios.post(`${BASEAPIURL}/user/findmobile`,
      { mobile },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    )

    return res.data.status
  } catch (error) {
    console.error("Error taking the number:", error);
  }
}


export const checkNumberforget = async (mobile) => {
  try {
    const res = await axios.post(`${BASEAPIURL}/user/findmobileforget`,
      { mobile },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    )

    return res.data.status
  } catch (error) {
    console.error("Error taking the number:", error);
  }
}
//------------------------------------------------------Check Email-----------------------------------------


export const checkEmail = async (email) => {
  try {
    const res = await axios.post(`${BASEAPIURL}/user/findemail`,
      { email },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    )
    return res.data.message
  } catch (error) {
    console.error("Error Creating the email:", error);
  }
}

//--------------------------------------------------  Relation -----------------------------------------

export const relationList = async (config) => {
  try {
    const res = await axios.post(`${BASEAPIURL}/user/sendSiblingsList`, {}, config)
    return res.data.relationships
  } catch (error) {
    return null;
  }
}

export const createRelation = async (data, config) => {
  try {
    const res = await axios.post(`${BASEAPIURL}/user/createSiblings`, data, config)
    return res.data.list
  } catch (error) {
    return null;
  }
}

export const getRelation = async (userId) => {
  try {
    const res = await axios.post(`${BASEAPIURL}/user/getSiblings`, { userId })
    return res.data.siblingsData
  } catch (error) {
    return null;
  }
}

export const deletRelation = async (siblingId, config) => {
  try {
    const res = await axios.post(`${BASEAPIURL}/user/deleteSibling`, { siblingId }, config)
    return res.data.siblingsData
  } catch (error) {
    return null;
  }
}

export const updateRelation = async (data, config) => {
  try {
    const res = await axios.post(`${BASEAPIURL}/user/updateSibling`, data, config)
    return res.data.list
  } catch (error) {
    return null;
  }
}


//--------------- Inspiration ---------------

export const createInspiration = async (inspiredBy, config) => {
  const res = await axios.post(`${BASEAPIURL}/user/createInspiration`, { inspiredBy }, config)

  return res.data
}

export const updateInspiration = async (inspirationId, inspiredBy, config) => {
  const res = await axios.post(`${BASEAPIURL}/user/updateInspiration`, { inspirationId, inspiredBy }, config)

  return res.data
}

export const deleteInspiration = async (inspirationId, config) => {
  const res = await axios.post(`${BASEAPIURL}/user/deleteInspiration`, { inspirationId }, config)

  return res.data
}

export const getInspiration = async (userId) => {
  const res = await axios.post(`${BASEAPIURL}/user/getInspiration`, { userId })

  return res.data.inspiration
}


//------------------------------------------------------Profile----------------------------------------------


export const myProfile = async (config) => {
  try {
    // Assuming education is an array of education objects
    const res = await axios.post(
      `${BASEAPIURL}/user/myprofile`, {}, config
    );
    return res.data.user;
  } catch (error) {
    console.error("Error finding education:", error);
  } finally {
  }
};

export const otherProfile = async (userId) => {
  try {
    // Assuming education is an array of education objects
    const res = await axios.post(
      `${BASEAPIURL}/user/getOtherProfile `,
      {
        userId
      }
    );
    return res.data.user;
  } catch (error) {
    console.error("Error finding education:", error);
  } finally {
  }
};

export const getProfileImage = async () => {
  try {
    const res = await axios.post(
      `${BASEAPIURL}/user/getprofileimage`,
      {
        userId: localStorage.getItem("profileUserId"),
      },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    return res.data.message;
  } catch (error) {
    return null
  } finally {
  }
};

//------------------------------------------------ places Lived ----------------------------------

// create user address

export const selectCountry = async () => {
  try {
    const response = await axios.post(
      `${BASEAPIURL}/user/selectCountry`
      , {},
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log('error agya', error);
  }
};

export const selectStates = async (name) => {
  try {
    const response = await axios.post(
      `${BASEAPIURL}/user/selectStates`
      , { name },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log('error agya', error);
  }
};

export const selectCities = async (name, states) => {
  try {
    const response = await axios.post(
      `${BASEAPIURL}/user/selectCities`
      , { name, states },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log('error agya', error);
  }
};


export const createAddress = async (addressDetails) => {
  try {
    const response = await axios.post(
      `${BASEAPIURL}/user/createuseraddress`,
      addressDetails
      ,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      }
    );
    return response
  } catch (error) {
    toast.error(error.response.data.message)
  }
};

// get address of user
export const getAddressDetails = async (userId) => {
  try {
    // Assuming education is an array of education objects
    const res = await axios.post(
      `${BASEAPIURL}/user/getuseraddress`,
      {
        userId
      }

    );
    return res.data.address;
  } catch (error) {
    console.error("Error finding education:", error);
  } finally {
  }
};

// delete user one address
export const deleteOneAddress = async (addressId) => {
  try {
    await axios.post(
      `${BASEAPIURL}/user/deleteoneuseraddress`,
      { addressId },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
  } catch (error) {
    console.error("Error deleting:", error);
  } finally {
  }

};

export const updateAddress = async (addressId) => {
  try {
    await axios.post(
      `${BASEAPIURL}/user/updateuseraddress`,
      addressId,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
  } catch (error) {
    console.error("Error updating:", error);
  } finally {
  }

};

//------------------------------------------------ Educational Details ----------------------------------

// create educational details
export const createUserEducation = async (eduInput) => {
  try {
    // Assuming education is an array of education objects
    await axios.post(
      `${BASEAPIURL}/user/createusereducation`,
      {
        schColl: eduInput.schColl,
        board: eduInput.board,
        degree: eduInput.degree,
        startDate: eduInput.startDate,
        endDate: eduInput.endDate,
        instituteName: eduInput.instituteName,
      },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
  } catch (error) {
    console.error("Error creating social:", error);
  } finally {
  }
};

// get education details

export const getEducationDetails = async (userId) => {
  try {
    // Assuming education is an array of education objects
    const res = await axios.post(
      `${BASEAPIURL}/user/getusereducation`,
      {
        userId
      }
    );
    return res.data.education;
  } catch (error) {
    console.error("Error finding education:", error);
  } finally {
  }
};

export const deleteEducationalDetails = async (educationId) => {
  try {
    // Assuming education is an array of education objects
    const res = await axios.post(
      `${BASEAPIURL}/user/deleteoneusereducation`,
      {
        educationId,
      },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    return res;
  } catch (error) {
    console.error("Error finding education:", error);
  } finally {
  }
};

export const updateEducation = async (educationId) => {
  try {
    await axios.post(
      `${BASEAPIURL}/user/updateusereducation`,
      educationId,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
  } catch (error) {
    console.log("error update education", error);
  } finally {
  }

};

//------------------------------------------------ Social Media ------------------------------------
// create educational details
export const socialMediaDetail = async (account) => {
  try {
    // Assuming education is an array of education objects
    const res = await axios.post(
      `${BASEAPIURL}/user/createusersocial`,
      {
        userhandle: account.userhandle,
        sociallink: account.sociallink,
        plateform: account.plateform,
      },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );

    return res.data;
  } catch (error) {
    console.error("Error creating social:", error);
  } finally {
  }
};

// get socail Deatils
export const getSocialDetails = async (userId) => {
  try {
    // Assuming education is an array of education objects
    const res = await axios.post(
      `${BASEAPIURL}/user/getusersocial`,
      {
        userId
      }

    );
    // return res.data

    return res.data.socialmedia;

    // console.log('socail' , res);
  } catch (error) {
    console.error("Error finding education:", error);
  } finally {
  }
};

// delete socicalDeatails of user
export const deleteSocialDetails = async (socialMediaId) => {
  try {
    // Assuming education is an array of education objects
    await axios.post(
      `${BASEAPIURL}/user/deleteoneusersocial`,
      {
        socialMediaId,
      },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
  } catch (error) {
    console.error("Error finding social:", error);
  } finally {
  }
};

export const updateSocial = async (socialMediaId) => {
  try {
    await axios.post(
      `${BASEAPIURL}/user/updateusersocial`,
      socialMediaId,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
  } catch (error) {
    console.log("error update social", error);
  }
  finally {
  }

};

//------------------------------------------------ Hangouts ------------------------------------
// create hangout details
export const hangoutDetail = async (hangout) => {
  try {
    // Assuming education is an array of education objects
    const res = await axios.post(
      `${BASEAPIURL}/user/createuserhangoutplace`,
      {
        placeName: hangout.place,
        placeType: hangout.type,
        placeLocation: hangout.location,
      },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );

    return res.data;
  } catch (error) {
    console.error("Error creating hangout:", error);
  } finally {
  }
};

// get socail Deatils
export const getHangoutDetails = async (userId) => {
  try {
    const res = await axios.post(
      `${BASEAPIURL}/user/getuserhangoutplace`,
      {
        userId
      }
    );

    return res.data.socialmedia;
  } catch (error) {
    console.error("Error finding hangout:", error);
  } finally {
  }
};


// delete socicalDeatails of user
export const deleteHangoutDetails = async (hangoutPlaceId) => {
  try {
    // Assuming education is an array of education objects
    await axios.post(
      `${BASEAPIURL}/user/deleteonehangoutplace`,
      {
        hangoutPlaceId,
      },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
  } catch (error) {
    console.error("Error finding hangout:", error);
  } finally {
  }
};

// update hangoutDetails
export const updateHangout = async (hangoutPlaceId) => {

  try {
    await axios.post(
      `${BASEAPIURL}/user/updateuserhangoutplace`,
      hangoutPlaceId,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
  } catch (error) {
    console.log("error update hangout", error);
  } finally {
  }
};


//------------------------------------------------ work ------------------------------------

export const workDetail = async (work) => {
  try {
    // Assuming education is an array of education objects
    const res = await axios.post(
      `${BASEAPIURL}/user/createuserwork`,
      {
        companyName: work.companyName,
        jobTitle: work.jobTitle,
        campanyLocation: work.campanyLocation,
        currentlyWorking: work.currentWorking,
        aboutCompany: work.about,
        startDate: work.startDate,
        endDate: work.endDate,
        workType: work.workType
      },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );

    return res;
  } catch (error) {
    console.error("Error creating hangout:", error);
  } finally {
  }
};

export const getWorkDetails = async (userId) => {
  try {
    const res = await axios.post(
      `${BASEAPIURL}/user/getuserwork`,
      {
        userId
      }

    );
    return res.data.work;
  } catch (error) {
    console.error("Error finding hangout:", error);
  } finally {
  }
};

export const deleteWorkDetails = async (workId) => {
  try {
    await axios.post(
      `${BASEAPIURL}/user/deleteoneuserwork`,
      {
        workId,
      },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
  } catch (error) {
    console.error("Error finding hangout:", error);
  } finally {
  }
};

export const updateWork = async (workId) => {
  try {
    await axios.post(`${BASEAPIURL}/user/updateuserwork`, workId, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });

  } catch (error) {
    console.log("error update word", error);
  }
  finally {
  }
};


//------------------------------------------------ Movies ------------------------------------

export const favMovie = async (movieName, movieDescription, config) => {
  try {

    const res = await axios.post(`${BASEAPIURL}/user/createUserFavMovies`, {
      movieName,
      movieDescription
    }, config)

    return res.data;

  } catch (error) {
    return null;
  }
}

export const getFavMovie = async (userId) => {
  const res = await axios.post(`${BASEAPIURL}/user/getUserFavMovies`, { userId })

  return res.data.movies;
}

export const deleteFavMovie = async (movieId, config) => {
  const res = await axios.post(`${BASEAPIURL}/user/deleteUserFavMovie`, { movieId }, config)

  return res.data;
}

export const updateUserMovie = async (movieId, movieName, movieDescription, config) => {
  const res = await axios.post(`${BASEAPIURL}/user/updateUserFavMovie`, { movieId, movieName, movieDescription }, config)

  return res.data;
}


//------------------------------------------------ Foods ------------------------------------
export const favFood = async (foodName, foodDetail, config) => {
  try {

    const res = await axios.post(`${BASEAPIURL}/user/createUserFavFood`, {
      foodName, foodDetail
    }, config)

    return res.data;

  } catch (error) {
    return null;
  }
}

export const getFavFood = async (userId) => {
  const res = await axios.post(`${BASEAPIURL}/user/getFavFood`, { userId }
  )

  return res.data.foods;
}

export const deleteFavFood = async (foodId, config) => {
  const res = await axios.post(`${BASEAPIURL}/user/deleteFood`, { foodId }, config)

  return res.data;
}

export const updateFavFood = async (foodId, foodName, foodDetail, config) => {
  const res = await axios.post(`${BASEAPIURL}/user/updateUserFood`, { foodId, foodName, foodDetail }, config)

  return res.data;
}


//------------------------------------------------ skills ------------------------------------

export const skillsDetail = async (skills) => {
  try {
    // Assuming education is an array of education objects
    const res = await axios.post(
      `${BASEAPIURL}/user/createuserskill`,
      {
        skillCategory: skills.category,
        skillName: skills.skill,
        skillLevel: skills.range,
      },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );

    return res

  } catch (error) {
    console.error("Error creating skill:", error);
  } finally {
  }
};

export const getSkillDetails = async (userId) => {
  try {
    const res = await axios.post(
      `${BASEAPIURL}/user/getuserskill`,
      {
        userId
      },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );

    return res.data.work;
  } catch (error) {
    console.error("Error finding skill:", error);
  } finally {
  }
};

export const deleteSkillDetails = async (skillId) => {
  try {
    // Assuming education is an array of education objects
    await axios.post(
      `${BASEAPIURL}/user/deleteoneuserskill`,
      {
        skillId,
      },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
  } catch (error) {
    console.error("Error finding skill:", error);
  } finally {
  }
};

// update skill
export const updateSkills = async (skillId) => {
  await axios.post(`${BASEAPIURL}/user/updateuserskill`, skillId, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
};
//------------------------------------------------ Hobby ------------------------------------

export const createHobby = async (hobbyName) => {
  try {
    // Assuming education is an array of education objects
    await axios.post(
      `${BASEAPIURL}/user/createuserhobby`,
      {
        hobbyName,
      },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
  } catch (error) {
    console.error("Error creating social:", error);
  } finally {
  }
};

export const updateHobby = async (hobbyName, hobbyId) => {
  await axios.post(`${BASEAPIURL}/user/updateUserHobby`, { hobbyName, hobbyId }, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
};

export const getHobby = async (userId) => {
  try {
    const res = await axios.post(
      `${BASEAPIURL}/user/getuserhobby`,
      {
        userId
      }

    );

    return res.data.hobby;
  } catch (error) {
    console.error("Error finding skill:", error);
  } finally {
  }
};

//------------------------------------------------ Reels ------------------------------------

export const postReel = async (formData) => {
  try {
    const res = await axios.post(
      `${BASEAPIURL}/post/uploaduserreel`,
      formData,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    return res.data;

    // console.log("res reel" , res);
  } catch (error) {
    console.error("Error Posting the reel:", error);
  } finally {
  }
};

export const getReelPosts = async () => {
  try {
    const res = await axios.post(
      `${BASEAPIURL}/post/getuserreel`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );

    console.log("res reel", res);
  } catch (error) {
    console.error("Error finding Reel Posts:", error);
  } finally {
  }
};

export const getUserReels = async (userId) => {
  try {
    const res = await axios.post(
      `${BASEAPIURL}/post/getuserreel`,
      { userId },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    return res.data.userReel;
  } catch (error) {
    console.error("Error finding user Reel Posts:", error);
  } finally {
  }
};

export const getAllReels = async () => {
  try {
    const res = await axios.post(
      `${BASEAPIURL}/post/getallreel`, {},
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    return res.data.allReel;
  } catch (error) {
    console.error("Error finding all reels:", error);
  } finally {
  }
};

export const getReelsDetails = async (reelId) => {
  try {
    const res = await axios.post(
      `${BASEAPIURL}/post/getsharereeldetails`, { reelId: reelId },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    return res.data.reelDetails;
  } catch (error) {
    console.error("Error finding all reels:", error);
  } finally {
  }
};


export const countLikeReel = async (reelId) => {
  try {
    const res = await axios.post(
      `${BASEAPIURL}/post/checklikereel`, { reelId },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    return res.data.liked;
  } catch (error) {
    console.error("Error like:", error);
  } finally {
  }
};

export const LikeReel = async (reelId, action) => {
  try {
    const res = await axios.post(
      `${BASEAPIURL}/post/likereel`, { reelId: reelId, action: action },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    return res.data;
  } catch (error) {
    console.error("Error liking the reel:", error);
  } finally {
  }
};

export const shareReel = async (reelId) => {
  try {
    const res = await axios.post(
      `${BASEAPIURL}/post/sharereelcountcreate`, { reelId: reelId },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    return res;
  } catch (error) {
    console.error("Error sharing the reel:", error);
  } finally {
  }
};

//----------------------------------------------------Memes----------------------------------------------------------

export const postMeme = async (formData) => {
  try {
    const res = await axios.post(
      `${BASEAPIURL}/post/uploadusermeme`, formData,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    return res.data;
  } catch (error) {
    console.error("Error Posting the meme:", error);
  } finally {
  }
};

export const getUserMemes = async (userId) => {
  try {
    const res = await axios.post(
      `${BASEAPIURL}/post/getallusermeme`, { userId },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    return res.data;
  } catch (error) {
    console.error("Error getting user meme:", error);
  } finally {
  }
};

export const getMemebyID = async (memeId) => {
  try {
    const res = await axios.post(
      `${BASEAPIURL}/post/getsinglememe`, { memeId: memeId },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    return res.data.listMeme;
  } catch (error) {
    console.error("Error getting user meme:", error);
  } finally {
  }
};

export const getAllMemes = async () => {
  try {
    const res = await axios.post(
      `${BASEAPIURL}/post/getallmeme`, {},
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    return res.data.listMeme;
  } catch (error) {
    console.error("Error getting all meme:", error);
  } finally {
  }
};

export const postViewMeme = async (memeId) => {
  try {
    const res = await axios.post(
      `${BASEAPIURL}/post/memeviewcountcreate`, { memeId },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    return res;
  } catch (error) {
    console.error("Error viewing meme:", error);
  } finally {
  }
};

export const sharememeCount = async (memeId) => {
  try {
    const res = await axios.post(
      `${BASEAPIURL}/post/sharememecountcreate`, { memeId },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    return res;
  } catch (error) {
    console.error("Error sharing memeCount:", error);
  } finally {
  }
};







//--------------------------------------------------Best Clips----------------------------------------------------

export const postBestClip = async (formData) => {
  try {
    const res = await axios.post(
      `${BASEAPIURL}/post/uploaduserbestclip`, formData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        },
      }
    );
    return res.data;
  } catch (error) {
    console.error("Error Posting the meme:", error);
  } finally {
  }
};

export const getAllBestClips = async () => {
  try {
    const res = await axios.post(
      `${BASEAPIURL}/post/getallbestclip`, {},
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    return res.data.listBestclip;
  } catch (error) {
    console.error("Error getting all meme:", error);
  } finally {
  }
};

export const getBestClipById = async (bestclipId) => {
  try {
    const res = await axios.post(
      `${BASEAPIURL}/post/getsinglebestclip`, { bestclipId },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        },
      }
    );
    return res.data.listBestclip;
  } catch (error) {
    console.error("Error getting all meme:", error);
  } finally {
  }
};


export const shareBestclipCount = async (bestclipId) => {
  try {
    const res = await axios.post(
      `${BASEAPIURL}/post/sharebestclipcreate`, { bestclipId },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    return res;
  } catch (error) {
    console.error("Error sharing meme:", error);
  } finally {
  }
};

export const postViewBestClip = async (bestclipId) => {
  try {
    const res = await axios.post(
      `${BASEAPIURL}/post/bestclipviewcountcreate`, { bestclipId },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    return res;
  } catch (error) {
    console.error("Error viewing Best Clip:", error);
  } finally {
  }
};


//----------------------------------------------Download files-------------------------------------------

export const handleDownloadReel = (reelId) => {
  const fileName = `reel_${reelId}.mp4`;
  const videoUrl = `${BASEAPIURL}/post/getvideobyid?reelId=${reelId}`

  axios({
    url: videoUrl,
    method: 'GET',
    responseType: 'blob',
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
}

export const handleDownloadPosts = (post) => {
  if (post && post.imageDetails && post.imageDetails.length > 0) {
    post.imageDetails.forEach((image, index) => {
      const downloadUrl = image.imageUrl;

      const link = document.createElement('a');
      link.href = downloadUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }
}

//-----------------------------------------------------------Comments-----------------------------------------------------

//--------------------------getComment------------------------

export const getMemeComment = async (memeId, config) => {
  const res = await axios.post(`${BASEAPIURL}/post/getmemecomment`, { memeId }, config)
  return res.data.reelcommentList
};
export const getPostComment = async (postId, config) => {


  const res = await axios.post('http://localhost:3001/post/getAllcomments', { postId }, config)

  return res.data
};
export const getReelComment = async (reelId, config) => {
  const res = await axios.post(`${BASEAPIURL}/post/getreelcomment`, { reelId }, config)
  return res.data.reelcommentList
};
export const getBesctclipComment = async (bestclipId, config) => {
  const res = await axios.post(`${BASEAPIURL}/post/getbestclipcomment`, { bestclipId }, config)
  return res.data.bestclipCommentList
};

//--------------------------CreateComment------------------------

export const createPostComment = async (postId, comment, config) => {
  await axios.post(`${BASEAPIURL}/post/createpostcomment`, { postId, comment: comment }, config)
};
export const createMemeComment = async (memeId, comment, config) => {
  await axios.post(`${BASEAPIURL}/post/creatememecomment`, { memeId, comment: comment }, config)
};
export const createreelComment = async (reelId, comment, config) => {
  await axios.post(`${BASEAPIURL}/post/createreelcomment`, { reelId, comment: comment }, config)
};
export const createBestclipComment = async (bestclipId, comment, config) => {
  await axios.post(`${BASEAPIURL}/post/createbestclipcomment`, { bestclipId, comment: comment }, config)
};

//------------------Emilo-Chats---------------

export const GetchatUserList = async (chatId, config) => {
  const res = await axios.post(`${BASEAPIURL}/user/chatuserlist`, { chatId }, config)
  return res.data.message
};

export const GetchatProfile = async (chatId, config) => {
  const res = await axios.post(`${BASEAPIURL}/user/getchatprofile`, { chatId }, config)

  return res.data.response

};

export const UpdatechatProfile = async (formdata, config) => {
  const res = await axios.post(`${BASEAPIURL}/user/updatechatprofile`, formdata, config)
  return res.data
};

export const makeGroupAdmin = async (userId, chatId, check, config) => {
  const res = await axios.post(`${BASEAPIURL}/user/makeuseradmin`, { userId, chatId, check }, config)
  return res.data
};

export const AddUsers = async (userId, chatId, action, config) => {
  const res = await axios.post(`${BASEAPIURL}/user/adduserIngroup`, { userId, chatId, action: action }, config)
  return res.data
};

//------------------------ email-otp-send-------------

export const email_otp_send = async (email) => {
  const res = await axios.post(`${BASEAPIURL}/user/otp-mail`, { email })
  return res.data
};


//-----------------------------forget password--------

export const fp_CreateNewPassword = async (mobile, email, newPassword) => {
  let data = { mobile, email, newPassword };



  const res = await axios.post(`${BASEAPIURL}/user/new-password`, data);
  return res.data;
};

//-----------------------------change password--------

export const cp_ChangePassword = async (password, newpass, confirmpass, config) => {
  const res = await axios.post(`${BASEAPIURL}/user/changepass`, { password, newpass, confirmpass }, config);
  return res.data;
};


// create pin chat

export const CreatePinChat = async (chatId, action, config) => {
  const res = await axios.post(`${BASEAPIURL}/user/createpinchat`, { chatId, action: action }, config)
  return res.data
};

export const CreateHideChat = async (chatId, action, config) => {
  const res = await axios.post(`${BASEAPIURL}/user/createhidechat`, { chatId, action: action }, config)
  return res.data
};

export const checkPinChat = async (chatId, config) => {
  const res = await axios.post(`${BASEAPIURL}/user/checkpinchat`, { chatId }, config)
  return res.data
};

export const deleteEmiloMsg = async (messageId, chatId, config) => {
  const res = await axios.post(`${BASEAPIURL}/user/deleteemmessage`, { messageId, chatId }, config)
  return res.data
};

export const ReplyEmiloMessage = async (message, replyMessageId, replyfileUrl, replyThumbnailUrl, replyfileType, chatId, type, config) => {
  const res = await axios.post(`${BASEAPIURL}/user/createemmessage`, { message, replyMessageId, replyfileUrl, replyfileType, replyThumbnailUrl, chatId, type }, config)
  return res.data
}

export const ClearEmiloMessage = async (chatId, config) => {
  const res = await axios.post(`${BASEAPIURL}/user/clearemchattime`, { chatId }, config)
  return res.data
}

export const messageSeen = async (userId, chatId, messageId, config) => {
  const res = await axios.post(`${BASEAPIURL}/user/messageSeenBy`, { userId, chatId, messageId }, config)
  return res.data
}

export const editMessage = async (messageId, message, config) => {
  const res = await axios.post(`${BASEAPIURL}/user/editMessage`, { messageId, message, isEdited: "1" }, config)
  return res.data
}

export const deleted_everyOne_Message = async (messageId, fileUrl, config) => {
  const res = await axios.post(`${BASEAPIURL}/user/editMessage`, { messageId, message: "This message was deleted", isEdited: "2", fileUrl }, config)
  return res.data
}

export const createReaction = async (messageId, chatId, reactions, config) => {
  const res = await axios.post(`${BASEAPIURL}/user/create_reactions`, { messageId, chatId, reactions }, config)
  return res.data
}

export const getChatReaction = async (messageId, config) => {
  const res = await axios.post(`${BASEAPIURL}/user/get_chat_reactions`, { messageId }, config)
  return res.data.data
}

export const deleteReaction = async (messageId, reactionId, config) => {
  const res = await axios.post(`${BASEAPIURL}/user/delete_reactions`, { messageId, reactionId }, config)
  return res.data
}

export const update_profile_image = async (file, config) => {
  const formdata = new FormData()

  formdata.append("image", file)

  const res = await axios.post(`${BASEAPIURL}/user/upload_profile_image`, formdata, config)

  return res
}

export const reportMessage = async (chatId, messageId, reportedto, reportMessage, targetMessage, config) => {
  const res = await axios.post(`${BASEAPIURL}/user/report_message`, { chatId, messageId, reportedto, reportMessage, targetMessage }, config)
  return res.data
}

export const deteleGroup = async (chatId, config) => {
  const res = await axios.post(`${BASEAPIURL}/user/delete_group`, { chatId }, config)
  return res
}

export const getMediaFiles = async (chatId, config) => {
  const res = await axios.post(`${BASEAPIURL}/user/get_media_files`, { chatId }, config)
  return res.data.getData
}

export const getDocFiles = async (chatId, config) => {
  const res = await axios.post(`${BASEAPIURL}/user/get_doc_files`, { chatId }, config)
  return res.data.getData
}

export const getPostData = async (userid, type, config) => {
  const res = await axios.post(`${BASEAPIURL}/post/getuserprofiledata`, { userid, type: `${type}` }, config)
  return res.data
}

export const getEventPopupData = async (postId, eventType, config) => {
  const res = await axios.post(`${BASEAPIURL}/post/listofuserevents`, { postId, eventType }, config)
  return res.data.data
}

//-----------------------------PostData--------------------------------------------

export const getAllPostData2 = async (mainType, page, config) => {
  const res = await axios.post(`${BASEAPIURL}/post/getallpost?limit=10&page=${page}`, {}, config)
  return res.data
}

export const getAllBusinessPost = async (page, config) => {
  const res = await axios.post(`${BASEAPIURL}/post/get_all_business_post?limit=10&page=${page}`, {}, config)
  return res.data
}

export const getTrendingFeedsApi = async (page, config) => {
  const res = await axios.post(`${BASEAPIURL}/post/trendingfeeds?limit=10&page=${page}`, {}, config)
  return res.data
}

export const getExplorePost = async (page, limit, config) => {
  const res = await axios.post(`${BASEAPIURL}/post/getExplorePost?limit=${limit}&page=${page}`, {}, config)
  return res
}

export const uploadpostUrl = async (formData, config) => {
  const res = await axios.post(`${BASEAPIURL}/post/postdataurl`, formData, config)
  return res.data
}


export const uploadPost = async (data, config) => {
  const res = await axios.post(`${BASEAPIURL}/post/createmediapost`, data, config)
  return res.data
}

export const getDataTypePost = async (type, maintype, category, page, config, limit, isVideo) => {
  const res = await axios.post(`${BASEAPIURL}/post/getdatatypepost?page=${page}&limit=${limit || 10}`, { type, maintype, category, isVideo }, config)
  return res
}

export const getStoryDataType = async (type, maintype, category, page, config, limit) => {
  const res = await axios.post(`${BASEAPIURL}/post/getstorydatatype?page=${page}&limit=${limit || 10}`, { type, maintype, category }, config)
  return res.data.posts
}

export const getOnePost = async (id, config) => {
  const res = await axios.post(`${BASEAPIURL}/post/getonepost`, { id }, config)
  return res.data.post
}

export const fetchLikeStatus = async (postId, config) => {
  const res = await axios.post(`${BASEAPIURL}/post/fetchlikestatus`, { postId }, config)
  return res.data.isLiked
}

export const createNotification = async (data, config) => {
  const res = await axios.post(`${BASEAPIURL}/user/createnotifications`, data, config)
  return res.data.data
}

export const getNotification = async (config) => {
  const res = await axios.post(`${BASEAPIURL}/user/getnotifications`, {}, config)
  return res.data.data
}

export const seenNotifications = async (id, config) => {
  const res = await axios.post(`${BASEAPIURL}/user/seen_notifications`, { id }, config)
  return res.data.data
}

export const updateNotification = async (config) => {
  const res = await axios.post(`${BASEAPIURL}/user/update_notifications`, {}, config)
  return res.data.message
}

export const checkAccountPrivacy = async (otherUserId, config) => {
  const res = await axios.post(`${BASEAPIURL}/setting/checkOtherUserAccountPrivacy`, { otherUserId }, config)
  return res.data.message
}

export const getProfileUser = async (userId, config) => {
  const res = await axios.post(`${BASEAPIURL}/user/getoneuser`, {userId}, config)
  return res
}

export const GetuserFavorite = async (post, config) => {
  const res = await axios.post(`${BASEAPIURL}/post/getuserfavorite`, { postType: `${post}` }, config)
  return res.data
}

export const viewCountLikeCountforPost = async (id, config) => {
  const res = await axios.post(`${BASEAPIURL}/post/viewCountLikeCountforPost`, { id }, config)
  return res.data
}

export const getStoriesFollowingUsers2 = async (id, config) => {
  const res = await axios.post(`${BASEAPIURL}/post/viewCountLikeCountforPost`, { id }, config)
  return res.data
}


export const deleteuserPostv2 = async (id, config) => {
  const res = await axios.post(`${BASEAPIURL}/post/deleteuserPostv2`, id, config)
  return res.data
}
// To get user stories
export const getUserStories = async (userId, config) => {
  const res = await axios.post(`${BASEAPIURL}/post/getUserStories`, { userId, AwsFileUrl }, config)
  return res.data

};

export const AudienceControlApi = async (data, config) => {
  const res = await axios.post(`${BASEAPIURL}/setting/audiencecontrole`, data, config)
  return res.data

};
export const editPostDescription = async (data, config) => {
  const res = await axios.post(`${BASEAPIURL}/post/editpostdescription`, data, config)
  return res.data

};





// export const fetchGif = async (query, config) => {
//   const res = await axios.get(`https://tenor.googleapis.com/v2/search?q=${query}&key=AIzaSyBf3LohXqKDspq-Iid9_x-KbdXSFRF4Roc&client_key=2&limit=2`)

//   return res.results
// }

export const fetchGif = async (query) => {
  const res = await axios.get(`https://tenor.googleapis.com/v2/search?q=${query || "trending"}&key=AIzaSyBf3LohXqKDspq-Iid9_x-KbdXSFRF4Roc&client_key=2&limit=32`)
  return res.data.results
};

export const BlockUnblockApi = async ( otherUserId,  action,  config) => {
  const res = await   axios.post(`${BASEAPIURL}/user/handleBlockUnblock`, { otherUserId: otherUserId, action: action }, config)
  return res.data

};
export const AdvenceSearchapi = async (keywords,  config) => {
  const res = await   axios.post(`${BASEAPIURL}/user/getalluserv3?description=${keywords}`, {},  config)
  return res.data

};

export const  followlistApi = async (list,  status,  config) => {
  const res = await   axios.post(`${BASEAPIURL}/user/userallfollowinglist2`, {list: list,  status :  status},  config)
  return res.data
};












