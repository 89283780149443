
import React, { useEffect, useState, useContext } from 'react';
import Modal from "react-bootstrap/Modal";
import { ApiContext } from '../../UseContext/ApiCall';


import axios from 'axios';


import { BASEAPIURL, getTodayDate, headerUserAPI } from '../../../../config';

const ServicePost = ({ type }) => {


  const { showServicePopup, setShowServicePopup,  } = useContext(ApiContext);


  const issues = [
    "My account has been compromised",
    "I need to report inappropriate content on eMilo (e.g., image, group, or page)",
    "I believe this account is fraudulent",
    "Someone is impersonating me",
    "Someone is pretending to be someone I know",
    "My email address is being used for someone else's eMilo account",
    "My personal photos, or those of my child, are being used without my consent",
    "There is content on eMilo that infringes on my rights",
    "I have encountered an underage user on eMilo",
    "Someone is threatening to expose private information",
    "Harassment or bullying is occurring",
    "Other types of abuse"
  ];

  function showMessage(message) {
    if (message.status && Number(message.status) === 1) {
      return <div className='text-success mt-2'>{message.message}</div>;
    }
    return <div className='text-danger mt-2'>{message.message}</div>;
  }

  const [formData, setFormData] = useState(
    {
      type: '',
      captcha1: Math.floor(Math.random() * 9) + 1,
      captcha2: Math.floor(Math.random() * 9) + 1,
      captcha: 0,
      apiCall: false,
    }
  );
  async function sendReportAny() {
    if (formData.captcha1 + formData.captcha2 !== Number(formData.captcha)) {
      setFormData({ ...formData, status: 0, message: 'Please Enter Correct Addion on human Verification.' });
      return false;
    }
    setFormData({ ...formData, apiCall: true });
    try {
      const headers = headerUserAPI(); // Assuming this returns headers
      await axios.post(`${BASEAPIURL}/user/sendReportAny`, { forDate: getTodayDate(), ...formData, get: new Date() }, { headers: headers });
      setFormData({ ...formData, apiCall: false });
    } catch (error) {
      console.error('Error making post request:');
      console.error(error.message);
      setFormData({ ...formData, apiCall: false });
      // You could also handle displaying error feedback to the user here
    }
  }

  useEffect(() => {
    // For Anythings
    // For Anythings
  }, []);


  return (
    <>

      <Modal show={showServicePopup} onHide={() => setShowServicePopup(false)} centered backdrop="static" keyboard={false} className="main-modal" size="md">
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body className="">
          <form
            method="POST"
             style={{maxHeight : "600px",  overflowY : "auto" }}
            className="space-y-4 p-6 border border-gray-300 mt-5 p-3 rounded-md shadow-md bg-white"
            onClick={() => {
              setFormData({ ...formData, message: '' });
            }}
            onSubmit={(e) => {
              e.preventDefault();
              sendReportAny();
            }}
          >
            <div className="form-group">
              <b>Type</b>
              <br />
              {issues.map((s, i) => (
                <div key={i} className="form-group d-flex mt-2">
                  <input
                    type="radio"
                    id={`issue-${i}`}
                    value={s}
                    name="type"
                    className="mr-2"
                    onChange={(e) => {
                      setFormData({ ...formData, type: e.target.value });
                    }}
                  />
                  <label htmlFor={`issue-${i}`} className="cursor-pointer">
                    {s}
                  </label>
                </div>
              ))}
            </div>
            <div className="form-group mt-3">
              <b>
                How would you best describe the issue you want to report?
              </b>
              <input
                type="text"
                className="mt-2 w-full p-2 border border-gray-300 rounded-md form-control"
                placeholder="Describe the issue"
                required
                onChange={(e) => {
                  setFormData({ ...formData, issue: e.target.value });
                }}
              />
            </div>

            <div className="form-group mt-3">
              <b>Links (URLs) to the content</b>
              <input
                type="url"
                className="mt-2 w-full p-2 border border-gray-300 rounded-md form-control"
                placeholder="Enter URLs related to the content"
                required
                onChange={(e) => {
                  setFormData({ ...formData, url: e.target.value });
                }}
              />
            </div>

            <div className="form-group mt-3">
              <b>When was this content originally shared?</b>
              <input
                type="date"
                className="mt-2 w-full p-2 border border-gray-300 rounded-md form-control"
                required
                onChange={(e) => {
                  setFormData({ ...formData, sharedDate: e.target.value });
                }}
              />
            </div>

            <div className="form-group mt-3">
              <b>Description</b>
              <br />
              <small>Please provide any details that could help us better understand the situation, including the time the content was posted and the name of the person, page, or group you are reporting.</small>
              <textarea
                className="w-full mt-2 p-2 border border-gray-300 rounded-md form-control"
                placeholder="Provide additional details"
                rows="4"
                required
                onChange={(e) => {
                  setFormData({ ...formData, description: e.target.value });
                }}
              ></textarea>
            </div>

            <div className="row mt-3">
              <div className="col-md-4">
                <div className="form-group">
                  <b>Human Verification</b>
                  <br />
                  <small>Enter {formData.captcha1} + {formData.captcha2} below</small>
                  <input
                    type="number"
                    className="w-full mt-2 p-2 border border-gray-300 rounded-md form-control"
                    placeholder=""
                    required
                    onChange={(e) => {
                      setFormData({ ...formData, captcha: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div className="col-md-8">
                <div className="form-group">
                  <b>Attachment</b>
                  <br />
                  <small>Image only</small>
                  <input
                    type="file"
                    accept="image/*"
                    className="w-full mt-2 p-2 border border-gray-300 rounded-md form-control"
                    placeholder=""
                    required
                    onChange={(e) => {
                      setFormData({ ...formData, file: e.target.value });
                    }}
                  />
                </div>
              </div>
            </div>
            {showMessage(formData)}
            {!formData.apiCall && (
              <button
                type="submit"
                className="w-100 btn btn-primary rounded-pill py-1 py-md-2 fs-5 my-3"
              >
                Submit
              </button>
            )}
          </form>

        </Modal.Body>
      </Modal>

    </>
  )
}

export default ServicePost;
