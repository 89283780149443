import React, { useContext, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import { businessContext } from '../../UseContext/BusinessContext';
import { AwsFileUrl } from '../../../../config';
import Slider from 'react-slick';


const CreateEventPopup = () => {

  const { 
    showCreateEventPopup, setShowCreateEventPopup,
    newEvent, setNewEvent, 
    createEvent,
    getSearchedHosts,
    searchedHostsList, setSearchedHostsList,
    selectedHostsList, setSelectedHostsList,
  } = useContext(businessContext);

  const fileInputRef = useRef(null);

  // handle click
  const handleDivClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    };
  };

  // handle file change
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files); // Convert FileList to Array
  
    setNewEvent((prevEvent) => {
      if (prevEvent.images.length + files.length > 5) {
        alert("You can only upload up to 5 images."); // Show popup
        return prevEvent; // Do not update state
      } else {
        return {
          ...prevEvent,
          images: [...files, ...prevEvent.images], // Append new images
        };
      }
    });
  };  

  // handle remove local file
  const handleRemoveLocalFile = (fileIndex) => {
    setNewEvent((prevEvent) => ({
      ...prevEvent,
      images: prevEvent.images.filter((_, index) => index !== fileIndex),
    }));
  };

  // handle search host change
  const handleSearchedHostChange = (e) => {
    setNewEvent(preState => ({ ...preState, host: e.target.value }));

    getSearchedHosts(e.target.value);
  };

  // handle searched host click 
  const handleSearchedHostClick = (host) => {
    // Remove the host from the searched host list
    setSearchedHostsList((prevSearched) => 
      prevSearched.filter(searchedHost => searchedHost._id !== host._id)
    );
  
    // Add the host to selected host list
    setSelectedHostsList((prevSelected) => 
      [...prevSelected, host]
    );
  };

  // handle selected host click 
  const handleSelectedHostClick = (host) => {
    // Remove the host from the selected host list
    setSelectedHostsList((prevSearched) => 
      prevSearched.filter(selectedHost => selectedHost._id !== host._id)
    );
  
    // Add the host to searched host list
    setSearchedHostsList((prevSelected) => 
      [...prevSelected, host]
    );
  };

  const settings = {
    dots: true,           
    infinite: false,
    speed: 500,           
    slidesToShow: 1,        
    slidesToScroll: 1,        
    adaptiveHeight: true,     
  };

  return (
    <>
      <Modal show={showCreateEventPopup} onHide={() => setShowCreateEventPopup(false)} keyboard={false} className="create-post" backdrop="static" centered>
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          <section>
            <h5 className='text-center mb-4 fw-semibold'>Create Event</h5>

            <div className='overflow-y-scroll ps-1 pe-2' style={{ height: '75vh' }}>
              {/* Add banner */}
              <>
                {
                  newEvent.images && newEvent.images.length > 0
                  ?
                  <div className='mb-3'>
                    {/* Add */}
                    <>
                      {
                        newEvent.images.length < 5
                        &&
                        <div className='p-1 bg-secondary-subtle rounded-circle d-inline-block mb-2 pt-0 cursor-pointer' onClick={handleDivClick}>
                          <img src="/images/business/icons/add-circle.svg" style={{ height: '20px' }} alt="add" />

                          <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} accept='image/*' multiple />
                        </div>
                      }
                    </>

                    <Slider className="job-images-slider" {...settings}>
                      {
                        newEvent.images.map((image, index) => (
                          <div className="border position-relative rounded-3" key={index}>
                            <img src={URL.createObjectURL(image)} className="rounded-3 object-fit-contain" style={{ maxHeight: '25vh', width: '100%' }} alt={`Product ${index}`} />

                            <div className='position-absolute top-0 end-0 mt-3 me-3 p-1 rounded-3 shadow-sm cursor-pointer' style={{ backgroundColor: '#4b4b4b96' }} onClick={() => handleRemoveLocalFile(index)}>
                              <img src="/images/icons/delete-white.svg" style={{ height: '20px' }} alt="delete" />
                            </div>
                          </div>
                        ))
                      }
                    </Slider>
                  </div>
                  :
                  <div className='d-flex flex-column align-items-center justify-content-center py-5 rounded-4 mb-4 cursor-pointer' style={{ border: '1px dashed gray' }} onClick={handleDivClick}>
                    <img src={`/images/business/icons/add-photo-video.svg`} className='mb-2' style={{ height: '40px' }} alt="a" />
                    <span className='fw-semibold'>Add Banner</span>
                    <small className='text-secondary'>Or drag and drop</small>

                    <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} accept='image/*' multiple />
                  </div>
                }
              </>

              <h6 className='fw-semibold mb-2'>Title</h6>
              <input type="text" className='form-control mb-4' placeholder='Enter Title...' 
                value={newEvent.title} onChange={(e) => setNewEvent((prevEvent) => ({ ...prevEvent, title: e.target.value }))}
              />

              <h6 className='fw-semibold mb-2'>Sub Title</h6>
              <input type="text" className='form-control mb-4' placeholder='Enter Sub Title...' 
                value={newEvent.subTitle} onChange={(e) => setNewEvent((prevEvent) => ({ ...prevEvent, subTitle: e.target.value }))}
              />  

              <h6 className='fw-semibold mb-2'>About</h6>
              <textarea class="form-control mb-4" placeholder="Write Something Here About Event..."
                value={newEvent.about} onChange={(e) => setNewEvent((prevEvent) => ({ ...prevEvent, about: e.target.value }))}
              ></textarea>

              <h6 className='fw-semibold mb-2'>Mode</h6>
              <select class="form-select mb-4" value={newEvent.mode} onChange={(e) => setNewEvent((prevEvent) => ({ ...prevEvent, mode: e.target.value }))} aria-label="Default select example" >
                <option value="virtual">Virtual</option>
                <option value="inPerson">In Person</option>
              </select>

              <h6 className='fw-semibold mb-2'>Address</h6>
              <textarea class="form-control mb-4" placeholder="Enter Address..."
                value={newEvent.address} onChange={(e) => setNewEvent((prevEvent) => ({ ...prevEvent, address: e.target.value }))}
              ></textarea>

              <h6 className='fw-semibold mb-2'>Mobile</h6>
              <input type="text" className='form-control mb-4' placeholder='Enter Mobile...' 
                value={newEvent.mobile} onChange={(e) => setNewEvent((prevEvent) => ({ ...prevEvent, mobile: e.target.value }))}
              />

              <h6 className='fw-semibold mb-2'>Email</h6>
              <input type="text" className='form-control mb-4' placeholder='Enter Email...' 
                value={newEvent.email} onChange={(e) => setNewEvent((prevEvent) => ({ ...prevEvent, email: e.target.value }))}
              />

              <h6 className='fw-semibold mb-2'>Website Link</h6>
              <input type="text" className='form-control mb-4' placeholder='Enter Website Link...' 
                value={newEvent.websiteLink} onChange={(e) => setNewEvent((prevEvent) => ({ ...prevEvent, websiteLink: e.target.value }))}
              />

              <h6 className='fw-semibold mb-2'>Ticket Link</h6>
              <input type="text" className='form-control mb-4' placeholder='Enter Ticket Link...' 
                value={newEvent.ticketLink} onChange={(e) => setNewEvent((prevEvent) => ({ ...prevEvent, ticketLink: e.target.value }))}
              />

              {/* Host */}
              <h6 className='fw-semibold mb-2'>Add Host</h6>
              <div className='position-relative'>
                {/* Selected Host */}
                <>
                  {
                    selectedHostsList && selectedHostsList.length > 0
                    &&
                    <div className='mb-2 py-1 d-flex flex-wrap gap-3'>
                      {
                        selectedHostsList.map((host, index) => (
                          <div className='d-inline bg-primary text-white rounded-pill ps-3 pe-2 py-1' key={index}>
                            <span className='me-2'>{host.name}</span>

                            <span className='bg-danger text-white px-2 rounded-circle cursor-pointer' onClick={() => handleSelectedHostClick(host)}>x</span>
                          </div>
                        ))
                      }
                    </div>
                  }
                </>

                <input type="text" className='form-control mb-4' value={newEvent.host} onChange={handleSearchedHostChange} placeholder='Enter Host Name...' />

                {/* Search List */}
                <>
                  {
                    searchedHostsList && searchedHostsList.length > 0
                    &&
                    <div className='position-absolute bg-white z-1 shadow pt-3 px-3 rounded-4 overflow-y-scroll cursor-pointer' style={{ maxHeight: '275px', top: '115%' }}>
                      {
                        searchedHostsList.map((host, index) => (
                          <div className='mb-3 d-flex gap-3 align-items-center' onClick={() => handleSearchedHostClick(host)} key={index}>
                            <img src={AwsFileUrl+host.imageId} className='border rounded-circle object-fit-cover' style={{ height: '40px', width: '40px' }} alt="profile" />

                            <div>
                              <h6 className='mb-0 fw-semibold'>{host.name}</h6>
                              <span>{host.username ? host.username : 'username06@'}</span>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  }
                </>
              </div>

              <div className='d-flex gap-3 align-items-center justify-content-between mb-2'>
                <h6 className='fw-semibold mb-0'>Repeat Event</h6>

                <div className="form-check form-switch m-0">
                  <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' 
                    checked={newEvent.repeatEvent === 'on'} onChange={(e) => setNewEvent((prevEvent) => ({ ...prevEvent, repeatEvent: e.target.checked ? 'on' : 'off' }))}
                  />
                </div>
              </div>

              <select class="form-select mb-4" value={newEvent.repeatEventOption} disabled={newEvent.repeatEvent === 'off'} aria-label="Default select example"
                onChange={(e) => setNewEvent((prevEvent) => ({ ...prevEvent, repeatEventOption: e.target.value }))} 
              >
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
                <option value="yearly">Yearly</option>
              </select>

              {/* Start & End Date */}
              <div className='border rounded-3 p-3 d-flex flex-column flex-sm-row gap-3 mb-4'>
                <div className='flex-grow-1'>
                  <h6 className='fw-semibold mb-2'>Start Date</h6>
                  <input type="date" className='form-control' min={new Date().toISOString().split("T")[0]}
                    value={newEvent.startDate} onChange={(e) => setNewEvent((prevEvent) => ({ ...prevEvent, startDate: e.target.value, endDate: '' }))} 
                  />
                </div>

                <div className='flex-grow-1'>
                  <h6 className='fw-semibold mb-2'>End Date</h6>
                  <input type="date" className='form-control' min={newEvent.startDate || new Date().toISOString().split("T")[0]} disabled={newEvent.startDate === ''} 
                    value={newEvent.endDate} onChange={(e) => setNewEvent((prevEvent) => ({ ...prevEvent, endDate: e.target.value }))} 
                  />
                </div>
              </div>

              {/* Start & End Time */}
              <>
                {
                  newEvent.startDate && newEvent.endDate
                  &&
                  <div className='border rounded-3 p-3 d-flex flex-column flex-sm-row gap-3 mb-4'>
                    <div className='flex-grow-1'>
                      <h6 className='fw-semibold mb-2'>Start Time</h6>
                      <input type="time" className='form-control' min={new Date().toTimeString().split(" ")[0].slice(0, 5)}
                        value={newEvent.startTime} onChange={(e) => setNewEvent((prevEvent) => ({ ...prevEvent, startTime: e.target.value, endTime: '' }))} 
                      />
                    </div>

                    <div className='flex-grow-1'>
                      <h6 className='fw-semibold mb-2'>End Time</h6>
                      <input type="time" className='form-control' min={newEvent.startTime || new Date().toTimeString().split(" ")[0].slice(0, 5)} disabled={newEvent.startTime === ''}
                        value={newEvent.endTime} onChange={(e) => setNewEvent((prevEvent) => ({ ...prevEvent, endTime: e.target.value }))} 
                      />
                    </div>
                  </div>
                }
              </>

              <div className='border rounded-3 p-3 mb-4'>
                <div className='d-flex gap-3 align-items-center justify-content-between mb-2'>
                  <h6 className='fw-semibold mb-0'>RSVP's</h6>

                  <div className="form-check form-switch m-0">
                    <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' 
                      checked={newEvent.rsvp === 'on'} onChange={(e) => setNewEvent((prevEvent) => ({ ...prevEvent, rsvp: e.target.checked ? 'on' : 'off' }))}
                    />
                  </div>
                </div>

                <div className='d-flex gap-3 align-items-center justify-content-between'>
                  <h6 className='fw-semibold mb-0'>Boost Event</h6>

                  <div className="form-check form-switch m-0">
                    <input type="checkbox" className="form-check-input" role="switch" aria-checked name='postLike' 
                      checked={newEvent.boostEvent === 'on'} onChange={(e) => setNewEvent((prevEvent) => ({ ...prevEvent, boostEvent: e.target.checked ? 'on' : 'off' }))}
                    />
                  </div>
                </div>
              </div>

              <button type="button" className='btn btn-primary rounded-pill w-100' onClick={createEvent}>Post</button>
            </div>
          </section>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreateEventPopup;