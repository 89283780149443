import React, { useContext, useRef } from 'react';
import { businessContext } from '../UseContext/BusinessContext';
import Modal from 'react-bootstrap/Modal';
import Slider from "react-slick";


const AddPageBannerPopup = () => {

  const {
    showAddPageBannerPopup, setShowAddPageBannerPopup,
    pageBanners, setPageBanners,
    createPageBanner,
  } = useContext(businessContext);

  const bannerInputRef = useRef(null);

  // handle add banner click
  const handleBannerClick = () => {
    if (bannerInputRef.current) {
      bannerInputRef.current.click();
    };
  };

  // handle banner change
  const handleBannerChange = (e) => {
    const files = Array.from(e.target.files);

    if (files.length > 0) {
      // Update state with new files
      setPageBanners((prevBanners) => [...prevBanners, ...files]);
    };
  };

  // handle remove selected banner
  const handleRemoveBanner = (indexToDelete) => {
    setPageBanners((prevBanners) => prevBanners.filter((_, index) => index !== indexToDelete));
  };

  
  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  

  return (
    <>
      <Modal show={showAddPageBannerPopup} onHide={() => setShowAddPageBannerPopup(false)} keyboard={false} className="create-post" backdrop="static" centered>
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          <section>
            <h4 className='text-center'>Add Banner</h4>

            <div className='bg-secondary-subtle p-1 rounded-circle d-inline-block my-2' onClick={handleBannerClick}>
              <img src="/images/business/icons/add-circle.svg" style={{ height: '25px', width: '25px' }} alt="add" />
            </div>

            <input type='file' ref={bannerInputRef} style={{ display: 'none' }} onChange={handleBannerChange} accept="image/*" multiple />

            <>
              {
                pageBanners && pageBanners.length > 0 
                && 
                <Slider {...sliderSettings} className='mb-4'>
                  {
                    pageBanners.map((banner, index) => (
                      <div className='position-relative' key={index}>
                        <img src={URL.createObjectURL(banner)} className="h-100 w-100 rounded-4" alt="banner" />

                        <div className='position-absolute p-1 rounded-circle top-0 end-0 mt-2 me-2' style={{ backgroundColor: '#40404078' }} onClick={() => handleRemoveBanner(index)}>
                          <img src="/images/icons/delete-white.svg" style={{ height: '20px', width: '20px' }} alt="delete" />
                        </div>
                      </div>
                    ))
                  }
                </Slider>
              }
            </>

            <button type="button" className='btn btn-primary rounded-pill w-100' disabled={pageBanners.length === 0} onClick={createPageBanner}>Add</button>
          </section>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AddPageBannerPopup
