import Modal from "react-bootstrap/Modal";
import React, { useContext, useEffect, useState } from "react";

import {
  BASE_URL_STATIC,
  BASEAPIURL,
  BASEURL,
  AwsFileUrl,
} from "../../../../../config";
import { Button, ButtonGroup } from "react-bootstrap";
import { ApiContext } from "../../../UseContext/ApiCall";
import { Link } from "react-router-dom";
import axios from "axios";

// import FollowUnfollowButton from "../../../Button/FollowUnfollowButton";

const Closefriend = () => {
  const {
    showClosefriendPopup,
    setShowCloseFriendPopup,
    loggedInUserId,
    config,
  } = useContext(ApiContext);
  const [Closefriend, setCloseFriend] = useState([]);
  const [deleteClosefriend, deletesetCloseFriend] = useState([]);
  const [listofFriend, setllistofFriend] = useState([]);
  const [listofsuggestion, setllistofsuggestion] = useState([]);
  const [listofSearchedFriend, setlistofSearchFriend] = useState([]);
  const [Active, setActive] = useState(true);
  const [msg, setmsg] = useState("");
  useEffect(() => {
    // console.log("deleteClosefriend", deleteClosefriend);
  }, [deleteClosefriend]);

  const fatchlist = async () => {
    try {
      const list = Active ? "listone" : "listtwo";
      const response = await axios.post(
        `${BASEAPIURL}/user/getallclosefriends`,
        { list: list },
        config
      );
      // console.log("list of friends", response.data.data);
      setllistofFriend(response.data.data);
    } catch (err) {
      console.log("err", err);
      setmsg(err && err.response && err.response.data && err.response.data.message);
      setllistofFriend([]);
    };
  };

  useEffect(() => {
    fatchlist();
    setlistofSearchFriend([])
    getclosesuggestedfriend();
  }, [Closefriend, Active]);

  useEffect(() => {
    fatchlist();
    setlistofSearchFriend([])
    getclosesuggestedfriend();
  }, []);

  const HandleCloseFriend = (e) => {
    console.log("e.target.value", e.target.value);
    if (e.target.checked) {
      setCloseFriend((prev) => {
        return [...prev, e.target.value];
      });
    } else {
      setCloseFriend((prev) => {
        return prev.filter((friend) => friend !== e.target.value);
      });
    }
  };

  const HandleDelete = (e) => {
    console.log("e.target.value", e.target.value);

    if (e.target.checked === false) {
      deletesetCloseFriend((prev) => {
        return [...prev, e.target.value];
      });
    } else {
      deletesetCloseFriend((prev) => {
        return prev.filter((friend) => friend !== e.target.value);
      });
    }
  };

  const HandleSubmit = async () => {
    try {
      const list = Active ? "listone" : "listtwo";
      await axios.post(
        `${BASEAPIURL}/user/addtoclosefriend`,
        { closeFriendId: Closefriend, list: list },
        config
      );
      await axios.post(
        `${BASEAPIURL}/user/deleteclosefriend`,
        { closeFriendId: deleteClosefriend, list: list },
        config
      );
      setShowCloseFriendPopup(false);
      setCloseFriend([]);
      deletesetCloseFriend([]);
    } catch (error) {
      console.log(error);
    }
  };

  const getclosesuggestedfriend = async () => {
    try {
      const list = Active ? "listone" : "listtwo";
      const response = await axios.post(
        `${BASEAPIURL}/user/getallfriends`,
        { list: list },
        config
      );
      setllistofsuggestion(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  // const HandleSearchfilte = async (e) => {
  //   try {
  //     const searchQuery = e.target.value;
  //     if (!searchQuery.trim()) {
        
  //       return setlistofSearchFriend([]); // No action if the search query is empty
  //     }
  
  //     const list = Active ? "listone" : "listtwo";
  //     const response = await axios.post(
  //       `${BASEAPIURL}/user/getallfriends`,
  //       { list: list, searchQuery: searchQuery },
  //       config
  //     );
     
  //     setlistofSearchFriend(response.data.data); // Log response for debugging
  //   } catch (error) {
  //     console.log("Error during API call:", error.response || error.message);
  //   }
  // };



  const HandleSearchfilte = async (e) => {
    try {
      const searchQuery = e.target.value;
      if (!searchQuery.trim()) {
        return setlistofSearchFriend([]); // No action if the search query is empty
      }
  
      const list = Active ? "listone" : "listtwo";
      const response = await axios.post(
        `${BASEAPIURL}/user/getallfriends`,
        { list: list, searchQuery: searchQuery },
        config
      );
          console.log("response", response)
      
      // Check if response has the expected data
      if (searchQuery.length > 3) {
        setlistofSearchFriend(response.data.data); // Log response for debugging
      } else {
        console.log("No friends found or empty response");
        setlistofSearchFriend([]); // Handle empty response case
      }
    } catch (error) {
      console.log("Error during API call:", error.response ? error.response.data : error.message);
    }
  };
  

return (
  <section>
    <Modal
      show={showClosefriendPopup}
      onHide={() => setShowCloseFriendPopup(false)}
      centered
      backdrop="static"
      keyboard={false}
      className="create-call"
    >
      <Modal.Header closeButton style={{ paddingRight: "10px" }}>
        &nbsp;
      </Modal.Header>

      <Modal.Body>
        <div className="pfp-tabs mb-3">
          <ButtonGroup className="w-100">
            <Button
              onClick={() => setActive(true)}
              className="flex-fill"
              variant={Active ? "secondary" : "outline-secondary"}
            >
              ListOne
            </Button>
            <Button
              onClick={() => setActive(false)}
              className="flex-fill"
              variant={!Active ? "secondary" : "outline-secondary"}
            >
              ListTwo
            </Button>
          </ButtonGroup>
        </div>

        <div className="pfp-search mb-3">
          <form className="pfp-search-form">
            <img
              className="pfp-search-img"
              src={`${BASE_URL_STATIC}/icon/Search-2.svg`}
              alt="search"
            />
            <label htmlFor="followingSearch">
              <input
                type="text"
                id="followingSearch"
                placeholder="Search"
                onChange={(e) => e.target.value.length > 2 && HandleSearchfilte(e)}
              />
            </label>
          </form>
            <span className="pfp-search-filter ms-2 ms-md-0">Filter</span>
          </div>

          { true ? (
            <div className="h-50" style={{ zIndex: 10000 }}>
              <div className="follow_pop">  
                {listofSearchedFriend.length > 0 ? (
                    <div className="px-2">
                      {listofSearchedFriend.map((user, index) => (
                        <div
                          className="d-flex align-items-center mb-2 mb-lg-3"
                          key={index}
                        >
                          <img
                            className="pfp-profile rounded-circle"
                            src={
                              AwsFileUrl + user.result[0].imageId ||
                              `${BASE_URL_STATIC}/dummy.png`
                            }
                            alt={user.fname}
                            onError={(e) =>
                              (e.target.src = `${BASE_URL_STATIC}/dummy.png`)
                            }
                          />

                          <Link
                            to={`${BASEURL}/profile/${user.result[0]._id}`}
                            className="ms-2 ms-sm-3 me-auto text-dark"
                            onClick={() => setShowCloseFriendPopup(false)}
                          >
                            <h6 className="pfp-name fw-bold">
                              {user.result[0].fname}
                            </h6>
                            <small className="text-secondary">
                              {user.userName || "username06@"}
                            </small>
                          </Link>
                          {user.result[0]._id !== loggedInUserId && (
                            <>
                              <label>
                                <input
                                  type="checkbox"
                                  name="agree"
                                  value={user.result[0]._id}
                                  checked={Closefriend.includes(
                                    user.result[0]._id
                                  )}
                                  onClick={(e) => HandleCloseFriend(e)}
                                />
                              </label>
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="text-center text-muted"></div>
                  )}
                {listofFriend.length > 0 ? (
                  <div className="px-2">
                    {listofFriend.map((user, index) => (
                      <div
                        className="d-flex align-items-center mb-2 mb-lg-3"
                        key={index}
                      >
                        <img
                          className="pfp-profile rounded-circle"
                          src={AwsFileUrl + user.result[0].imageId}
                          alt={user.fname}
                          onError={(e) =>
                            (e.target.src = `${BASE_URL_STATIC}/dummy.png`)
                          }
                        />

                        <Link
                          to={`${BASEURL}/profile/${user.result[0]._id}`}
                          className="ms-2 ms-sm-3 me-auto text-dark"
                          onClick={() => setShowCloseFriendPopup(false)}
                        >
                          <h6 className="pfp-name fw-bold">
                            {user.result[0].fname}
                          </h6>
                          <small className="text-secondary">
                            {user.userName || "username06@"}
                          </small>
                        </Link>
                        {user.userId !== loggedInUserId && (
                          <>
                            <label>
                              <input
                                type="checkbox"
                                name="agree"
                                value={user.result[0]._id}
                                checked={
                                  !deleteClosefriend.includes(
                                    user.result[0]._id
                                  )
                                }
                                onClick={(e) => HandleDelete(e)}
                              />
                            </label>
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="text-center text-muted">{msg}</div>
                )}

                <p> Suggested People</p>

                <div className="follow_pop">
              
                  {listofsuggestion.length > 0 ? (
                    <div className="px-2">
                      {listofsuggestion.map((user, index) => (
                        <div className="d-flex align-items-center mb-2 mb-lg-3" key={index} >
                          <img
                            className="pfp-profile rounded-circle"
                            src={
                              user.result.length > 0 && user.result[0].imageId
                              &&
                              (AwsFileUrl+user.result[0].imageId || `${BASE_URL_STATIC}/dummy.png`)
                            }
                            alt={user.fname}
                            onError={(e) =>
                              (e.target.src = `${BASE_URL_STATIC}/dummy.png`)
                            }
                          />

                          <Link
                            to={`${BASEURL}/profile/${user.result.length > 0 && user.result[0]._id}`}
                            className="ms-2 ms-sm-3 me-auto text-dark"
                            onClick={() => setShowCloseFriendPopup(false)}
                          >
                            <h6 className="pfp-name fw-bold">
                              {user.result.length > 0 && user.result[0].fname}
                            </h6>
                            <small className="text-secondary">
                              {user.userName || "username06@"}
                            </small>
                          </Link>
                          {user.result.length > 0 && user.result[0]._id !== loggedInUserId && (
                            <>
                              <label>
                                <input
                                  type="checkbox"
                                  name="agree"
                                  value={user.result[0]._id}
                                  checked={Closefriend.includes(
                                    user.result.length > 0 && user.result[0]._id
                                  )}
                                  onClick={(e) => HandleCloseFriend(e)}
                                />
                              </label>
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="text-center text-muted">{msg}</div>
                  )}
                  
                </div>
              </div>
            </div>
          ) : (
            <div className="h-50">
              <div className="follow_pop">
                {listofFriend.length > 0 ? (
                  <div className="px-2">
                    {listofFriend.map((user, index) => (
                      <div
                        className="d-flex align-items-center mb-2 mb-lg-3"
                        key={index}
                      >
                        <img
                          className="pfp-profile rounded-circle"
                          src={AwsFileUrl + user.result[0].imageId}
                          alt={user.fname}
                          onError={(e) =>
                            (e.target.src = `${BASE_URL_STATIC}/dummy.png`)
                          }
                        />

                        <Link
                          to={`${BASEURL}/profile/${user.result[0]._id}`}
                          className="ms-2 ms-sm-3 me-auto text-dark"
                          onClick={() => setShowCloseFriendPopup(false)}
                        >
                          <h6 className="pfp-name fw-bold">
                            {user.result[0].fname}
                          </h6>
                          <small className="text-secondary">
                            {user.userName || "username06@"}
                          </small>
                        </Link>
                        {user.userId !== loggedInUserId && (
                          <>
                            <label>
                              <input
                                type="checkbox"
                                name="agree"
                                value={user.result[0]._id}
                                checked={
                                  !deleteClosefriend.includes(
                                    user.result[0]._id
                                  )
                                }
                                onClick={(e) => HandleDelete(e)}
                              />
                            </label>
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="text-center text-muted">No found.</div>
                )}

                <p> Suggested People</p>

                <div className="follow_pop">
                  {listofsuggestion.length > 0 ? (
                    <div className="px-2">
                      {listofsuggestion.map((user, index) => (
                        <div
                          className="d-flex align-items-center mb-2 mb-lg-3"
                          key={user.result[0]._id}
                        >
                          <img
                            className="pfp-profile rounded-circle"
                            src={
                              AwsFileUrl + user.result[0].imageId ||
                              `${BASE_URL_STATIC}/dummy.png`
                            }
                            alt={user.fname}
                            onError={(e) =>
                              (e.target.src = `${BASE_URL_STATIC}/dummy.png`)
                            }
                          />

                          <Link
                            to={`${BASEURL}/profile/${user.result[0]._id}`}
                            className="ms-2 ms-sm-3 me-auto text-dark"
                            onClick={() => setShowCloseFriendPopup(false)}
                          >
                            <h6 className="pfp-name fw-bold">
                              {user.result[0].fname}
                            </h6>
                            <small className="text-secondary">
                              {user.userName || "username06@"}
                            </small>
                          </Link>
                          {user.result[0]._id !== loggedInUserId && (
                            <>
                              <label>
                                <input
                                  type="checkbox"
                                  name="agree"
                                  value={user.result[0]._id}
                                  checked={Closefriend.includes(
                                    user.result[0]._id
                                  )}
                                  onClick={(e) => HandleCloseFriend(e)}
                                />
                              </label>
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="text-center text-muted">{msg}</div>
                  )}
                </div>
              </div>
            </div>
          )}
          

          <button onClick={HandleSubmit} className="btn w-100 btn-primary" type="button">
            Submit
          </button>
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default Closefriend;
