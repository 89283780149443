import React, { useContext, useRef } from 'react';
import Modal from "react-bootstrap/Modal";
import { ScApiContext } from '../../useContext/softCornerContext';
import { BASE_URL_STATIC } from '../../../../config';
// import WorkLocation from '../../../Emilo/Popup/CreateNewPost/WorkLocation';
import { useLoadScript, Autocomplete } from '@react-google-maps/api';


const AddSpecialDaysPopup = () => {

  const {
    specialDay, setSpecialDay,
    specialDays,
    showAddSpecialDaysPopup, setShowAddSpecialDaysPopup,
    createSpecialDay,
  } = useContext(ScApiContext);

  // --- Google Map Plateform ---

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyAvczQH2_uIOGDXy2pRIpCUiDUnRd_OU60',
    libraries: ['places'],
  });

  const addAutocompleteRef = useRef(null);

  // Handle autocomplete load while adding
  const handleAddAutocompleteLoad = (autocomplete) => {
    addAutocompleteRef.current = autocomplete;
  };

  // Handle place changed while adding
  const handleAddPlaceChanged = () => {
    const place = addAutocompleteRef.current.getPlace();

    if (place && place.geometry) {
      setSpecialDay((prevState) => ({
        ...prevState,
        location: place.formatted_address,
        lat: place.geometry.location.lat(), 
        lng: place.geometry.location.lng(), 
      }));
    } else {
      alert('Please select a valid location from the suggestions.');
    }
  };

  const categories = [
    "First Date",
    "Anniversary",
    "Proposal",
    "Wedding Day",
    "First Vacation Together",
    "Engagement Anniversary",
    "Moving In Together",
    "First Home Purchase"
  ];

  const setAlerts = [
    { label: "1 Min", key: "1_min" },
    { label: "5 Min", key: "5_min" },
    { label: "1 Week", key: "1_week" },
    { label: "1 Month", key: "1_month" },
  ];

  // handle set alerts checkboxes change
  const handleCheckboxChange = (key) => {
    setSpecialDay((prevState) => {
      let updatedAlerts;

      if (prevState.alerts.includes(key)) {
        // Remove the alert from the array
        updatedAlerts = prevState.alerts.filter(alert => alert !== key);
      } else {
        // Add the alert to the array
        updatedAlerts = [...prevState.alerts, key];
      };
  
      return {
        ...prevState,
        alerts: updatedAlerts,  // Update the alerts array
      };
    });
  };

  // handle set alert custom
  const handleSetAlertCustom = () => {
    setSpecialDay((prevState) => ({
      ...prevState,
      setCustomAlert: prevState.setCustomAlert === 'true' ? 'false' : 'true',
    }));
  
    if (specialDay.setCustomAlert === 'true') {
      // Clear custom date and time when going back
      setSpecialDay((prevState) => ({
        ...prevState,
        customDateTime: '',
      }));
    } else {
      // Clear selected checkboxes when switching to custom alert
      setSpecialDay((prevState) => ({
        ...prevState,
        alerts: []
      }));
    };
  };

  // handle close popup
  const handleClosePopup = () => {
    setShowAddSpecialDaysPopup(false);

    if(specialDays && specialDays.length > 0) {
      setSpecialDay(specialDays[0]);
    } else {
      setSpecialDay({
        _id: '',
        title: '',
        category: '',
        categoryOther: '',
        location: '',
        lat: '',
        lng: '',
        dateTime: '',
        setCustomAlert: 'false',
        alerts: [],
        customDateTime: '',
        note: '',
      });
    }
  };

  const disabledButton = (
    specialDay.title === ''  || specialDay.location === '' || specialDay.lat === '' || specialDay.lng === '' || specialDay.note === '' 
    ||
    (
      specialDay.category === 'Other'
      ?
      specialDay.categoryOther === ''
      :
      specialDay.category === ''
    )
    ||
    ( 
      specialDay.setCustomAlert === 'true' 
      ? 
      (specialDay.customDateTime === '') 
      : 
      (specialDay.alerts.length === 0)
    )
  );

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <>
      <Modal show={showAddSpecialDaysPopup} onHide={handleClosePopup} size='md' centered backdrop="static" keyboard={false} className="create-call">
      {/* <Modal show={true} onHide={() => setShowAddSpecialDaysPopup(false)} size='md' centered backdrop="static" keyboard={false} className="create-call"> */}
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          {
            specialDay
            &&
            <section className=''>
              <h5 className='mb-3'>Special Days</h5>

              <section className='border overflow-y-scroll p-1' style={{ height: '80vh' }}>
                {/* Title */}
                <>
                  <div className='d-inline-block px-3 py-1 rounded-pill mb-2' style={{ backgroundColor: '#FF6969' }}>
                    <img src={`${BASE_URL_STATIC}/SoftCorner/sc-title.png`} className='me-1' style={{ height: '20px' }} alt="sc-title" />
                    <span className='text-white'>Title</span>
                  </div>

                  <input type="text" className='form-control rounded-pill mb-3' name='title' placeholder='Enter Title...' 
                    value={specialDay.title} onChange={(e) => setSpecialDay(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} 
                  />
                </>

                {/* Category */}
                <div className='mb-3'>
                  <div className='d-inline-block px-3 py-1 rounded-pill mb-2' style={{ backgroundColor: '#FF6969' }}>
                    <img src={`${BASE_URL_STATIC}/SoftCorner/sc-category.png`} className='me-1' style={{ height: '20px' }} alt="sc-category" />
                    <span className='text-white'>Category</span>
                  </div>

                  <select className='form-control rounded-pill' id='category' name='category' required
                    value={specialDay.category} onChange={(e) => setSpecialDay(prevState => ({ ...prevState, [e.target.name]: e.target.value, categoryOther: '' }))} 
                  >
                    <option value="" disabled>Select Category...</option>

                    {
                      categories.map((category, index) => (
                        <option key={index} value={category}>{category}</option>
                      ))
                    }

                    <option value="Other">Other</option>
                  </select>

                  {/* Other Category */}
                  <div>
                    {
                      specialDay.category === "Other" 
                      && 
                      <input type="text" className='form-control rounded-pill mt-3' name='categoryOther' placeholder='Enter Other Category...'
                        value={specialDay.categoryOther} onChange={(e) => setSpecialDay(prevState => ({ ...prevState, [e.target.name]: e.target.value }))}

                      />
                    }
                  </div>
                </div>

                {/* Location */}
                <>
                  <div className='d-inline-block px-3 py-1 rounded-pill mb-2' style={{ backgroundColor: '#FF6969' }}>
                    <img src={`${BASE_URL_STATIC}/SoftCorner/sc-location.png`} className='me-1' style={{ height: '20px' }} alt="sc-location" />
                    <span className='text-white'>Location</span>
                  </div>

                  <Autocomplete
                    className="mb-3"
                    onLoad={handleAddAutocompleteLoad}
                    onPlaceChanged={handleAddPlaceChanged}
                  >
                    <input type="search" className="form-control rounded-pill" placeholder="Search places here..." 
                      value={specialDay.location}
                      onChange={(e) => {
                        setSpecialDay((prev) => ({
                          ...prev,
                          location: e.target.value,
                          lat: '',
                          lng: '',
                        }))
                      }}
                    />
                  </Autocomplete>
                </>

                {/* Date & Time */}
                <div className='mb-3'>
                  <div className='d-inline-block px-3 py-1 rounded-pill mb-2' style={{ backgroundColor: '#FF6969' }}>
                    <img src={`${BASE_URL_STATIC}/SoftCorner/sc-DateAndTime.png`} className='me-1' style={{ height: '20px' }} alt="sc-DateAndTime" />
                    <span className='text-white'>Date & Time</span>
                  </div>

                  <div className='d-flex gap-3 flex-column flex-sm-row'>
                    {/* Date & Time */}
                    <input type="datetime-local" className='form-control rounded-pill' name='dateTime' disabled={specialDay._id !== ''}
                      const max = {new Date().toISOString().split('T').map((v, i) => i === 1 ? v.substring(0, 5) : v).join('T')}
                      value={specialDay.dateTime} onChange={(e) => setSpecialDay((prevState) => ({ ...prevState, [e.target.name]: e.target.value, customDateTime: '' }))} 
                    />
                  </div>
                </div>

                {/* Set Alerts */}
                <div className='mb-3'>
                  <div className='d-flex align-items-center justify-content-between mb-2'>
                    <div className='d-inline-block px-3 py-1 rounded-pill mb-2' style={{ backgroundColor: '#FF6969' }}>
                      <img src={`${BASE_URL_STATIC}/SoftCorner/sc-alert.png`} className='me-1' style={{ height: '20px' }} alt="sc-alert" />
                      <span className='text-white'>Set Alert</span>
                    </div>

                    <span className='d-inline-block px-3 py-1 rounded-pill mb-2 text-white cursor-pointer' style={{ backgroundColor: '#FF6969' }}
                      onClick={handleSetAlertCustom}
                    >{specialDay.setCustomAlert === 'true' ? 'Back' : 'Custom'}</span>
                  </div>

                  {/* Default & Custom */}
                  <>
                    {
                      specialDay.setCustomAlert === 'true'
                      ?
                      // Custom
                      <div className='d-flex gap-3 flex-column flex-sm-row'>
                        {/* Date */}
                        <input type="datetime-local" className='form-control rounded-pill' name='customDateTime' min={specialDay.dateTime} disabled={specialDay.dateTime === ''}
                          value={specialDay.customDateTime} onChange={(e) => setSpecialDay((prevState) => ({ ...prevState, [e.target.name]: e.target.value }))}
                        />
                      </div>
                      :
                      // Default
                      <div className="ms-3">
                        {
                          setAlerts.map(({ label, key }, index) => (
                            <div className="form-check form-check-inline float-none" key={index}>
                              <input className="form-check-input mt-1" type="checkbox" id={key}
                                checked={specialDay.alerts.includes(key)}  // Check if the alert is in the array
                                onChange={() => handleCheckboxChange(key)}  // Handle adding/removing alerts
                              />
                              <label className="form-check-label fs-6" htmlFor={key}>
                                {label}
                              </label>
                            </div>
                          ))
                        }
                      </div>
                    }
                  </>
                </div>

                {/* Notes */}
                <div className='mb-3'>
                  <div className='d-inline-block px-3 py-1 rounded-pill mb-2' style={{ backgroundColor: '#FF6969' }}>
                    <img src={`${BASE_URL_STATIC}/SoftCorner/sc-notes.png`} className='me-1' style={{ height: '20px' }} alt="sc-notes" />
                    <span className='text-white'>Notes</span>
                  </div> 

                  <textarea className='form-control' id='note' name='note' rows={5} cols={40} placeholder='Write something here...'
                    value={specialDay.note} onChange={(e) => setSpecialDay(prevState => ({ ...prevState, [e.target.name]: e.target.value }))}
                  />
                </div>

                {/* Add/Update Button */}
                <button type='button' className='btn text-white w-100 rounded-pill' style={{ backgroundColor: "#FF6969" }} onClick={createSpecialDay} 
                  disabled={disabledButton}
                >{specialDay._id === '' ? 'Add' : 'Update'}</button>
              </section>
            </section>
          }
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddSpecialDaysPopup;