import React, { useContext } from 'react'
import { BASE_URL_STATIC } from '../../../config';
import { Link } from 'react-router-dom';
import { ScApiContext } from '../useContext/softCornerContext';
// import { faPenToSquare, faTrashCan } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const SpecialDaysRightBlock = () => {

  const {
    userSoftCornerId,
    setShowAddSpecialDaysPopup,
    specialDay,
    deleteSpecialDay,
  } = useContext(ScApiContext);

  const formatDate = (dateTime) => {
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr',
      'May', 'Jun', 'Jul', 'Aug',
      'Sep', 'Oct', 'Nov', 'Dec'
    ];
  
    const inputDate = new Date(dateTime);
  
    const month = monthNames[inputDate.getMonth()];
    const day = inputDate.getDate().toString().padStart(2, '0');
    const year = inputDate.getFullYear();
  
    const formattedDateString = `${month} ${day}, ${year}`;
  
    return formattedDateString;
  };

  const formatTime = (dateTime) => {
    const inputDate = new Date(dateTime);
    const hours = inputDate.getHours();
    const minutes = inputDate.getMinutes();
  
    const period = hours >= 12 ? "PM" : "AM"; // Determine AM/PM
    const formattedHours = hours % 12 || 12; // Convert to 12-hour format
    const formattedTimeString = `${formattedHours}:${minutes.toString().padStart(2, "0")} ${period}`;
  
    return formattedTimeString;
  };

  const completedDays = (specialDay) => {
    const specialDayDate = new Date(specialDay);
  
    // Check if the specialDay is a valid date
    if (isNaN(specialDayDate.getTime())) {
      return "Invalid date"; // Return a message for invalid date
    }
  
    // Calculate the number of days since the special day was created
    const daysDifference = Math.floor((Date.now() - specialDayDate) / (1000 * 60 * 60 * 24));
  
    // If the special day is in the future, return 0
    if (daysDifference < 0) {
      return 0;
    }
  
    return daysDifference;
  };
  

  return (
    <>
      {
        specialDay && specialDay._id !== ''
        ?
        <section className='sc-specialDays-rightBlock pt-5 px-5 overflow-y-scroll scrollbar-hidden'>
          {/* Date */}
          <div className='sc-specialDays-rightBlock-div-1 mt-5'>
            <h2 className='mb-0'>{specialDay.dateTime && formatDate(specialDay.dateTime)}</h2>

            
            <div className='text-center'>
              <h5 className='mb-0'>
                {
                  specialDay.dateTime 
                  && 
                  completedDays(specialDay.dateTime) + " Days "
                }
              </h5>

              <small>Completed</small>
            </div>
          </div>

          <hr className='hr' />

          {/* Title & Category */}
          <div className='d-flex align-items-center gap-3 mb-4'>
            <div className='sc-specialDays-rightBlock-div-2-img'>
              <img src={`${BASE_URL_STATIC}/SoftCorner/sc-sd-coffee.png`} style={{ height: '30px' }} alt="sc-sd-coffee" />
            </div>

            <div className='sc-specialDays-rightBlock-div-2-head'>
              <h5 className='mb-0'>{specialDay.title}</h5>
              <span>{specialDay.category !== 'Other' ? specialDay.category : specialDay.categoryOther}</span>
            </div>
          </div>

          {/* Location */}
          <div className='border rounded-pill px-3 py-2 d-flex align-items-center gap-3 mb-3'>
            <img src={`${BASE_URL_STATIC}/SoftCorner/sc-sd-repeat.png`} alt="sc-sd-repeat" height={20} width={20} id='location' name='location' />

            <span className='me-auto flex-grow-1'>{specialDay.location}</span>

            <Link to={`https://www.google.com/maps?q=${specialDay.lat},${specialDay.lng}`} target='_blank' style={{ color: '#FF6969' }}>See map</Link>
          </div>

          {/* Date & Time */}
          <div className='d-flex align-items-center gap-3 mb-3'>
            <div className='border rounded-pill px-3 py-2 flex-grow-1'>
              <img src={`${BASE_URL_STATIC}/SoftCorner/sc-sd-repeat.png`} alt="sc-sd-repeat" height={20} width={20} />
              <span className='ms-3'>{formatDate(specialDay.dateTime)}</span>
            </div>

            <div className='border rounded-pill px-3 py-2 flex-grow-1'>
              <img src={`${BASE_URL_STATIC}/SoftCorner/sc-sd-repeat.png`} alt="sc-sd-repeat" height={20} width={20} />
              <span className='ms-3'>{formatTime(specialDay.dateTime)}</span>
            </div>
          </div>

          {/* Alerts */}
          <div className='mb-4'>
            {
              specialDay.setCustomAlert === 'true'
              ? 
              // Custom
              <>
                {
                  specialDay.customDate !== '' && specialDay.customTime !== '' 
                  &&
                  <div className='border rounded-pill px-3 py-2 d-flex align-items-center gap-3 mb-3'>
                    <img src={`${BASE_URL_STATIC}/SoftCorner/sc-sd-repeat.png`} style={{ height: '15px' }} alt="icon" />

                    <span className='flex-grow-1'>Alert</span>

                    <span style={{ color: '#FF6969' }}>{formatDate(specialDay.customDateTime)} • {formatTime(specialDay.customDateTime)}</span>
                  </div>
                }
              </>
              :
              // Default
              <>
                {
                  specialDay.alerts.length > 0 
                  && 
                  specialDay.alerts.map((alert, index) => (
                    <div key={index} className="border rounded-pill px-3 py-2 d-flex align-items-center gap-3 mb-3">
                      <img src={`${BASE_URL_STATIC}/SoftCorner/sc-sd-repeat.png`} style={{ height: '15px' }} alt="icon" />
                      <span className="flex-grow-1">Alert</span>
                      <span style={{ color: '#FF6969' }}>
                        {alert === '1_min' && 'Before 1 Minute'}
                        {alert === '5_min' && 'Before 5 Minutes'}
                        {alert === '1_week' && 'Before 1 Week'}
                        {alert === '1_month' && 'Before 1 Month'}
                      </span>
                    </div>
                  ))
                }
              </>
            }
          </div>

          {/* Notes */}
          <div className='mb-3'>
            <div className='d-inline-block px-3 py-1 rounded-pill mb-3' style={{ backgroundColor: '#FF6969' }}>
              <img src={`${BASE_URL_STATIC}/SoftCorner/sc-notes.png`} alt="" height={20} width={20} />
              <span className='text-white ms-2'>Notes</span>
            </div>

            <div className='border rounded-4 p-2'>{specialDay.note}</div>
          </div>

          {/* Update & Delete */}
          <>
            {
              specialDay.scUserId === userSoftCornerId
              &&
              <div className='d-flex gap-3 flex-wrap mt-3'>
                {/* Update */}
                <button type='button' className='btn rounded-pill w-100 text-white' style={{ backgroundColor: '#64A6F0' }}
                  onClick={() => {setShowAddSpecialDaysPopup(true)}}
                >Update</button>

                {/* Delete */}
                <button type='button' className='btn rounded-pill w-100 text-white' style={{ backgroundColor: '#FF6969' }}
                  onClick={deleteSpecialDay}
                >Delete</button>
              </div>
            }
          </>
        </section>
        :
        <section className='sc-specialDays-rightBlock pt-5 px-5 overflow-y-scroll scrollbar-hidden'>
          <div className='sc-rb-area-add mt-5'>
            <div className='sc-rb-area-add-usre'>
              <img className='sc-rb-area-add-usre-profile' src={`${BASE_URL_STATIC}/user-icon3.jpg`} alt="profile" />

              <div className='sc-rb-area-add-usre-header'>
                <h6 className='sc-rb-area-add-usre-head mb-0 fw-semibold'>Interesting Facts</h6>
                <span className='sc-rb-area-add-usre-sponserd'>Sponsored</span>
              </div>
            </div>

            <span className='sc-rb-area-add-usre-msg1'>Lorem Ipsum is simply dummy text of the printing & typesetting industry. Lorem Ipsum has been industry’s standard dummy text.</span>

            <img className='sc-rb-area-add-usre-img' src={`${BASE_URL_STATIC}/SoftCorner/rb-add.png`} alt="add" />

            <span className='sc-rb-area-add-usre-link'>www.example.com</span>
            <span className='sc-rb-area-add-usre-msg2'>Lorem Ipsum is simply dummy text.</span>

            <span className='sc-rb-area-add-usre-btn'>Visit Us</span>
          </div>
        </section>
      }
    </>
  )
}

export default SpecialDaysRightBlock