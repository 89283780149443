import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { AwsFileUrl, BASEAPIURL, getTodayDate, headerUserAPI } from '../../config';
import FooterContent from '../../Components/Emilo/Helper/Footer.js';
import './information.css';

const ReportAnything = () => {
    const issues = [
        "My account has been compromised",
        "I need to report inappropriate content on eMilo (e.g., image, group, or page)",
        "I believe this account is fraudulent",
        "Someone is impersonating me",
        "Someone is pretending to be someone I know",
        "My email address is being used for someone else's eMilo account",
        "My personal photos, or those of my child, are being used without my consent",
        "There is content on eMilo that infringes on my rights",
        "I have encountered an underage user on eMilo",
        "Someone is threatening to expose private information",
        "Harassment or bullying is occurring",
        "Other types of abuse"
    ];
    function showMessage(message) {
        if (message.status && Number(message.status) === 1) {
            return <div className='text-success mt-2'>{message.message}</div>;
        }
        return <div className='text-danger mt-2'>{message.message}</div>;
    }
    const [formData, setFormData] = useState(
        {
            description: '',
            issue: '',
            url: '',
            sharedDate: '',
            type: '',
            captcha1: Math.floor(Math.random() * 9) + 1,
            captcha2: Math.floor(Math.random() * 9) + 1,
            captcha: '',
            captchaRefresh: Math.floor(Math.random() * 9) + 1,
            apiCall: false,
        }
    );
    async function sendReportAny() {
        if (formData.captcha1 + formData.captcha2 !== Number(formData.captcha)) {
            setFormData({ ...formData, status: 0, message: 'Please Enter Correct Addion on human Verification.' });
            return false;
        }
        setFormData({ ...formData, apiCall: true });
        try {
            const headers = headerUserAPI(); // Assuming this returns headers
            const data = await axios.post(`${BASEAPIURL}/user/sendReportAny`, { forDate: getTodayDate(), ...formData, get: new Date() }, { headers: headers });
            if (data.data.status) {
                setFormData({
                    captcha1: Math.floor(Math.random() * 9) + 1,
                    captcha2: Math.floor(Math.random() * 9) + 1,
                    captchaRefresh: Math.floor(Math.random() * 9) + 1,
                    description: '',
                    issue: '',
                    url: '',
                    sharedDate: '',
                    type: '',
                    captcha: '',
                    apiCall: false,
                    ...data.data,
                });
            } else {
                setFormData({
                    ...formData,
                    ...data.data,
                    captchaRefresh: Math.floor(Math.random() * 9) + 1,
                    apiCall: false,
                });
            }
        } catch (error) {
            setFormData({ ...formData, apiCall: false, status: 0, message: "There is an problem, Please try after sometime." });
            // You could also handle displaying error feedback to the user here
        }
    }

    useEffect(() => {
        // For Anythings
        // For Anythings
    }, []);


    return (
        <>
            <div className="container mx-auto p-4">
                <div className='mx-auto text-center cursor-pointer'>
                    <a href="/">
                        <img src={`${AwsFileUrl}/images/logo.png`} style={{ maxHeight: '130px' }} alt="logo" />
                    </a>
                </div>
                <div className='row'>
                    <div className='col-md-2'></div>
                    <div className='col-md-8'>
                        <form
                            method="POST"
                            className="border border-gray-300 my-5 p-4 rounded shadow-lg bg-white"
                            onClick={() => {
                                setFormData({ ...formData, message: '' });
                            }}
                            onSubmit={(e) => {
                                e.preventDefault();
                                sendReportAny();
                            }}
                        >
                            <div className="form-group mb-3">
                                <b>Type</b>
                            </div>
                            <div className="form-group ReportAnythingType mb-4">
                                {issues.map((s, i) => (
                                    <div key={i} className="form-group d-flex mb-2">
                                        <input
                                            required
                                            checked={formData.type === s}
                                            type="radio"
                                            id={`issue-${i}`}
                                            value={s}
                                            name="type"
                                            className="mr-2"
                                            onChange={(e) => {
                                                setFormData({ ...formData, type: e.target.value });
                                            }}
                                        />
                                        <label htmlFor={`issue-${i}`} className="cursor-pointer">
                                            {s}
                                        </label>
                                    </div>
                                ))}
                            </div>
                            <div className="form-group mb-4">
                                <b>
                                    How would you best describe the issue you want to report?
                                </b>
                                <input
                                    type="text"
                                    className="mt-2 w-full p-2 border border-gray-300 rounded-md form-control"
                                    placeholder="Describe the issue"
                                    required
                                    value={formData.issue}
                                    onChange={(e) => {
                                        setFormData({ ...formData, issue: e.target.value });
                                    }}
                                />
                            </div>

                            <div className="form-group mb-4">
                                <b>Links (URLs) to the content</b>
                                <input
                                    type="url"
                                    value={formData.url}
                                    className="mt-2 w-full p-2 border border-gray-300 rounded-md form-control"
                                    placeholder="Enter URLs related to the content"
                                    onChange={(e) => {
                                        setFormData({ ...formData, url: e.target.value });
                                    }}
                                />
                            </div>

                            <div className="form-group mb-4">
                                <b>When was this content originally shared?</b>
                                <input
                                    type="date"
                                    value={formData.sharedDate}
                                    className="mt-2 w-full p-2 border border-gray-300 rounded-md form-control"
                                    onChange={(e) => {
                                        setFormData({ ...formData, sharedDate: e.target.value });
                                    }}
                                />
                            </div>

                            <div className="form-group mb-4">
                                <b>Description</b>
                                <br />
                                <small>Please provide any details that could help us better understand the situation, including the time the content was posted and the name of the person, page, or group you are reporting.</small>
                                <textarea
                                    className="w-full mt-2 p-2 border border-gray-300 rounded-md form-control"
                                    placeholder="Provide additional details"
                                    rows="4"
                                    value={formData.description}
                                    onChange={(e) => {
                                        setFormData({ ...formData, description: e.target.value });
                                    }}
                                ></textarea>
                            </div>

                            <div className="row mb-4">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <b>Human Verification</b>
                                        <br />
                                        {formData.captchaRefresh && (
                                            <small>Enter {formData.captcha1} + {formData.captcha2} below</small>
                                        )}
                                        <input
                                            type="number"
                                            className="w-full mt-2 p-2 border border-gray-300 rounded-md form-control"
                                            placeholder={`Enter ${formData.captcha1} + ${formData.captcha2}`}
                                            required
                                            value={formData.captcha}
                                            onChange={(e) => {
                                                setFormData({ ...formData, captcha: e.target.value });
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="form-group">
                                        <b>Attachment</b>
                                        <br />
                                        <small>Image only</small>
                                        <input
                                            type="file"
                                            accept="image/*"
                                            className="w-full mt-2 p-2 border border-gray-300 rounded-md form-control"
                                            onChange={(e) => {
                                                setFormData({ ...formData, file: e.target.value });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            {showMessage(formData)}
                            {!formData.apiCall ? (
                                <button
                                    type="submit"
                                    className="btn btn-primary mt-3"
                                >
                                    Submit
                                </button>
                            ) : (
                                <button
                                    type="button"
                                    disabled
                                    className="btn btn-outline-warning mt-3"
                                >
                                    Loading
                                </button>
                            )}
                        </form>
                    </div>
                </div>
            </div>
            <FooterContent />
        </>
    )
}

export default ReportAnything
