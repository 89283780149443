import React, { useEffect, useContext } from "react";
import { ScApiContext } from "../useContext/softCornerContext";
import { BASE_URL_STATIC } from "../../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";


const DatePlannerRightBlock = () => {

  const { 
    userSoftCornerId,
    getDatePlanners,
    datePlanner, setDatePlanner,
    datePlanners,
    filteredDatePlanners,
    deleteDatePlanner,
    updateDatePlannerStatus,
  } = useContext(ScApiContext);
  
  const formatDate = (dateTime) => {
    if(dateTime !== '') {
      const options = { day: "numeric", month: "short", year: "numeric" };
      const date = new Date(dateTime);
      return new Intl.DateTimeFormat("en-US", options).format(date);
    };
  };

  const formatTime = (dateTime) => {
    if(dateTime !== '') {
      const options = { hour: "numeric", minute: "numeric", hour12: true };
      const date = new Date(dateTime);
      return new Intl.DateTimeFormat("en-US", options).format(date);
    };
  };

  // handle click go back
  const handleGoBack = () => {
    setDatePlanner({
      _id: '',
      title: '',
      category: '',
      categoryOther: '',
      location: '',
      lat: '',
      lng: '',
      allDay: 'false',
      startDateTime: '',
      endDateTime: '',
      setCustomAlert: 'false',
      alerts: [],
      customDateTime: '', 
      note: '',
    });
  };

  useEffect(() => {
    getDatePlanners();
  }, []);

  return (
    <>
      <section className="sc-datePlanner-rb pt-5 pb-3 px-5">
        <>
          {
            datePlanner && datePlanner._id === ''
            &&
            <div className="mt-5 mb-3">
              <h2>Upcoming events</h2>
              <p>Don’t miss any of your scheduled events</p>
            </div>
          }
        </>

        <>
          {
            // step 1 : - show date planner
            datePlanner && datePlanner._id !== ''
            ?
            <section className="mt-5">
              {/* Back, Start & End Date */}
              <div className='d-flex align-items-center gap-3'>
                <FontAwesomeIcon className="cursor-pointer" style={{ color: '#64A6F0' }} icon={faAngleLeft} onClick={handleGoBack} />

                <h2 className="mb-0">
                  {/* agar start date end date ke equal h to sirf ek hi date show krna h otherwise dono date show krna h */}
                  {
                    datePlanner.allDay === 'true'
                    ?
                    formatDate(datePlanner.startDateTime)
                    :
                    formatDate(datePlanner.startDateTime) === formatDate(datePlanner.endDateTime)
                    ?
                    formatDate(datePlanner.startDateTime)
                    : 
                    `${formatDate(datePlanner.startDateTime)} - ${formatDate(datePlanner.endDateTime)}`
                  }
                </h2>
              </div>
    
              <hr className='hr' />
    
              {/* Title & Category */}
              <div className='d-flex align-items-center gap-3 mb-3'>
                <div className="rounded-circle p-2" style={{ border: '1px solid #FFD3D4' }}>
                  <img src={`${BASE_URL_STATIC}/SoftCorner/sc-sd-coffee.png`} className="m-1" style={{ height: '30px', width: '30px' }} alt="sc-sd-coffee" />
                </div>
    
                <div>
                  <div className="d-flex align-items-center gap-3">
                    <h4 className="mb-0">{datePlanner.title}</h4>

                    {
                      datePlanner.scUserId === userSoftCornerId
                      ?
                      // mera status dikhega
                      (
                        datePlanner.status === 'requested'
                        ?
                        <small className="badge text-white text-capitalize" style={{ backgroundColor: '#FF6969' }}>Requested</small>
                        :
                        <small className="badge text-white text-capitalize" style={{ backgroundColor: '#FF6969' }}>Accepted</small>
                      )
                      :
                      // partner ka status dikhega
                      (
                        datePlanner.status === 'requested'
                        ?
                        <small className="badge text-white text-capitalize" style={{ backgroundColor: '#64A6F0' }}>Requested</small>
                        :
                        <small className="badge text-white text-capitalize" style={{ backgroundColor: '#64A6F0' }}>Accepted</small>
                      )
                    }
                  </div>

                  <span style={{ color: '#2676E1' }}>{datePlanner.category === 'Other' ? datePlanner.categoryOther : datePlanner.category}</span>
                </div>
              </div>
    
              {/* Location */}
              <div className="border px-3 py-2 rounded-pill mb-3 d-flex align-items-center" >
                <img src={`${BASE_URL_STATIC}/SoftCorner/sc-sd-repeat.png`} alt="sc-sd-repeat" height={20} width={20} id='location' name='location' />

                <span className="ms-2 me-auto" style={{ fontSize: "18px", fontWeight: "500" }}>
                  {
                    datePlanner.location && datePlanner.location.length > 30 
                    ? 
                    datePlanner.location.substr(0, 30) + "..." 
                    : 
                    datePlanner.location
                  }
                </span>

                <Link to={`https://www.google.com/maps?q=${datePlanner.lat},${datePlanner.lng}`} target='_blank'>See map</Link>
              </div> 

              {/* Date And Time */}
              <div className='d-flex align-items-center gap-3 mb-3'>
                {/* Start End Date */}
                <div className="border rounded-pill d-flex gap-2 align-items-center px-3 py-2 flex-grow-1">
                  <img src={`${BASE_URL_STATIC}/SoftCorner/sc-sd-repeat.png`} style={{ height: '15px' }} alt="sc-sd-repeat" />

                  <span className='d-flex' style={{ color: '#2676E1' }}>
                    {/* agar start date end date ke equal h to sirf ek hi date show krna h otherwise dono date show krna h */}
                    {
                      datePlanner.allDay === 'true'
                      ?
                      formatDate(datePlanner.startDateTime)
                      :
                      formatDate(datePlanner.startDateTime) === formatDate(datePlanner.endDateTime)
                      ?
                      formatDate(datePlanner.startDateTime)
                      : 
                      `${formatDate(datePlanner.startDateTime)} - ${formatDate(datePlanner.endDateTime)}`
                    }
                  </span>
                </div>

                {/* Start End Time */}
                <div className="border rounded-pill d-flex gap-2 align-items-center px-3 py-2 flex-grow-1">
                  <img src={`${BASE_URL_STATIC}/SoftCorner/sc-sd-repeat.png`} style={{ height: '15px' }} alt="sc-sd-repeat" />

                  <span className='d-flex gap-2' style={{ color: '#2676E1' }}>
                    {
                      datePlanner.allDay === 'true'
                      ?
                      'All Day'
                      :
                      `${formatTime(datePlanner.startDateTime)} - ${formatTime(datePlanner.endDateTime)}`
                    }
                  </span>
                </div>
              </div>
              
              {/* Alert */}
              <div className='mb-3'>
                {
                  datePlanner.setCustomAlert === 'true'
                  ? 
                  // Custom
                  <>
                    {
                      datePlanner.customDate !== '' && datePlanner.customTime !== '' 
                      &&
                      <div className='border rounded-pill px-3 py-2 d-flex align-items-center gap-3 mb-3'>
                        <img src={`${BASE_URL_STATIC}/SoftCorner/sc-sd-repeat.png`} style={{ height: '15px' }} alt="icon" />
  
                        <span className='flex-grow-1'>Alert</span>
  
                        <span style={{ color: '#2676E1' }}>{formatDate(datePlanner.customDateTime)} • {formatTime(datePlanner.customDateTime)}</span>
                      </div>
                    }
                  </>
                  :
                  // Default
                  <>
                    {
                      datePlanner.alerts.length > 0 
                      && 
                      datePlanner.alerts.map((alert, index) => (
                        <div key={index} className="border rounded-pill px-3 py-2 d-flex align-items-center gap-3 mb-3">
                          <img src={`${BASE_URL_STATIC}/SoftCorner/sc-sd-repeat.png`} style={{ height: '15px' }} alt="icon" />
                          <span className="flex-grow-1">Alert</span>
                          <span style={{ color: '#2676E1' }}>
                            {alert === '1_min' && 'Before 1 Minute'}
                            {alert === '5_min' && 'Before 5 Minutes'}
                            {alert === '1_week' && 'Before 1 Week'}
                            {alert === '1_month' && 'Before 1 Month'}
                          </span>
                        </div>
                      ))
                    }
                  </>
                }
              </div>
    
              {/* Note */}
              <>
                {
                  datePlanner.note 
                  && 
                  <>
                    <div className='px-3 py-1 rounded-pill d-inline-block mb-2' style={{ backgroundColor: '#64A6F0' }}>
                      <img src={`${BASE_URL_STATIC}/SoftCorner/sc-notes.png`} style={{ height: '17px' }} alt="sc-notes" />
                      <span className="text-white ms-2">Notes</span>
                    </div>
        
                    <div className='border rounded-4 p-2'>{datePlanner.note}</div>
                  </>
                }
              </>

              {/* Accept & Delete */}
              <>
                {
                  datePlanner.scUserId === userSoftCornerId
                  ?
                  <button type='button' className='btn rounded-pill w-100 mt-3 text-white' style={{ backgroundColor: '#FF6969' }} onClick={deleteDatePlanner}>Delete</button>
                  :
                  datePlanner.status === 'requested'
                  &&
                  <button type='button' className='btn rounded-pill w-100 mt-3 text-white' style={{ backgroundColor: '#64A6F0' }} onClick={updateDatePlannerStatus}>Accept</button>
                }
              </>
            </section>
            :
            // step 2 : - show filtered date planners list
            filteredDatePlanners && filteredDatePlanners.length > 0
            ?
            filteredDatePlanners.map((value, index) => (
              <div className="sc-datePlanner-rb-plan" onClick={() => setDatePlanner(value)} key={index}>
                <span className="sc-datePlanner-rb-plan-date">
                  {/* agar start date end date ke equal h to sirf ek hi date show krna h otherwise dono date show krna h */}
                  {
                    value.allDay === 'true'
                    ?
                    formatDate(value.startDateTime)
                    :
                    formatDate(value.startDateTime) === formatDate(value.endDateTime)
                    ?
                    formatDate(value.startDateTime)
                    : 
                    `${formatDate(value.startDateTime)} - ${formatDate(value.endDateTime)}`
                  }
                </span>

                <div className="sc-datePlanner-rb-plan-d1">
                  <div className="sc-datePlanner-dates">
                    {
                      value.allDay === 'true'
                      ?
                      <span className="sc-datePlanner-rb-plan-time">All Day</span>
                      :
                      <>
                        <span className="sc-datePlanner-rb-plan-time child1" style={{ fontSize: "18px", fontWeight: "400" }}>{formatTime(value.startDateTime)}  -</span>
                        <small className="sc-datePlanner-rb-plan-time">{formatTime(value.endDateTime)}</small>
                      </>
                    }
                  </div>

                  <div className="sc-datePlanner-rb-plan-img">
                    <img src={`${BASE_URL_STATIC}/SoftCorner/sc-sd-coffee.png`} alt="coffe" height={25} width={25} />
                  </div>

                  <div className="sc-datePlanner-rb-plan-name">
                    <div className="d-flex align-items-center gap-3">
                      <h5 className="fw-semibold mb-0">{value.title}</h5>

                      {
                        value.scUserId === userSoftCornerId
                        ?
                        // isme mera status (requested/accepted) dikhega
                        <small className="badge text-white" style={{ backgroundColor: '#FF6969' }}>{value.status === 'requested' ? 'Requested' : 'Accepted'}</small>
                        :
                        // isme partner ka status (requested/accepted) dikhega
                        <small className="badge text-white" style={{ backgroundColor: '#64A6F0' }}>{value.status === 'requested' ? 'Requested' : 'Accepted'}</small>
                      }
                    </div>

                    <span>
                      {
                        value.location && value.location.length > 30 
                        ? 
                        value.location.substr(0, 30) + "..." 
                        : 
                        value.location
                      }
                    </span>
                  </div>
                </div>
              </div>
            ))
            :
            // step 3 : - show date planners list
            datePlanners && datePlanners.length > 0 
            ? 
            datePlanners.map((value, index) => (
              <div className="sc-datePlanner-rb-plan" onClick={() => setDatePlanner(value)} key={index}>
                <span className="sc-datePlanner-rb-plan-date">
                  {/* agar start date end date ke equal h to sirf ek hi date show krna h otherwise dono date show krna h */}
                  {
                    value.allDay === 'true'
                    ?
                    formatDate(value.startDateTime)
                    :
                    formatDate(value.startDateTime) === formatDate(value.endDateTime)
                    ?
                    formatDate(value.startDateTime)
                    : 
                    `${formatDate(value.startDateTime)} - ${formatDate(value.endDateTime)}`
                  }
                </span>

                <div className="sc-datePlanner-rb-plan-d1">
                  <div className="sc-datePlanner-dates">
                    {
                      value.allDay === 'true'
                      ?
                      <span className="sc-datePlanner-rb-plan-time">All Day</span>
                      :
                      <>
                        <span className="sc-datePlanner-rb-plan-time child1" style={{ fontSize: "18px", fontWeight: "400" }}>{formatTime(value.startDateTime)}  -</span>
                        <small className="sc-datePlanner-rb-plan-time">{formatTime(value.endDateTime)}</small>
                      </>
                    }
                  </div>

                  <div className="sc-datePlanner-rb-plan-img">
                    <img src={`${BASE_URL_STATIC}/SoftCorner/sc-sd-coffee.png`} alt="coffe" height={25} width={25} />
                  </div>

                  <div className="sc-datePlanner-rb-plan-name">
                    <div className="d-flex align-items-center gap-3">
                      <h5 className="fw-semibold mb-0">{value.title}</h5>

                      {
                        value.scUserId === userSoftCornerId
                        ?
                        // isme mera status (requested/accepted) dikhega
                        <small className="badge text-white" style={{ backgroundColor: '#FF6969' }}>{value.status === 'requested' ? 'Requested' : 'Accepted'}</small>
                        :
                        // isme partner ka status (requested/accepted) dikhega
                        <small className="badge text-white" style={{ backgroundColor: '#64A6F0' }}>{value.status === 'requested' ? 'Requested' : 'Accepted'}</small>
                      }
                    </div>

                    <span>
                      {
                        value.location && value.location.length > 30 
                        ? 
                        value.location.substr(0, 30) + "..." 
                        : 
                        value.location
                      }
                    </span>
                  </div>
                </div>
              </div>
            ))
            : 
            // step 4 : - show add date planner text
            <div className='noupcommingevents'>
              <img src={`${BASE_URL_STATIC}/noupcomming-event.png`} alt="no event" />

              <span>
                <span>You haven't added any event : &#41;</span>
                <span style={{color:"#d3d3d3"}}>Create an event and share a quality time with your loved ones.</span>
              </span>
            </div>
          }
        </>
      </section>
    </>
  )
}

export default DatePlannerRightBlock