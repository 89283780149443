import React, { useState, useContext, useEffect } from 'react';
import Modal from "react-bootstrap/Modal";
import { ApiContext } from '../../../UseContext/ApiCall';
import { Link } from "react-router-dom";
import FollowUnfollowButton from '../../../Button/FollowUnfollowButton';
import { BASEURL, BASE_URL_STATIC } from '../../../../../config';
import { Button, ButtonGroup } from 'react-bootstrap';


const FollowingPopup = ({ showFollowingPopup, setShowFollowingPopup, defaultTab = 'following' }) => {

  const [searchQuery, setSearchQuery] = useState('');
  const [activeTab, setActiveTab] = useState(defaultTab); // Set activeTab to the defaultTab prop
  const [followingSearchUsersList, setFollowingSearchUsersList] = useState([]);


  const { loggedInUserId, followerList = [], followingList = [], pendingList = [] } = useContext(ApiContext);

  const handleFollowingSearch = (e) => {
    setSearchQuery(e.target.value);

    const query = e.target.value.toLowerCase();
    if (activeTab === 'following') {
      setFollowingSearchUsersList(
        followingList.filter((user) => user.fname.toLowerCase().includes(query))
      );
    } else if (activeTab === 'followers') {
      setFollowingSearchUsersList(
        followerList.filter((user) => user.fname.toLowerCase().includes(query))
      );
    } else if (activeTab === 'pending') {
      setFollowingSearchUsersList(
        pendingList.filter((user) => user.fname.toLowerCase().includes(query))
      );
    }
  };

  const getTabContent = () => {
    switch (activeTab) {
      case 'following':
        return followingSearchUsersList.length > 0 ? followingSearchUsersList : followingList;
      case 'followers':
        return followingSearchUsersList.length > 0 ? followingSearchUsersList : followerList;
      case 'pending':
        return followingSearchUsersList.length > 0 ? followingSearchUsersList : pendingList;
      default:
        return [];
    }
  };

  const tabContent = getTabContent();

  useEffect(() => {
    if (showFollowingPopup) {
      setActiveTab(defaultTab); // Set activeTab to the passed defaultTab when modal opens
    }
  }, [showFollowingPopup, defaultTab]);

  return (
    <section>
      <Modal show={showFollowingPopup} onHide={() => setShowFollowingPopup(false)} centered backdrop="static" keyboard={false} className="create-call" >
        <Modal.Header closeButton style={{ paddingRight: '10px' }}>&nbsp;</Modal.Header>

        <Modal.Body>
          {/* Tabs for Followers, Following, Pending */}
          <div className="pfp-tabs mb-3">
            <ButtonGroup className="w-100">
              {['followers', 'following', 'pending'].map((tab) => (
                <Button key={tab} variant={activeTab === tab ? 'primary' : 'outline-secondary'} className="flex-fill"
                  onClick={() => {
                    setActiveTab(tab);
                    setFollowingSearchUsersList([]); // Clear search results when switching tabs
                    setSearchQuery(''); // Reset search query
                  }}
                >
                  {tab.charAt(0).toUpperCase() + tab.slice(1)}
                </Button>
              ))}
            </ButtonGroup>
          </div>
          <div className="text-center text-muted">
            {activeTab === 'pending' &&
              ['approve all', 'reject all'].map((action, index) => (
                <Button key={index} className={`mb-2 custom-button ${index !== 0 ? 'ms-2' : ''}`}
                  variant={action === 'approve all' ?  'primary' : 'outline-secondary'} // Green for "Approve All", Red for "Reject All"
                  style={{
                    padding: '5px 10px',
                    borderRadius: '8px',
                    fontSize: '13px',
                    fontWeight: 'bold',
                  }}
                >
                  {action.charAt(0).toUpperCase() + action.slice(1)}
                </Button>
              ))}
          </div>

          {/* Search Bar Below Tabs */}
          <div className="pfp-search mb-3">
            <form className="pfp-search-form">
              <img className="pfp-search-img" src={`${BASE_URL_STATIC}/icon/Search-2.svg`} alt="search" />
              <label htmlFor="followingSearch">
                <input type="text" id="followingSearch" placeholder="Search" value={searchQuery} onChange={handleFollowingSearch} />
              </label>
            </form>
            <span className="pfp-search-filter ms-2 ms-md-0">Filter</span>
          </div>

          <div className="follow_pop">
            {
              tabContent.length > 0 
              ? 
              <div className="px-2">
                {
                  tabContent.map((user, index) => (
                    <div className="d-flex align-items-center mb-2 mb-lg-3" key={user.userId || index}>
                      <img className="pfp-profile rounded-circle" src={user.imageUrl || `${BASE_URL_STATIC}/dummy.png`} alt={user.fname} onError={(e) => (e.target.src = `${BASE_URL_STATIC}/dummy.png`)} />

                      <Link to={`${BASEURL}/profile/${user.userId}`} className="ms-2 ms-sm-3 me-auto text-dark" onClick={() => setShowFollowingPopup(false)} >
                        <h6 className="pfp-name fw-bold mb-0">{user.fname}</h6>
                        <small className="text-secondary">{user.userName || 'username06@ asdfasdf'}</small>
                      </Link>

                      {
                        user.userId !== loggedInUserId 
                        && 
                        <FollowUnfollowButton otherUserId={user.userId} user={user} />
                      }
                    </div>
                  ))
                }
              </div>
              : 
              <div className="text-center text-muted">No {activeTab} found.</div>
            }
          </div>
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default FollowingPopup;