import React, { useContext, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import { ScApiContext } from "../../useContext/softCornerContext";
import { BASE_URL_STATIC } from "../../../../config";
import { useLoadScript, Autocomplete } from '@react-google-maps/api';


const AddDatePlannerPopup = () => {

  const { 
    showAddPlanPopup, setShowAddPlanPopup,
    datePlanner, setDatePlanner,
    createDatePlanner,
  } = useContext(ScApiContext);


  // --- Google Map Plateform ---

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyAvczQH2_uIOGDXy2pRIpCUiDUnRd_OU60',
    libraries: ['places'],
  });

  const addAutocompleteRef = useRef(null);

  // Handle autocomplete load while adding
  const handleAddAutocompleteLoad = (autocomplete) => {
    addAutocompleteRef.current = autocomplete;
  };

  // Handle place changed while adding
  const handleAddPlaceChanged = () => {
    const place = addAutocompleteRef.current.getPlace();

    if (place && place.geometry) {
      setDatePlanner((prevState) => ({
        ...prevState,
        location: place.formatted_address,
        lat: place.geometry.location.lat(), 
        lng: place.geometry.location.lng(), 
      }));
    } else {
      alert('Please select a valid location from the suggestions.');
    }
  };

  const categories = [
    "Coffee Date",
    "Romantic Dinner",
    "Picnic in the Park",
    "Movie Night",
    "Stargazing",
    "Weekend Getaway",
    "Cooking Together",
    "Couples Spa Day"
  ];

  const setAlerts = [
    { label: "1 Min", key: "1_min" },
    { label: "5 Min", key: "5_min" },
    { label: "1 Week", key: "1_week" },
    { label: "1 Month", key: "1_month" },
  ];

  // handle set alerts checkboxes change
  const handleCheckboxChange = (key) => {
    setDatePlanner((prevState) => {
      let updatedAlerts;

      if (prevState.alerts.includes(key)) {
        // Remove the alert from the array
        updatedAlerts = prevState.alerts.filter(alert => alert !== key);
      } else {
        // Add the alert to the array
        updatedAlerts = [...prevState.alerts, key];
      };
  
      return {
        ...prevState,
        alerts: updatedAlerts,  // Update the alerts array
      };
    });
  };

  // handle set alert custom
  const handleSetAlertCustom = () => {
    setDatePlanner((prevState) => ({
      ...prevState,
      setCustomAlert: prevState.setCustomAlert === 'true' ? 'false' : 'true',
    }));
  
    if (datePlanner.setCustomAlert === 'true') {
      // Clear custom date and time when going back
      setDatePlanner((prevState) => ({
        ...prevState,
        customDateTime: '',
      }));
    } else {
      // Clear selected checkboxes when switching to custom alert
      setDatePlanner((prevState) => ({
        ...prevState,
        alerts: []
      }));
    };
  };

  // handle disabled button
  const disabledButton = (
    datePlanner.title === '' || datePlanner.location === '' || datePlanner.lat === '' || datePlanner.lng === '' || datePlanner.note === ''
    ||
    (
      datePlanner.category === 'Other'
      ?
      datePlanner.categoryOther === ''
      :
      datePlanner.category === ''
    )
    ||
    (
      datePlanner.allDay === 'true'
      ?
      (datePlanner.startDateTime === '')
      :
      (datePlanner.startDateTime === '' || datePlanner.endDateTime === '')
    )
    ||
    (
      datePlanner.setCustomAlert === 'true'
      ?
      (datePlanner.customDateTime === '')
      :
      (datePlanner.alerts.length === 0)
    )
  );


  if (!isLoaded) return <div>Loading...</div>;


  return (
    <>
      <Modal show={showAddPlanPopup} onHide={() => setShowAddPlanPopup(false)} size="md" centered backdrop="static" keyboard={false} className="create-call" >
      {/* <Modal show={true} onHide={() => setShowAddPlanPopup(false)} size="md" centered backdrop="static" keyboard={false} className="create-call" > */}
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          <section className="newPlanPopup">
            <h5 className="mb-3">New Plan</h5>

            {/* Title */}
            <>
              <div className='d-inline-block px-3 py-1 rounded-pill mb-2' style={{ backgroundColor: '#FF6969' }}>
                <img src={`${BASE_URL_STATIC}/SoftCorner/sc-title.png`} className='me-2' style={{ height: '20px' }} alt="sc-title" />
                <span className='text-white'>Title</span>
              </div>

              <input type="text" className='form-control rounded-pill mb-3' name="title" placeholder="Enter Title..." 
                value={datePlanner.title} onChange={(e) => setDatePlanner(prevState => ({...prevState, [e.target.name]: e.target.value}))}
              />
            </>

            {/* Category */}
            <div className='mb-3'>
              <div className='d-inline-block px-3 py-1 rounded-pill mb-2' style={{ backgroundColor: '#FF6969' }}>
                <img src={`${BASE_URL_STATIC}/SoftCorner/sc-category.png`} className='me-2' style={{ height: '20px' }} alt="sc-category" />
                <span className='text-white'>Category</span>
              </div>

              <select className='form-control rounded-pill' id='category' name='category' required
                value={datePlanner.category} onChange={(e) => setDatePlanner(prevState => ({ ...prevState, [e.target.name]: e.target.value, categoryOther: '' }))} 
              >
                <option value="" disabled>Select Category...</option>

                {
                  categories.map((category, index) => (
                    <option key={index} value={category}>{category}</option>
                  ))
                }

                <option value="Other">Other</option>
              </select>

              {/* Other Category */}
              <div>
                {
                  datePlanner.category === "Other" 
                  && 
                  <input type="text" className='form-control rounded-pill mt-3' name='categoryOther' placeholder='Enter Other Category...'
                    value={datePlanner.categoryOther} onChange={(e) => setDatePlanner(prevState => ({ ...prevState, [e.target.name]: e.target.value }))}

                  />
                }
              </div>
            </div>

            {/* Location */}
            <>
              <div className='d-inline-block px-3 py-1 rounded-pill mb-2' style={{ backgroundColor: '#FF6969' }}>
                <img src={`${BASE_URL_STATIC}/SoftCorner/sc-location.png`} className='me-2' style={{ height: '20px' }} alt="sc-location" />
                <span className='text-white'>Location</span>
              </div>

              <Autocomplete
                className="mb-3"
                onLoad={handleAddAutocompleteLoad}
                onPlaceChanged={handleAddPlaceChanged}
              >
                <input type="search" className="form-control rounded-pill" placeholder="Search places here..." 
                  value={datePlanner.location}
                  onChange={(e) => {
                    setDatePlanner((prev) => ({
                      ...prev,
                      location: e.target.value,
                      lat: '',
                      lng: '',
                    }))
                  }}
                />
              </Autocomplete>
            </>

            {/* Date & Time */}
            <div className="mb-3">
              <div className="d-flex align-items-center justify-content-between mb-2">
                <div className='d-inline-block px-3 py-1 rounded-pill mb-2' style={{ backgroundColor: '#FF6969' }}>
                  <img src={`${BASE_URL_STATIC}/SoftCorner/sc-DateAndTime.png`} className='me-2' style={{ height: '20px' }} alt="sc-title" />
                  <span className='text-white'>Date & Time</span>
                </div>

                <div className="form-check">
                  <input type="checkbox" className="form-check-input" name='allDay' id="allDay" checked={datePlanner.allDay === 'true'}
                    onChange={(e) => setDatePlanner(prevState => ({ 
                      ...prevState, 
                      [e.target.name]: e.target.checked ? 'true' : 'false',  
                      endDateTime: e.target.checked ? '' : '',
                    }))}
                  />
                  <label className="form-check-label" htmlFor="allDay">All Day</label>
                </div>
              </div>

              {/* Start */}
              <>
                <h5 className="mb-1 text-start">Start</h5>
                <input type="datetime-local" className="form-control rounded-pill" name="startDateTime" min={new Date().toISOString().slice(0, 16)}
                  value={datePlanner.startDateTime} 
                  onChange={(e) => setDatePlanner(prevState => ({
                    ...prevState, 
                    [e.target.name]: e.target.value, 
                    endDateTime: '', 
                    customDateTime: ''
                  }))}
                />
              </>

              {/* End */}
              <>
                {
                  datePlanner.startDateTime !== '' && datePlanner.allDay === 'false' 
                  && 
                  <>
                    <h5 className="mb-1 mt-3 text-start">End</h5>
                    <input type="datetime-local" className="form-control rounded-pill" name="endDateTime" min={datePlanner.startDateTime} disabled={datePlanner.startDateTime === ''}
                      value={datePlanner.endDateTime} onChange={(e) => setDatePlanner(prevState => ({...prevState, [e.target.name]: e.target.value}))}
                      />
                  </>
                }
              </>
            </div>

            {/* Set Alert */}
            <div className='mb-3'>
              <div className='d-flex align-items-center justify-content-between mb-2'>
                <div className='d-inline-block px-3 py-1 rounded-pill mb-2' style={{ backgroundColor: '#FF6969' }}>
                  <img src={`${BASE_URL_STATIC}/SoftCorner/sc-alert.png`} className='me-2' style={{ height: '20px' }} alt="sc-alert" />
                  <span className='text-white'>Set Alert</span>
                </div>

                <span className='d-inline-block px-3 py-1 rounded-pill mb-2 text-white cursor-pointer' style={{ backgroundColor: '#FF6969' }}
                  onClick={handleSetAlertCustom}
                >{datePlanner.setCustomAlert === 'true' ? 'Back' : 'Custom'}</span>
              </div>

              {/* Default & Custom */}
              <>
                {
                  datePlanner.setCustomAlert === 'true'
                  ?
                  // Custom
                  <div className='d-flex gap-3 flex-column flex-sm-row'>
                    <input type="datetime-local" className='form-control rounded-pill' name='customDateTime' 
                      min={new Date().toISOString().slice(0, 16)} max={datePlanner.startDateTime} 
                      disabled={(datePlanner.allDay === 'true') ? (datePlanner.startDateTime === '') : (datePlanner.startDateTime === '' || datePlanner.endDateTime === '')}
                      value={datePlanner.customDateTime} onChange={(e) => setDatePlanner((prevState) => ({ ...prevState, [e.target.name]: e.target.value }))}
                    />
                  </div>
                  :
                  // Default
                  <div className="ms-3">
                    {
                      setAlerts.map(({ label, key }, index) => (
                        <div className="form-check form-check-inline float-none" key={index}>
                          <input className="form-check-input mt-1" type="checkbox" id={key}
                            checked={datePlanner.alerts.includes(key)}
                            onChange={() => handleCheckboxChange(key)} 
                          />
                          <label className="form-check-label fs-6" htmlFor={key}>{label}</label>
                        </div>
                      ))
                    }
                  </div>
                }
              </>
            </div>

            {/* Notes */}
            <div className='mb-3'>
              <div className='d-inline-block px-3 py-1 rounded-pill mb-2' style={{ backgroundColor: '#FF6969' }}>
                <img src={`${BASE_URL_STATIC}/SoftCorner/sc-notes.png`} className='me-2' style={{ height: '20px' }} alt="sc-notes" />
                <span className='text-white'>Notes</span>
              </div> 

              <textarea className='form-control' id='note' name='note' rows={5} cols={40} placeholder='Write something here...'
                value={datePlanner.note} onChange={(e) => setDatePlanner(prevState => ({ ...prevState, [e.target.name]: e.target.value }))}
              />
            </div>

            {/* Add/Update Button */}
            <button type='button' className='btn text-white w-100 rounded-pill' style={{ backgroundColor: "#FF6969" }} onClick={createDatePlanner} 
              disabled={disabledButton}
            >{datePlanner._id === '' ? 'Add' : 'Update'}</button>
          </section>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddDatePlannerPopup;