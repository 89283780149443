import React, { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import { AwsFileUrl } from '../../../../../config';
import Slider from 'react-slick';
import { businessContext } from '../../../UseContext/BusinessContext';



const ViewMilestonePopup = () => {

  const {
    isItMyPage,
    showViewMilestonePopup, setShowViewMilestonePopup,
    milestone, setMilestone,
    deleteMilestone,
    setMilestoneImages,
  } = useContext(businessContext);

  // handle popup close
  const handlePopupClose = () => {
    setShowViewMilestonePopup(false);

    setMilestoneImages([]);

    setMilestone({
      images: [],
      title: '',
      date: '',
      description: '',
    });
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1, 
    slidesToScroll: 1, 
    arrows: false, 
  };


  return (
    <>
      <Modal show={showViewMilestonePopup} onHide={handlePopupClose} keyboard={false} className="create-post" backdrop="static" centered>
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          <h4 className='text-center mb-4'>Milestone</h4>

          {/* Images */}
          <div className='mb-3'>
            {
              milestone && milestone.images && milestone.images.length > 0 
              && 
              <Slider className="milestone-images-slider" {...settings}>
                {/* Live Images */}
                {
                  milestone.images.map((value, index) => (
                    <div className='milestone-view-image border rounded-3 vh-25' key={index}>
                      <img src={`${AwsFileUrl}${value.image}`} className='rounded-3 object-fit-contain h-100 w-100' alt={`Product ${index}`} />
                    </div>
                  ))
                }
              </Slider>
            }
          </div>

          <div className="mt-2">
            <h6 className="fw-semibold d-inline me-2 me-sm-3">{milestone.title}</h6>
            <span className="badge bg-primary">{milestone.date}</span>
          </div>

          <span className="text-secondary my-3 overflow-y-scroll" style={{ maxHeight: '25vh' }}>{milestone.description}</span>

          {/* Delete */}
          <>
            {
              isItMyPage
              &&
              <div className='text-center'>
                <button type="button" className='btn btn-danger w-50 rounded-pill' onClick={deleteMilestone}>Delete</button>
              </div>
            }
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewMilestonePopup;