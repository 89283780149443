import React, { useContext, useState, useMemo } from 'react';
import { ScApiContext } from '../../useContext/softCornerContext';
import { AwsFileUrl, BASE_URL_STATIC, BASEAPIURL, BASEURL } from '../../../../config';
import AddRemoveBtn from '../../AddRemoveButton/AddRemoveBtn.jsx';
import { Link } from 'react-router-dom';
import { faAngleLeft, faAngleRight, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { ApiContext } from '../../../Emilo/UseContext/ApiCall.js';
import Slider from "react-slick";



const RightBlock = ({ setdisplayCB }) => {

  const { 
    rightBlockUser, currentCrush, softCornerUsers, setSoftCornerUsers, slidertype, setRightBlockUser, 
    profileIndex, setProfileIndex, scConfig, disableNext 
  } = useContext(ScApiContext);

  const { config } = useContext(ApiContext)

  const [scCounter, setScCounter] = useState(2)

  if (rightBlockUser !== undefined) {
    setdisplayCB('right');
  };

  let user = null;

  const handleFwd = () => {
    addProfileCrush()
    if (slidertype === "softcorner") {
      user = softCornerUsers
    }

    setProfileIndex(prevIndex => {
      const newIndex = (prevIndex + 1) % user.length;
      setRightBlockUser(user[newIndex]);
      return newIndex;
    });
  };

  const handleBwd = () => {
    addProfileCrush()
    if (slidertype === "softcorner") {
      user = softCornerUsers
    }
    setProfileIndex(prevIndex => {
      const newIndex = (prevIndex - 1 + user.length) % user.length;
      setRightBlockUser(user[newIndex]);
      return newIndex;
    });
  };

  const getSoftCornerUsers = async (page) => {
    await axios.post(`${BASEAPIURL}/softcorner/scuserlist?page=${page}&limit=10`, {}, scConfig)
      .then(res => {
        setSoftCornerUsers(res.data.message);
      })
      .catch(err => {
        console.log(err);
      });
  };

  useMemo(() => {
    if (softCornerUsers.length === 0) {
      return;
    } else {
      if (slidertype === 'softcorner' && profileIndex > (softCornerUsers.length - 1)) {
        getSoftCornerUsers(scCounter);
        setScCounter(prevCounter => prevCounter + 1)
      }
    }
  }, [profileIndex, slidertype])

  const addProfileCrush = async () => {
    // console.log(otherUserId);
    await axios.post(`${BASEAPIURL}/softcorner/createprofileviewsc`, { type: 'view', viewUserId: currentCrush }, config)
      .then(res => {
        return null;
      })
      .catch(err => {
        console.log(err);
      });
  };

  const dummyGifts = [
    {
      img: 'https://demo3.sjainventures.com/e-milo/images/SoftCorner/sc-pog-img-1.png',
      name: 'Roses And Orchids Batch',
      price: 1749
    },
    {
      img: 'https://demo3.sjainventures.com/e-milo/images/SoftCorner/sc-pog-img-2.png',
      name: 'Scarlet Rose Black B..',
      price: 699
    },
    {
      img: 'https://demo3.sjainventures.com/e-milo/images/SoftCorner/sc-pog-img-4.png',
      name: 'Rotating Love Couple',
      price: 1500
    },
    {
      img: 'https://demo3.sjainventures.com/e-milo/images/SoftCorner/sc-pog-img-1.png',
      name: 'Roses And Orchids Batch',
      price: 1749
    },
    {
      img: 'https://demo3.sjainventures.com/e-milo/images/SoftCorner/sc-pog-img-2.png',
      name: 'Scarlet Rose Black B..',
      price: 699
    },
    {
      img: 'https://demo3.sjainventures.com/e-milo/images/SoftCorner/sc-pog-img-4.png',
      name: 'Rotating Love Couple',
      price: 1500
    },
    {
      img: 'https://demo3.sjainventures.com/e-milo/images/SoftCorner/sc-pog-img-1.png',
      name: 'Roses And Orchids Batch',
      price: 1749
    },
    {
      img: 'https://demo3.sjainventures.com/e-milo/images/SoftCorner/sc-pog-img-2.png',
      name: 'Scarlet Rose Black B..',
      price: 699
    },
    {
      img: 'https://demo3.sjainventures.com/e-milo/images/SoftCorner/sc-pog-img-4.png',
      name: 'Rotating Love Couple',
      price: 1500
    },
    {
      img: 'https://demo3.sjainventures.com/e-milo/images/SoftCorner/sc-pog-img-1.png',
      name: 'Roses And Orchids Batch',
      price: 1749
    },
    {
      img: 'https://demo3.sjainventures.com/e-milo/images/SoftCorner/sc-pog-img-2.png',
      name: 'Scarlet Rose Black B..',
      price: 699
    },
    {
      img: 'https://demo3.sjainventures.com/e-milo/images/SoftCorner/sc-pog-img-4.png',
      name: 'Rotating Love Couple',
      price: 1500
    },
  ];

  const giftSettings = {
    className: 'giftSettings',
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  };

  console.log("rightBlockUser", rightBlockUser);

  return (
    <>
      <section className='vh-100 py-5 pb-lg-0 overflow-y-scroll scrollbar-hidden shadow'>
        {/* User */}
        {
          rightBlockUser
          ?
          <>
            <div className='mx-auto rounded-4 shadow pb-2' style={{ width: '80%' }}>
              <div className='position-relative rounded-4 mt-5'>
                {/* Profile */}
                <div className='rounded-4'>
                  <img src={`${AwsFileUrl + (rightBlockUser.profileImg.scProfileImg || rightBlockUser.profileImg.imageId)}`} className='rounded-4 object-fit-cover w-100' style={{ height: '200px' }} alt="user" onError={(e) => e.target.src = `${BASE_URL_STATIC}/profile-img1.jpg`} />
                </div>

                {/* Cut */}
                <div className='position-absolute end-0 top-0 bg-secondary-subtle rounded-circle shadow px-1 me-2 me-xl-3 mt-2 mt-xl-3 cursor-pointer' onClick={() => { setRightBlockUser(); setdisplayCB('center') }}>
                  <img src={`/images/icons/cut.svg`} style={{ width: '13px', marginBottom: '3px' }} alt="cut" />
                </div>

                {/* Softcorner */}
                <div className='position-absolute top-100 translate-middle p-2 bg-white rounded-circle shadow-sm' style={{ left: '50%' }}>
                  <img src={`${BASE_URL_STATIC}/softcornerLogo.png`} className='' style={{ width: '27px' }} alt="softcorner-logo" />
                </div>
              </div>

              <h5 className='mt-5 mx-auto text-center fw-semibold' style={{ width: '90%' }}>{rightBlockUser.fname}</h5>

              <p className='mx-auto text-center' style={{ width: '90%' }}>{rightBlockUser.age}</p>

              <p className='mx-auto text-center' style={{ width: '90%' }}>{rightBlockUser.address}</p>

              <Link to={`${BASEURL}/profile/${rightBlockUser.emiloId}`} className='text-primary d-block text-center mt-2 mb-3 cursor-pointer' style={{ fontSize: '13px' }}>Know More</Link>

              {/* School */}
              <>
                {
                  rightBlockUser.school !== undefined
                  &&
                  <div className='container'>
                    <div className='sc_card_details mb-1'>
                      <img src="/images/icons/school.svg" alt="" />
                      <p>School</p>
                    </div>

                    <p className='container' style={{ fontSize: "14px" }} > {rightBlockUser.school}</p>
                  </div>
                }
              </>

              {/* Collage */}
              <>
                {
                  rightBlockUser.college !== undefined
                  &&
                  <div className='container mt-3'>
                    <div className='sc_card_details mb-1'>
                      <img src="/images/icons/college.svg" alt="" />
                      <span>College</span>
                    </div>

                    <p className='container' style={{ fontSize: "14px" }} > {rightBlockUser.college}</p>
                  </div>
                }
              </>

              {/* Work */}
              <>
                {
                  rightBlockUser.work !== undefined
                  &&
                  <div className='container mt-3'>
                    <div className='sc_card_details mb-1'>
                      <img src="/images/icons/work.svg" alt="" />
                      <span>Work</span>
                    </div>
                    <p className='container' style={{ fontSize: "14px" }} > {rightBlockUser.work}</p>
                  </div>
                }
              </>

              {/* Button */}
              <>
                {
                  (rightBlockUser.scUserId || rightBlockUser.scProfileId) 
                  ?
                  <div className='text-center pb-3'>
                    <AddRemoveBtn partnerUserId={rightBlockUser.emiloId || rightBlockUser.userId} />
                  </div>
                  :
                  <div className='container'>
                    <div className='p-2  my-2'>
                      <p className='d-flex gap-2 fs-14 align-items-center'>
                        <FontAwesomeIcon icon={faTriangleExclamation} />
                        <span className='fw-bold '>Note</span>
                      </p>
                      <span className='fs-12 text-justify'> Respective user is <b>UNREGISTERED</b>  in Soft Corner, once they get registered you will be able to add them.</span>
                    </div>
                  </div>
                }
              </>
            </div>

            {/* Previous And Next */}
            <>
              {
                (!disableNext)
                &&
                <div className='sc-rb-lgt pb-4 pb-lg-0'>
                  <span className='sc-rb-lt' onClick={handleBwd}>
                    <FontAwesomeIcon icon={faAngleLeft} />
                  </span>
                  <span className='sc-rb-gt' onClick={handleFwd}>
                    <FontAwesomeIcon icon={faAngleRight} />
                  </span>
                </div>
              }
            </>
          </>
          :
          <div className='sc-rb-area pt-4'>
            {/* Sponsored */}
            <div>
              <h6 className="trending_head my-2">Sponsored</h6>

              <div className='sc-rb-area-add'>
                <div className='sc-rb-area-add-usre'>
                  <img className='sc-rb-area-add-usre-profile' src={`${BASE_URL_STATIC}/user-icon3.jpg`} alt="profile" />

                  <div className='sc-rb-area-add-usre-header'>
                    <h6 className='sc-rb-area-add-usre-head'>Interesting Facts</h6>
                    <span className='sc-rb-area-add-usre-sponserd'>Sponsored</span>
                  </div>
                </div>

                <span className='sc-rb-area-add-usre-msg1'>Lorem Ipsum is simply dummy text of the printing & typesetting industry. Lorem Ipsum has been industry’s standard dummy text.</span>

                <img className='sc-rb-area-add-usre-img' src={`${BASE_URL_STATIC}/SoftCorner/rb-add.png`} alt="add" />

                <span className='sc-rb-area-add-usre-link'>www.example.com</span>
                <span className='sc-rb-area-add-usre-msg2'>Lorem Ipsum is simply dummy text.</span>

                <span className='sc-rb-area-add-usre-btn'>Visit Us</span>
              </div>
            </div>

            {/* Gifts */}
            <div>
              <h6 className="trending_head my-2 flex">
                <span>Gift's</span>
                <Link to={`/SoftCorner/ProposeOption-Gifts`} className='text-primary cursor-primary'>See all</Link>
              </h6>

              <div className="slider-container mx-2 bg-white                                  ">
                <Slider {...giftSettings}>
                  {
                    dummyGifts.map((gift, index) => (
                      <Link className='pe-1 pe-xxl-2' to={`/SoftCorner/ProposeOption-Gifts/item`} key={index}>
                        <div className='bg-secondary-subtle rounded-4 px-1 py-2'>
                          <img src={gift.img} className='w-100 object-fit-contain' style={{ height: '115px' }} alt="sc-pog-img-1" />

                          <p className='mx-auto text-nowrap overflow-hidden text-truncate my-2 fw-bold' style={{ width: '90%' }}>{gift.name}</p>

                          <div className="d-flex mx-auto" style={{ width: '90%' }}>
                            <p className="me-auto">₹ {gift.price}</p>

                            <div className="d-flex align-items-center bg-primary px-2 rounded-4">
                              <p className="me-2">5</p>
                              <img src="https://demo3.sjainventures.com/e-milo/images/star.png" style={{ width: '13px' }} alt="star" />
                            </div>
                          </div>
                        </div>
                      </Link>
                    ))
                  }
                </Slider>
              </div>
            </div>
          </div>
        }
      </section>
    </>
  )
}

export default RightBlock;