import React, { useEffect } from 'react';
import { AwsFileUrl } from '../../config';
import FooterContent from '../../Components/Emilo/Helper/Footer.js';
import './information.css';

const TermsAndConditions = () => {
    useEffect(() => {
        // For Initialization or Data Fetching if required
    }, []);

    return (
        <>
            <div className="container mx-auto p-4">
                <div className='mx-auto text-center cursor-pointer'>
                    <a href="/">
                        <img src={`${AwsFileUrl}/images/logo.png`} style={{ maxHeight: '130px' }} alt="logo" />
                    </a>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="border border-gray-300 my-5 p-5 rounded shadow-lg bg-white">
                            <section className="docstaBlock pb-0" style={{ textAlign: 'justify' }}>
                                <div className="container p-0">
                                    <div className="row align-items-center">
                                        <div className="col-lg-12">
                                            <div className="">
                                                <h1 className="mb-3">Terms and Conditions</h1>
                                                <p className="mb-2">Effective Date: 2024-07-01</p>
                                                <p className="mb-4">
                                                    Welcome to Emilo! These terms and conditions outline the rules and regulations
                                                    for the use of our platform. By accessing Emilo, you accept these terms in full.
                                                    If you do not agree to all the terms and conditions stated here, please do not use
                                                    our platform.
                                                </p>

                                                <h4 className="mb-3">1. Use of the Platform</h4>
                                                <ul className="mb-4">
                                                    <li>Users must be at least 13 years old to access and use Emilo.</li>
                                                    <li>All information provided during registration must be accurate and up to date.</li>
                                                    <li>You are solely responsible for maintaining the confidentiality of your account credentials.</li>
                                                </ul>

                                                <h4 className="mb-3">2. Prohibited Activities</h4>
                                                <p className="mb-2">Users agree not to:</p>
                                                <ul className="mb-4">
                                                    <li>Engage in unlawful or fraudulent activities.</li>
                                                    <li>Post or share inappropriate, defamatory, or offensive content.</li>
                                                    <li>Use bots or automated tools to interact with the platform without prior permission.</li>
                                                </ul>

                                                <h4 className="mb-3">3. Intellectual Property</h4>
                                                <p className="mb-4">
                                                    The content on Emilo, including logos, text, images, and software, is the
                                                    intellectual property of Emilo or its licensors. Users must not replicate,
                                                    distribute, or use this content without express permission.
                                                </p>

                                                <h4 className="mb-3">4. Termination</h4>
                                                <p className="mb-4">
                                                    Emilo reserves the right to terminate or suspend user accounts that violate these
                                                    terms or engage in behavior detrimental to the platform's integrity.
                                                </p>

                                                <h4 className="mb-3">5. Limitation of Liability</h4>
                                                <p className="mb-4">
                                                    Emilo shall not be held liable for any damages, losses, or interruptions resulting
                                                    from the use of the platform. Users acknowledge that they use the platform at
                                                    their own risk.
                                                </p>

                                                <h4 className="mb-3">6. Changes to Terms</h4>
                                                <p className="mb-4">
                                                    We may revise these terms from time to time. Continued use of the platform
                                                    after changes have been made constitutes acceptance of the updated terms.
                                                </p>

                                                <h4 className="mb-3">7. Governing Law</h4>
                                                <p className="mb-4">
                                                    These terms are governed by the laws of [Insert Jurisdiction], and any disputes
                                                    arising shall be resolved in accordance with applicable laws.
                                                </p>

                                                <h4 className="mb-3">8. Contact Us</h4>
                                                <p className="mb-2">
                                                    For any questions, feedback, or concerns regarding these Terms and Conditions,
                                                    you can contact us at:
                                                </p>
                                                <ul className="mb-0">
                                                    <li><strong>Email:</strong> info@emilo.in</li>
                                                    <li><strong>Address:</strong> Raipur</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
            <FooterContent />
        </>
    );
};

export default TermsAndConditions;
