import React from 'react';
import SpecialDaysRightBlock from './SpecialDaysRightBlock';
import SpecialDaysCenterBlock from './SpecialDaysCenterBlock';


const SpecialDays = () => {
  return (
    <>
      {
        <section className='sc-specialDays'>
          {/* Center Block */}
          <SpecialDaysCenterBlock />

          {/* Right Block */}
          <SpecialDaysRightBlock />
        </section>
      }
    </>
  );
};

export default SpecialDays;