import React, { useContext, useEffect } from 'react';
import { AwsFileUrl, BASE_URL_STATIC } from '../../../config';
import { TfiLineDouble } from "react-icons/tfi";
import { FiBox } from "react-icons/fi";
import { IoMdStar } from "react-icons/io";
import Slider from "react-slick"; 
import { MdOutlineSettings } from "react-icons/md";
import { businessContext } from '../UseContext/BusinessContext';
import AddProductPopup from '../Popup/Home/ProductService/AddProductPopup';
import EditProductPopup from '../Popup/Home/ProductService/EditProductPopup';
import ViewProductPopup from '../Popup/Home/ProductService/ViewProductPopup';
import AddServicePopup from '../Popup/Home/ProductService/AddServicePopup';
import EditServicePopup from '../Popup/Home/ProductService/EditServicePopup';
import ViewServicePopup from '../Popup/Home/ProductService/ViewServicePopup';


const BusinessHomeProductAndServices = () => {

  const { 
    paramsPageId,
    setShowAddProductPopup, setShowEditProductPopup, setShowViewProductPopup,
    setShowAddServicePopup, setShowEditServicePopup, setShowViewServicePopup,
    products, setProduct,
    productsServices,
    checkIsItMyPage, isItMyPage,
    getProducts, 
    setService,
    services, getServices,
  } = useContext(businessContext);
  

  // ----- Logged in user ----- 

  const productSetting = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1700, 
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1400, 
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768, 
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  };

  const serviceSetting = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1700, 
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1400, 
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768, 
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  };


  // ----- Other user -----

  const productSetting2 = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1700, 
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1400, 
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1200, 
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 992, 
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768, 
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 576, 
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  };

  const serviceSetting2 = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1700, 
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1400, 
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1200, 
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 992, 
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768, 
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 576, 
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  };


  useEffect(() => {
    checkIsItMyPage(paramsPageId);
    getProducts(paramsPageId);
    getServices(paramsPageId);
  }, [paramsPageId]);
  

  return (
    <>
      {/* Popup */}
      <>      
        {/* Add Product Popup */}
        <AddProductPopup />

        {/* Edit Product Popup */}
        <EditProductPopup />

        {/* View Product Popup */}
        <ViewProductPopup />

        {/* Add Service Popup */}
        <AddServicePopup />

        {/* Edit Service Popup */}
        <EditServicePopup />

        {/* View Service Popup */}
        <ViewServicePopup />
      </>

      <section className=''>
        {/* Search Bar */}
        <div className='d-flex gap-3 mb-5'>
          <div className='d-flex flex-grow-1 align-items-center rounded-pill ps-3 ps-sm-4 py-2 bg-white shadow-sm'>
            <img src={`${BASE_URL_STATIC}/icon/search.svg`} alt="search" />
            <input type="search" className='w-10 bg-transparent flex-grow-1 border-0 ms-2 ms-sm-3' placeholder='Search Post, Product & Services...' />
          </div>

          <button className='py-2 bg-white shadow-sm rounded-2'>
            <TfiLineDouble style={{ width: "20px", color: "gray" }} />
          </button>
        </div>

        <>
          {
            isItMyPage
            ?
            <>
              {/* ----- It will show to logged in user ----- */}

              {/* Products */}
              <div>
                <h4 className='fw-semibold mb-4'>Products</h4>

                <div className='row mb-5 mx-auto'>
                  <div className='col-sm-3 col-md-2 px-0 d-flex flex-column gap-3 justify-content-center align-items-center mb-3 mb-sm-0'>
                    <div className='shadow-sm rounded-3 bg-white d-flex align-items-center justify-content-center cursor-pointer' style={{ width : "100px",  height : "100px" }} onClick={() => setShowAddProductPopup(true)}>                  
                      <FiBox style={{ width: "50px", height: "50px", color: "#5367F9" }} />
                    </div>

                    <small className='text-primary fw-semibold cursor-pointer' onClick={() => setShowAddProductPopup(true)}>Add Products</small>
                  </div>
                
                  <div className='col-sm-9 col-md-10 px-0'>
                    <Slider {...productSetting} className='business-home-product-service-slick'>
                      {
                        products && products.length > 0
                        &&
                        products.map((product, index) => (
                          <div className='bg-white rounded-4 shadow-sm p-3 position-relative' onClick={() => {setProduct(product); setShowViewProductPopup(true)}} key={index}>
                            <img src={product.images.length > 0 && AwsFileUrl+product.images[0].image} className="w-100 object-fit-contain rounded-4 mb-3" style={{ height: '175px' }} alt="search" />

                            <p className="text-truncate fw-semibold">{product.title}</p>

                            <p className="text-secondary text-truncate mb-2" style={{ fontSize: '15px' }}>{product.description}</p>

                            <div className="d-flex justify-content-between align-items-center">
                              <div>
                                {/* <span className="badge rounded-pill text-bg-primary me-2">5 <IoMdStar /></span> */}

                                {/* <small className='text-secondary' style={{ fontSize: '13px' }}>(3124)</small> */}

                                <h5 className='fw-semibold mb-0'>₹ {product.price}</h5>
                              </div>

                              <button type='button' className="btn btn-primary" onClick={(e) => {e.stopPropagation(); window.open(product.link, '_blank')}}>Buy Now</button>
                            </div>

                            {/* Key Product */}
                            <>
                              {
                                product.keyProduct === 'true'
                                &&
                                <div className='position-absolute top-0 start-0 bg-primary d-flex align-items-center gap-2 px-2 py-1' style={{ borderTopLeftRadius: '15px', borderBottomRightRadius: '15px' }}>
                                  <img src="/images/business/icons/star-white.svg" style={{ height: '13px' }} alt="star" />
                                  <small className='text-white'>Key Product</small>
                                </div>
                              }
                            </>

                            {/* Edit */}
                            <img src={`/images/business/icons/edit-blue.svg`} className='position-absolute top-0 end-0 mt-3 me-3 cursor-pointer' style={{ height: '20px' }} alt="edit" 
                              onClick={(e) => {e.stopPropagation(); setShowEditProductPopup(true); setProduct(product)}}
                            />
                          </div>
                        ))
                      }
                    </Slider>
                  </div>
                </div>
              </div>

              {/* Services */}
              <div>
                <h4 className='fw-semibold mb-4'>Services</h4>

                <div className='row mb-5 mx-auto'>
                  <div className='col-sm-3 col-md-2 px-0 d-flex flex-column gap-3 justify-content-center align-items-center mb-3 mb-sm-0'>
                    <div className='shadow-sm rounded-3 bg-white d-flex align-items-center justify-content-center cursor-pointer' style={{ width : "100px",  height : "100px" }} onClick={() => setShowAddServicePopup(true)}>                  
                      <MdOutlineSettings style={{ width: "50px", height: "50px", color: "#5367F9" }} />
                    </div>

                    <small className='text-primary fw-semibold cursor-pointer' onClick={() => setShowAddServicePopup(true)}>Add Services</small>
                  </div>
                
                  <div className='col-sm-9 col-md-10 px-0'>
                    <Slider className='business-home-product-service-slick' {...serviceSetting}>
                      {
                        services && services.length > 0
                        &&
                        services.map((service, index) => (
                          <div className='bg-white rounded-4 shadow-sm p-3' onClick={() => {setService(service); setShowViewServicePopup(true)}} key={index}>
                            <div className='position-relative'>
                              <img src={service.images && service.images.length > 0 && AwsFileUrl+service.images[0].image} className="w-100 object-fit-contain rounded-4 mb-3" style={{ height: '175px' }} alt="search" />

                              <div className='position-absolute top-0 end-0 mt-2 me-2 p-2 rounded-circle' style={{ backgroundColor: '#e2e3e5cc' }}>
                                <img src={`/images/business/icons/edit-white.svg`} style={{ height: '15px' }} alt="edit" 
                                  onClick={(e) => {e.stopPropagation(); setShowEditServicePopup(true); setService(service)}}
                                />
                              </div>
                            </div>

                            <p className="text-truncate fw-semibold">{service.title}</p>

                            <p className="text-secondary text-truncate mb-2" style={{ fontSize: '15px' }}>{service.description}</p>

                            <div className="d-flex justify-content-between align-items-center">
                              <div>
                                <span className="badge rounded-pill text-bg-primary me-2">5 <IoMdStar /></span>

                                <small className='text-secondary' style={{ fontSize: '13px' }}>(1432)</small>

                                <h5 className='fw-semibold mt-1 mb-0'>₹ {service.price}</h5>
                              </div>

                              <button type='button' className="btn btn-primary">Call Now</button>
                            </div>
                        </div>
                        ))
                      }
                    </Slider>
                  </div>
                </div>
              </div>
            </>
            :
            <>
              {/* ----- It will show to other user ----- */}
              
              {/* Products */}
              <div className='mb-5'>
                <h4 className='fw-semibold mb-4'>Products</h4>

                <div>
                  <Slider {...productSetting2} className='business-home-product-service-slick-other'>
                    {
                      productsServices && productsServices.products.length > 0
                      && 
                      productsServices.products.map((product, index) => (
                        <div className='bg-white rounded-4 shadow-sm p-3 position-relative' key={index}>
                          <img src={AwsFileUrl+product.image} className="w-100 object-fit-contain rounded-4 mb-3" style={{ height: '175px' }} alt="search" />

                          <p className="text-truncate fw-semibold">{product.heading}</p>

                          <p className="text-secondary text-truncate mb-2" style={{ fontSize: '15px' }}>{product.description}</p>

                          <div className="d-flex justify-content-between align-items-center">
                            <div>
                              <span className="badge rounded-pill text-bg-primary me-2">
                                {product.rating} <IoMdStar />
                              </span>

                              <small className='text-secondary' style={{ fontSize: '13px' }}>(143)</small>

                              <h5 className='fw-semibold mt-1 mb-0'>₹ {product.price}</h5>
                            </div>

                            <button type='button' className="btn btn-primary">Buy Now</button>
                          </div>

                          {/* Edit */}
                          <img src={`/images/business/icons/edit-blue.svg`} className='position-absolute top-0 end-0 mt-3 me-3' style={{ height: '20px' }} alt="edit" />
                        </div>
                      ))
                    }
                  </Slider>
                </div>
              </div>

              {/* Services */}
              <div className='mb-5'>
                <h4 className='fw-semibold mb-4'>Services</h4>

                <div>
                    <Slider {...serviceSetting2} className='business-home-product-service-slick-other'>
                      {
                        productsServices && productsServices.services.length > 0
                        && 

                        productsServices.services.map((service, index) => (
                          <div className='bg-white rounded-4 shadow-sm p-3' key={index}>
                            <div className='position-relative'>
                              <img src={AwsFileUrl+service.image} className="w-100 object-fit-cover rounded-4 mb-3" style={{ height: '175px' }} alt="search" />

                              <div className='position-absolute top-0 end-0 mt-2 me-2 p-2 rounded-circle' style={{ backgroundColor: '#e2e3e5cc' }}>
                                <img src={`/images/business/icons/edit-white.svg`} style={{ height: '15px' }} alt="edit" />
                              </div>
                            </div>

                            <p className="text-truncate fw-semibold">{service.heading}</p>

                            <p className="text-secondary text-truncate mb-2" style={{ fontSize: '15px' }}>{service.description}</p>

                            <div className="d-flex justify-content-between align-items-center">
                              <div>
                                <span className="badge rounded-pill text-bg-primary me-2">
                                  {service.rating} <IoMdStar />
                                </span>

                                <small className='text-secondary' style={{ fontSize: '13px' }}>(143)</small>

                                <h5 className='fw-semibold mt-1 mb-0'>₹ {service.price}</h5>
                              </div>

                              <button type='button' className="btn btn-primary">Call Now</button>
                            </div>
                        </div>
                        ))
                      }
                    </Slider>
                  </div>
              </div>
            </>
          }
        </>
      </section>
    </>
  );
};

export default BusinessHomeProductAndServices;