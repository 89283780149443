import React, { useContext, useEffect } from 'react'
import { AwsFileUrl, BASE_URL_STATIC } from '../../../config'
import CreateEventPopup from '../Popup/Events/CreateEventPopup';
import { businessContext } from '../UseContext/BusinessContext';
import { useNavigate } from 'react-router-dom';


const Events = () => {

  const { 
    paramsPageId,
    getEvents,
    events,
    setShowCreateEventPopup,
  } = useContext(businessContext);

  const navigate = useNavigate();

  const options = ['Popular', 'Local', 'Online', 'In Person'];

  useEffect(() => {
    getEvents();
  }, []);

  return (
    <>
      {/* Create Event Popup */}
      <CreateEventPopup />

      <section className='business-rb h-100 overflow-y-scroll scrollbar-hidden pt-3 px-2 px-sm-3 px-md-4 px-xl-5' style={{ backgroundColor: '#edf1f4' }}>
        {/* Search Bar, Notification, Chat and Profile */}
        <div className='bg-white d-flex justify-content-between align-items-center rounded-3 px-2 px-sm-3 px-md-4 py-3'>
            {/* Search Bar */}
            <div className='d-flex flex-grow-1 w-75 rounded-pill px-3 px-sm-4 py-1 py-sm-2 shadow-sm' style={{ backgroundColor: '#f5f5f5' }}>
              <img src={`${BASE_URL_STATIC}/icon/search.svg`} className='me-2 me-sm-3' alt="search" />

              <input type="search" className='w-100 bg-transparent border-0' placeholder='Search...' />
            </div>

            {/* Notification */}
            <div className='mx-3 mx-sm-4 mx-md-5'>
              <img src={`${BASE_URL_STATIC}/top-icon1.png`} alt="notification" />
            </div>

            {/* Chat */}
            <div>
              <img src={`${BASE_URL_STATIC}/top-icon2.png`} alt="chat" />
            </div>

            {/* Profile */}
            <div className='ms-3 ms-sm-4 ms-md-5'>
              <img src={`${BASE_URL_STATIC}/profile-img1.jpg`} className='rounded-circle' style={{ height: '30px', width: '30px' }} alt="profile" />
            </div>
        </div>

        {/* Heading */}
        <div className='d-flex align-items-center my-3'>
          <img src={`/images/icons/events-title.svg`} style={{ width: "20px" }} alt="event"  />   

          <h5 className='fw-semibold mb-0 mx-2'>Events</h5> 

          <img src={`/images/icons/info.svg`} style={{ width: "20px" }} alt="event" />

          <button type="button" className='btn btn-primary rounded ms-auto d-flex align-items-center' onClick={() => setShowCreateEventPopup(true)}>
            <img src={`/images/icons/event-add.svg`} className='me-2' style={{width:"20px"}}   alt="event" />  
            <span>Create Event</span>
          </button>
        </div>

        {/* Search Bar */}
        <div className='d-flex gap-3 align-items-center mb-3'>
          <div className='bg-white flex-grow-1 d-flex gap-2 align-items-center p-2 ps-3 ps-sm-4 rounded-pill'>
            <img src="https://s3aws.emilo.in/images/icon/search.svg" style={{ height: '20px' }} alt="search" />

            <input type="search" className='flex-grow-1 form-control border-0 shadow-none' placeholder='Search on products showcase...' />
          </div>

          <img src={`/images/business/icons/event-adjust.svg`} style={{ height: '50px', width: '50px' }} alt="event-adjust" />
        </div>

        {/* Options */}
        <div className='d-flex flex-wrap gap-3 align-items-center mb-3'>
          <div className='bg-white p-2 rounded-circle'>
            <img src={`/images/icons/starred.svg`} style={{ height: '25px' }} alt="starred" />
          </div>

          <div className='flex-grow-1 d-flex flex-wrap gap-3 align-items-center'>
            <span className='bg-primary text-white rounded-pill flex-grow-1 text-center py-2 px-3'>Top</span>

            <>
              {
                options.map((option, index) => (
                  <span className='bg-white rounded-pill flex-grow-1 text-center py-2 px-3' key={index}>{option}</span>
                ))
              }
            </>
          </div>
        </div>

        {/* Events */}
        <div className='business-events mb-3'>
          {
            events.map((event, index) => (
              <div className='p-2 rounded-4 shadow-sm d-flex flex-column justify-content-between' style={{ backgroundColor: '#f8f9fa', maxWidth: '559px' }} onClick={() => navigate(`/business/event/${paramsPageId}/${event._id}`)} key={index}>
                <div className='mb-2' style={{ height: '175px' }}>
                  <img src={AwsFileUrl+event.image} className='rounded-4 h-100 w-100 object-fit-fill' alt="event" />
                </div>

                <h5 className='fw-semibold mb-0'>{event.title.length > 40 ? `${event.title.slice(0, 40)}...` : event.title}</h5>

                <span>{event.subTitle.length > 50 ? `${event.subTitle.slice(0, 40)}...` : event.subTitle}</span>

                <div>
                  <small className='text-secondary'>
                    {new Date(`${event.startDate}T${event.startTime}`).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })} {' '}
                    {' - '} 
                    {new Date(`${event.endDate}T${event.endTime}`).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })} {' '}
                  </small>

                  {' ● '} 

                  <small className='fw-semibold'>
                    {new Date(`${event.startDate}T${event.startTime}`).toLocaleTimeString('en-US', { hour: '2-digit',minute: '2-digit', hour12: true })}
                    {' - '} 
                    {new Date(`${event.endDate}T${event.endTime}`).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })}
                  </small>
                </div>

                <small className='text-secondary'>{event.address.length > 50 ? `${event.address.slice(0, 50)}...` : event.address}</small>

                <div className='mt-2 d-flex gap-3'>
                  <button type="button" className='btn btn-primary flex-grow-1 rounded-4' onClick={(e) => e.stopPropagation()}>
                    <img src={`/images/business/icons/attending-white.svg`} className='me-2' style={{ height: '25px' }} alt="attending" />
                    <span>Attending</span>
                  </button>

                  <button type="button" className='btn btn-white text-primary shadow-sm' onClick={(e) => e.stopPropagation()}>RSVP</button>

                  <button type="button" className='btn btn-white shadow-sm' onClick={(e) => e.stopPropagation()}>
                    <img src={`/images/icons/unstarred.svg`} style={{ height: '20px' }} alt="unstarred" />
                  </button>
                </div>
              </div>
            ))
          }
        </div>
      </section>
    </>
  );
};

export default Events;