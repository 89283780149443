import React from 'react'
import { Outlet } from 'react-router-dom'
import LeftBlock from './Home/LeftBlock/LeftBlock'

const SoftCornerBody = () => {

  return (
    <section className={`section-main `}>
        <div className="container-fluid p-0">
          <div className="row no-gutters" style={{display:"flex"}}>
            <div className="d-none d-lg-block col-lg-3 overflow-y-scroll scrollbar-hidden shadow" style={{ zIndex: 1, height: '100vh' }}>
              <LeftBlock />
            </div>

            <div className="col-lg-9" style={{ background: "#F8F8F8" }}>
              <Outlet />
            </div>
          </div>
        </div>
      </section>
  )
}

export default SoftCornerBody
