import React, { useEffect, useContext } from "react";
import { ScApiContext } from "../useContext/softCornerContext";
import { BASE_URL_STATIC } from "../../../config";
import AddDatePlannerPopup from "../Popup/DatePlanner/AddDatePlannerPopup";
import { scheduler } from "dhtmlx-scheduler";
import "dhtmlx-scheduler";
import "dhtmlx-scheduler/codebase/dhtmlxscheduler_material.css";


const DatePlannerCenterBlock = () => {

  const { 
    setShowAddPlanPopup,
    datePlanners, 
    setDatePlanner,
    setFilteredDatePlanners,
  } = useContext(ScApiContext);

  // handle click add new plan
  const handleClickAddNewPlan = () => {
    setShowAddPlanPopup(true);

    setDatePlanner({
      _id: '',
      title: '',
      category: '',
      categoryOther: '',
      location: '',
      lat: '',
      lng: '',
      allDay: 'false',
      startDateTime: '',
      endDateTime: '',
      setCustomAlert: 'false',
      alerts: [],
      customDateTime: '', 
      note: '',
    });
  };

  // handle search
  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();

    const filtered = datePlanners.filter((plan) =>
      plan.title.toLowerCase().includes(searchValue) 
      ||
      plan.location.toLowerCase().includes(searchValue)
    );

    setFilteredDatePlanners(filtered);
  };

  // dhtmlx scheduler (calendar)
  useEffect(() => {
    scheduler.config.first_hour = 6;
    scheduler.config.last_hour = 1;
    scheduler.config.header = ['day', 'week', 'month', 'date', 'prev', 'today', 'next'];
  
    scheduler.init("scheduler_here", new Date(), "month");

    // Disable the add event popup on empty click
    scheduler.attachEvent("onEmptyClick", function (id, e) {
      return false; // Prevent adding events by clicking on an empty slot
    });

    // Disable editing or updating events by clicking on them
    scheduler.attachEvent("onClick", function (id, e) {
      return false; // Prevent editing existing events
    });

    // Disable the lightbox (add/edit event popup)
    scheduler.config.details_on_create = false;
    scheduler.config.details_on_dblclick = false;
  
    // Parse data for the scheduler
    const parsedEvents = datePlanners
    .filter((value) => value.status === "accepted")
    .map((value) => ({
      id: value._id,
      text: value.title,
      start_date: value.allDay === 'true' ? `${value.startDateTime.split('T')[0]} 00:00` : value.startDateTime.replace('T', ' '),
      end_date: value.allDay === 'true' ? `${value.startDateTime.split('T')[0]} 23:59` : value.endDateTime.replace('T', ' '),
    }));
  
    scheduler.parse(parsedEvents);
  
    return () => {
      scheduler.clearAll();
    };
  }, [datePlanners]);

  return (
    <>
      {/* Add new date plan popup */}
      <AddDatePlannerPopup />

      <section className="sc-datePlanner-centerBlock pt-5 px-5">
        {/* Search & Add */}
        <div className="d-flex align-items-center gap-3 mt-5 mb-4">
          <div className="d-flex align-items-center bg-white rounded-pill ps-4 shadow-sm flex-grow-1">
            <img src={`${BASE_URL_STATIC}/search.png`} style={{ height: '20px' }} alt="search" />
            <input type="search" className="form-control rounded-pill border-0 shadow-none" onChange={handleSearch} placeholder="Search here..." />
          </div>

          <button type="button" className="btn bg-white shadow-sm d-flex align-items-center gap-1 rounded-pill" onClick={handleClickAddNewPlan}>
            <img src={`${BASE_URL_STATIC}/SoftCorner/sc-addAlbum.png`} style={{ height: '20px' }} alt="add" />
            <span>New Plan</span>
          </button>
        </div>

        {/* Calander */}
        <div id="scheduler_here" style={{ height: '75vh' }} />
      </section>
    </>
  )
}

export default DatePlannerCenterBlock