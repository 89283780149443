import React, { useContext, useEffect } from "react";
import { AwsFileUrl, BASE_URL_STATIC } from "../../../config";
import { businessContext } from "../UseContext/BusinessContext";
import { useNavigate, useParams } from "react-router-dom";
import AddEventHostsPopup from "../Popup/Events/AddEventHostsPopup";
import UpdateEventPopup from "../Popup/Events/UpdateEventPopup";
import Slider from "react-slick";


const Event = () => {

  const navigate = useNavigate();

  const { pageId, eventId } = useParams();
  
  const { 
    paramsPageId,
    getEvent, 
    event,
    getEventOrganiserPage,
    eventOrganiserPage,
    setShowUpdateEventPopup,
    getEventHosts,
    eventHostsList,
    setShowAddEventHostsPopup,
    deleteEventBanner,
    deleteEventHost,
    deleteEvent,
  } = useContext(businessContext); 

  const bannerSetting = {
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    infinite: false,
    dots: true,
    customPaging: (i) => (
      <img
        src={event.images && event.images[i] && event.images[i].image && AwsFileUrl + event.images[i].image}
        className="cursor-pointer h-100 w-100 object-fit-fill bg-white"
        style={{ borderRadius: '30px' }}
        alt={`dot-${i}`}
      />
    ),
    appendDots: (dots) => (
      <div className="d-flex justify-content-center gap-3 mt-3">{dots}</div>
    ),
  };

  useEffect(() => {
    getEvent(eventId);
    getEventOrganiserPage(eventId);
    getEventHosts(eventId);
  }, [eventId]);


  return (
    <>
      {/* Add event host popup */}
      <AddEventHostsPopup />

      {/* Update Event Popup */}
      <UpdateEventPopup />

      {
        event
        &&
        <section className="business-rb h-100 overflow-y-scroll scrollbar-hidden pt-3 px-2 px-sm-3 px-md-4 px-xl-5" style={{ backgroundColor: "#edf1f4" }} >
          {/* Search Bar, Notification, Chat and Profile */}
          <div className="bg-white d-flex justify-content-between align-items-center rounded-3 px-2 px-sm-3 px-md-4 py-3">
            {/* Search Bar */}
            <div className="d-flex flex-grow-1 w-75 rounded-pill px-3 px-sm-4 py-1 py-sm-2 shadow-sm" style={{ backgroundColor: "#f5f5f5" }} >
              <img src={`${BASE_URL_STATIC}/icon/search.svg`} className="me-2 me-sm-3" alt="search" />

              <input type="search" className="w-100 bg-transparent border-0" placeholder="Search..." />
            </div>

            {/* Notification */}
            <div className="mx-3 mx-sm-4 mx-md-5">
              <img src={`${BASE_URL_STATIC}/top-icon1.png`} alt="notification" />
            </div>

            {/* Chat */}
            <div>
              <img src={`${BASE_URL_STATIC}/top-icon2.png`} alt="chat" />
            </div>

            {/* Profile */}
            <div className="ms-3 ms-sm-4 ms-md-5">
              <img src={`${BASE_URL_STATIC}/profile-img1.jpg`} className="rounded-circle" style={{ height: "30px", width: "30px" }} alt="profile" />
            </div>
          </div>

          {/* Phone, Email & Link */}
          <div className="my-4 d-flex flex-wrap justify-content-end gap-3 gap-md-4">
            {/* Phone */}
            <div className="bg-white rounded-3 px-3 shadow-sm d-flex align-items-center">
              <img src={`/images//business/icons/phone-blue.svg`} style={{ width: "30px" }} alt="phone" />
              <span className="ms-2 fw-semibold">{event.mobile}</span>
            </div>

            {/* Email */}
            <div className="bg-white rounded-3 px-3 shadow-sm d-flex align-items-center">
              <img src={`/images//business/icons/mail-blue.svg`} style={{ width: "30px" }} alt="mail" />
              <span className="ms-2 fw-semibold">{event.email}</span>
            </div>

            {/* Link */}
            <div className="bg-white rounded-3 px-3 shadow-sm d-flex align-items-center">
              <img src={`/images//business/icons/websiteClick.svg`} style={{ width: "23px" }} alt="link" />
              <span className="ms-2 fw-semibold text-primary cursor-pointer" onClick={() => {
                const websiteLink = event.websiteLink.startsWith("http") ? event.websiteLink : `https://${event.websiteLink}`;
                window.open(websiteLink, "_blank");
              }}>
                {event.websiteLink}
              </span>
            </div>
          </div>

          {/* Banner */}
          <>
            {
              event.images && event.images.length > 0
              &&
              <Slider className='business-banner-slider shadow-sm rounded-3 mb-3 bg-white' {...bannerSetting}>
                {
                  event.images.map((image, index) => (
                    <div className='position-relative' key={index}>
                      <img src={AwsFileUrl + image.image} className='w-100 object-fit-fill rounded-3' style={{ height: '300px' }} alt="banner" />

                      <>
                        {
                          event.myEvent === 'true'
                          &&
                          <div className='position-absolute top-0 end-0 mt-2 me-2 d-flex gap-2'>
                            {/* Add */}
                            <>
                              {
                                event.images.length < 5
                                &&
                                <div className='p-1 rounded-3 cursor-pointer shadow-sm' style={{ backgroundColor: '#5f5f5f85' }} onClick={() => setShowUpdateEventPopup(true)}>
                                  <img src={`/images/business/icons/add-white.svg`} style={{ width: '20px' }} alt="add-circle" />
                                </div>
                              }
                            </>

                            {/* Delete */}
                            <>
                              {
                                event.images.length > 1
                                && 
                                <div className='p-1 rounded-3 cursor-pointer shadow-sm' style={{ backgroundColor: '#5f5f5f85' }} onClick={() => deleteEventBanner(image._id)}>
                                  <img src={`/images/icons/delete-white.svg`} style={{ width: '20px' }} alt="delete-red" />
                                </div>
                              }
                            </>
                          </div>
                        }
                      </>
                    </div>
                  ))
                }
              </Slider>
            }
          </>

          {/* Heading */}
          <div className="bg-white p-3 rounded-3 shadow-sm d-flex flex-wrap gap-3 align-items-start mb-3">
            <div className="flex-grow-1">
              <h2 className="fw-semibold mb-0">{event.title}</h2>
              <span className="fw-semibold">{event.subTitle}</span>
            </div>

            <div className='d-flex gap-3 flex-wrap'>
              {
                event && event.myEvent === 'true'
                ?
                <>
                  <button type="button" className='btn border border-primary rounded-3'>
                    <img src={`/images/business/icons/boost-1-blue.svg`} className='me-2' style={{ height: '25px' }} alt="attending" />
                    <span className="text-primary">Boost</span>
                  </button>

                  <button type="button" className='btn btn-primary rounded-3 px-4'>
                    <img src={`/images/business/icons/mail-white.svg`} className='me-2' style={{ height: '25px' }} alt="attending" />
                    <span className="fw-semibold">Invite</span>
                  </button>

                  <button type="button" className='btn btn-white shadow-sm'>
                    <img src={`/images/business/icons/share-blue.svg`} style={{ height: '20px' }} alt="unstarred" />
                  </button>

                  <button type="button" className='btn btn-white shadow-sm' onClick={() => {deleteEvent(event._id); navigate(`/business/my-events/${paramsPageId}`)}}>
                    <img src={`/images/business/icons/delete-red.svg`} style={{ height: '20px' }} alt="delete" />
                  </button>
                </>
                :
                <>
                  <button type="button" className='btn btn-primary rounded-4 px-4'>
                    <img src={`/images/business/icons/attending-white.svg`} className='me-2' style={{ height: '25px' }} alt="attending" />
                    <span className="fw-semibold">Attending</span>
                  </button>

                  <button type="button" className='btn btn-white text-primary shadow-sm'>RSVP</button>

                  <button type="button" className='btn btn-white shadow-sm'>
                    <img src={`/images/icons/unstarred.svg`} style={{ height: '20px' }} alt="unstarred" />
                  </button>
                </>
              }

              <button type="button" className='btn btn-white shadow-sm'>
                <img src={`https://s3aws.emilo.in/images/icon/threeDot.svg`} style={{ height: '20px' }} alt="threeDot" />
              </button>
            </div>
          </div>

          {/* Main */}
          <div className="business-event mb-3">
            {/* Event Details */}
            <div className="rounded-3 shadow-sm p-3" style={{ backgroundColor: '#f8f8f8' }}>
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="fw-semibold mb-0">Event Details</h5>

                {
                  event.myEvent === 'true'
                  &&
                  <img src="/images/business/icons/edit-blue.svg" className="cursor-pointer" style={{ height: '20px', width: '20px' }} onClick={() => setShowUpdateEventPopup(true)} alt="edit" />
                }
              </div>

              <hr className="hr bg-secondary-subtle" />

              {/* Date & Time */}
              <div className="mb-4">
                <div className="d-flex align-items-center gap-2 mb-1">
                  <img src={`/images/icons/time.svg`} style={{ height: '17px' }} alt="date & time" />
                  <small className="text-primary">Date And Time</small>
                </div>

                <span className=''>
                  {new Date(`${event.startDate}T${event.startTime}`).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })} {' '}
                  {' - '} 
                  {new Date(`${event.endDate}T${event.endTime}`).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })} {' '}
                </span>

                {' ● '} 

                <span className='fw-semibold'>
                  {new Date(`${event.startDate}T${event.startTime}`).toLocaleTimeString('en-US', { hour: '2-digit',minute: '2-digit',hour12: true })}
                  {' - '} 
                  {new Date(`${event.endDate}T${event.endTime}`).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })}
                </span>
              </div>

              {/* Address */}
              <div className="mb-4 d-flex gap-3 align-items-center">
                <div className="flex-grow-1">
                  <div className="d-flex align-items-center gap-2 mb-1">
                    <img src={`/images/icons/location.svg`} style={{ height: '17px' }} alt="date & time" />
                    <small className="text-primary">Address</small>
                  </div>

                  <span>{event.address}</span>
                </div>

                <button type="button" className="btn btn-sm shadow-sm bg-white rounded-3">
                  <img src={`/images/icons/location.svg`} className="me-2" style={{ height: '17px' }} alt="location" />
                  <span className="text-primary">See Map</span>
                </button>
              </div>

              {/* Ticket */}
              <div className="mb-4">
                <div className="d-flex align-items-center gap-2 mb-1">
                  <img src={`/images/icons/link-blue.svg`} style={{ height: '17px' }} alt="date & time" />
                  <small className="text-primary">Ticket</small>
                </div>

                <div className="cursor-pointer" onClick={() => {
                  const ticketLink = event.ticketLink.startsWith("http") ? event.ticketLink : `https://${event.ticketLink}`;
                  window.open(ticketLink, "_blank");
                }}>
                  {event.ticketLink}
                </div>
              </div>

              {/* Mode */}
              <div className="mb-4">
                <div className="d-flex align-items-center gap-2 mb-1">
                  <img src={`/images/icons/hand-shake-blue.svg`} style={{ height: '17px' }} alt="date & time" />
                  <small className="text-primary">Mode</small>
                </div>

                <div>{event.mode}</div>
              </div>

              {/* Interested */}
              <div className="mb-4">
                <div className="d-flex align-items-center gap-2 mb-1">
                  <img src={`/images/icons/star.svg`} style={{ height: '17px' }} alt="date & time" />
                  <small className="text-primary">Interested</small>
                </div>

                <div>12,385 People</div>
              </div>

              {/* Duration */}
              <div className="mb-5">
                <div className="d-flex align-items-center gap-2 mb-1">
                  <img src={`/images/icons/duration.svg`} style={{ height: '17px' }} alt="date & time" />
                  <small className="text-primary">Duration</small>
                </div>

                <div>3 Hrs</div>
              </div>

              <h5 className="fw-semibold mb-0">About</h5>

              <hr className="hr bg-secondary-subtle" />

              <span>{event.about}</span>
            </div>

            <div>
              {/* Event Organiser Page */}
              <div className="rounded-3 shadow-sm p-3 mb-3" style={{ backgroundColor: '#f8f8f8' }}>
                <h5 className="fw-semibold mb-0">Event Organiser Page</h5>

                <hr className="hr bg-secondary-subtle" />

                <div className="d-flex gap-3 flex-wrap align-items-center">
                  <div className="rounded-circle cursor-pointer" style={{ height: '125px', width: '125px' }} onClick={() => navigate(`/business/${eventOrganiserPage._id}`)}>
                    <img src={AwsFileUrl+eventOrganiserPage.imageId} className="border h-100 w-100 object-fit-contain rounded-circle" alt="profile" />
                  </div>

                  <div>
                    <div className="d-flex align-items-center gap-3 flex-wrap">
                      <h2 className="fw-semibold m-0 cursor-pointer" onClick={() => navigate(`/business/${eventOrganiserPage._id}`)}>{eventOrganiserPage.fname}</h2>
                      <small className="text-secondary bg-white rounded-pill shadow-sm px-3 py-1">249 Past Events</small>
                    </div>

                    <span className="fw-semibold mt-1 mb-2">
                      {eventOrganiserPage.pageCategory} 
                      {' '}
                      ({eventOrganiserPage.pageSubCategory && eventOrganiserPage.pageSubCategory.join(', ')})
                    </span>

                    <>
                      {
                        eventOrganiserPage._id === pageId
                        ? 
                        // Edit & Share
                        <div className="d-flex gap-3 flex-wrap">
                          <button type="button" className="btn border border-primary rounded-4 d-flex align-items-center">
                            <img src={`/images/business/icons/edit-blue.svg`} className="me-2" style={{ height: '20px' }} alt="edit" />
                            <span className="text-primary">Edit</span>
                          </button>

                          <button type="button" className="btn border border-primary rounded-4 d-flex align-items-center">
                            <img src={`/images/icons/share.svg`} className="me-2" style={{ height: '20px' }} alt="share" />
                            <span className="text-primary">Share</span>
                          </button>
                        </div>
                        :
                        // Follow & Message
                        <div className="d-flex gap-3 flex-wrap">
                          <button type="button" className="btn border border-primary rounded-4 d-flex align-items-center">
                            <img src={`/images/icons/follow.svg`} className="me-2" style={{ height: '20px' }} alt="follow" />
                            <span className="text-primary">Follow</span>
                          </button>

                          <button type="button" className="btn border border-primary rounded-4 d-flex align-items-center">
                            <img src={`/images/icons/message.svg`} className="me-2" style={{ height: '20px' }} alt="message" />
                            <span className="text-primary">Message</span>
                          </button>
                        </div>
                      }
                    </>
                  </div>
                </div>
              </div>

              {/* Event Host */}
              <div className="rounded-3 shadow-sm p-3" style={{ backgroundColor: '#f8f8f8' }}>
                <div className="d-flex align-items-center justify-content-between">
                  <h5 className="fw-semibold mb-0">Event Hosts</h5>

                  {
                    event.myEvent === 'true'
                    &&
                    <img src="/images/business/icons/add-circle.svg" className="cursor-pointer" style={{ height: '20px', width: '20px' }} onClick={() => setShowAddEventHostsPopup(true)} alt="add-circle" />
                  }
                </div>

                <div className="overflow-y-scroll scrollbar-hidden pb-3" style={{ maxHeight: '357px' }}>
                  {
                    eventHostsList && eventHostsList.length > 0 
                    &&
                    eventHostsList.map((host, index) => (
                      <div>
                        <hr className="hr bg-secondary-subtle" />

                        <div className="d-flex gap-3 flex-wrap align-items-center" key={index}>
                          <div className="rounded-circle cursor-pointer" style={{ height: '100px', width: '100px' }} onClick={() => navigate(`/profile/${host.userId}`)}>
                            <img src={AwsFileUrl+host.profile} className="border h-100 w-100 object-fit-contain rounded-circle" alt="profile" />
                          </div>

                          <div className="flex-grow-1">
                            <div className="d-flex align-items-center justify-content-between gap-2">
                              <h4 className="fw-semibold m-0 cursor-pointer" onClick={() => navigate(`/profile/${host.userId}`)}>{host.name}</h4>

                              {
                                event.myEvent === 'true'
                                &&
                                <img src="/images/icons/delete-black.svg" className="cursor-pointer" style={{ height: '20px', width: '20px' }} onClick={() => deleteEventHost(host._id)} alt="delete" />
                              }
                            </div>

                            <span className="fw-semibold mt-1 mb-2 text-primary cursor-pointer" onClick={() => navigate(`/profile/${host.userId}`)}>{host.username ? host.username : 'username06@'}</span>

                            <div className="d-flex gap-3 flex-wrap">
                              <button type="button" className="btn border border-primary rounded-4">
                                <img src={`/images/icons/follow.svg`} className="me-2" style={{ height: '20px' }} alt="follow" />
                                <span className="text-primary">Follow</span>
                              </button>

                              <button type="button" className="btn border border-primary rounded-4">
                                <img src={`/images/icons/message.svg`} className="me-2" style={{ height: '20px' }} alt="message" />
                                <span className="text-primary">Message</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>

              </div>
            </div>
          </div>
        </section>
      }
    </>
  );
};

export default Event;
