import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AwsFileUrl, BASE_URL_STATIC } from "../../../../config";
import { ScApiContext } from "../../useContext/softCornerContext";
import { useEffect } from "react";
import { getMyImage, getPartnerImage, getSoftCornerProfile } from "../../../Emilo/UseContext/ScApiHelpers";
import { ApiContext } from "../../../Emilo/UseContext/ApiCall";


const LeftBlock = () => {

  const [matchTime, setMatchTime] = useState(null);
  const [matchDays, setMatchDays] = useState(null);
  const [matchDate, setMatchDate] = useState(null);
  // const [isMatchDate, setIsMatchDate] = useState(null)
  const [myImage, setMyImage] = useState("");
  const [partnerImage, setPartnerImage] = useState("");
  const [profileDetails, setProfileDetails] = useState(null)
  // const [partnerGender, setPartnerGender] = useState("")
  const [viewCrush, setViewCrush] = useState({ views: 0, crushes: 0 })

  const { scConfig, isMatched, setmatchOpen, isSoftCornerPartnerExist, setEditMessage, btnEnable, setBtnEnable, setChatId, setIsMatched } = useContext(ScApiContext);
  const { loggedInUserId, setPartnerId } = useContext(ApiContext)


  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",];

  const nav = useNavigate();

  useEffect(() => {
    if (matchTime !== 0 && matchTime !== null) {
      const currentTime = new Date();
      const matchDate = new Date(matchTime);

      currentTime.setUTCHours(0, 0, 0, 0);
      matchDate.setUTCHours(0, 0, 0, 0);

      const timeDifference = currentTime.getTime() - matchDate.getTime();
      const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

      setMatchDays(daysDifference);
      setMatchDate(matchDate);
    } else {
      setMatchDays(0);
      setMatchDate(0);
    }
  }, [matchTime]);

  useEffect(() => {
    localStorage.setItem("isMatched", 0);

    getSoftCornerProfile(loggedInUserId, scConfig).then((e) => {
      if (e.matchStatus === 1) {
        localStorage.setItem("isMatched", 1)
        setmatchOpen(true)
      }
      if (e.matchDate !== 0 && e.matchDate !== null) {
        setMatchTime(e.matchDate)
        setIsMatched(true)
      } else {
        setIsMatched(false)
      }
      setPartnerId(e.partnerId)
      setBtnEnable(e.enableLinks)
      setEditMessage(e.editMessage)
      // setPartnerGender(e.partnerGender)
      setProfileDetails(e.getProfile)
      setViewCrush(prev => ({ ...prev, crushes: e.crush, views: e.view }))
      // setIsMatchDate(e.getMatchDate)
      setChatId(e.chatId)
    })
  }, [loggedInUserId, isSoftCornerPartnerExist, setChatId]);

  useEffect(() => {
    getMyImage(scConfig).then((res) => setMyImage(res));
    getPartnerImage(scConfig).then((res) => setPartnerImage(res) );
  }, [isSoftCornerPartnerExist]);

  return (
    <>
      <section className="vh-100 d-none d-lg-flex flex-column justify-content-between pt-5">
        {/* Div 1 */}
        <>
          {
            // loggedInUser && loggedInUser.gender === 'Male'
            true
            // false
            ?
            <div className="d-flex align-items-center justify-content-evenly pt-5">
              {
                myImage && myImage.myImageUrl
                ?
                <img className={`leaf-image1 ${profileDetails && profileDetails.gender === 'Male' ? 'male-box' : 'female-box'}`} src={`${AwsFileUrl + (myImage.myImageUrl.scProfileImg || myImage.myImageUrl.imageId)}`} alt="lb-rectangle-1" height={100} width={100} />
                :
                <img className={`leaf-image1 ${profileDetails && profileDetails.gender === 'Male' ? 'male-box' : 'female-box'}`} src='https://images.firstpost.com/wp-content/uploads/2016/10/keerthy-suresh-825.jpg' alt="lb-rectangle-1" height={100} width={100} />
              }

              <img src={`${BASE_URL_STATIC}/softcornerLogo.png`} alt="sc-logo-2" height={47} width={50} />
              {
                isSoftCornerPartnerExist && partnerImage 
                ?
                <img alt="leaf-img" className={`leaf-image2 ${profileDetails && profileDetails.gender === 'Male' ? 'female-box' : 'male-box'}`} src={`${AwsFileUrl + (partnerImage.partnerImageUrl.scProfileImg || partnerImage.partnerImageUrl.imageId)}`} height={125} width={125} style={{ filter: `blur(${isMatched ? '0px' : '2px'})` }} />
                :
                <div className={`leaf-image2 ${profileDetails && profileDetails.gender === 'Male' ? 'female-box' : 'male-box'}`}></div>
              }

            </div>
            :
            <div className="d-flex align-items-center justify-content-evenly pt-5">
              {
                myImage && <myImage className="myImageUrl"></myImage>
                  ?
                  <img className="leaf-image3" src='https://img.indiaforums.com/person/480x360/1/1581-kiara-advani.jpg?c=8xOB8D' alt="lb-rectangle-1" height={125} width={125} />
                  :
                  <div className="leaf-image3"></div>
              }

              <img src={`${BASE_URL_STATIC}/softcornerLogo.png`} alt="sc-logo-2" height={47} width={50} />

              {
                isSoftCornerPartnerExist && partnerImage && partnerImage.partnerImageUrl
                  ?
                  true
                    // false
                    ?
                    <img className="leaf-image4" src='https://filmfare.wwmindia.com/content/2023/apr/shahidkapoor11681969665.jpg' alt="lb-rectangle-2" height={125} width={125} style={{ filter: `blur(${isMatched ? '0px' : '2px'})` }} />
                    :
                    <img className="leaf-image4" src='https://filmfare.wwmindia.com/content/2023/apr/shahidkapoor11681969665.jpg' alt="lb-rectangle-2" height={125} width={125} />
                  :
                  <div className="leaf-image4"></div>
              }
            </div>
          }
        </>

        {/* Div 2 */}
        <>
          {
            isMatched
            ?
            <div className="text-center">
              <p className="text-white px-1 py-2 rounded-5 col-4 mx-auto" style={{ backgroundColor: '#FF6969' }}>{matchDays ? matchDays + " days" : 0}</p>

              <span className="my-2">Since</span>

              <p className="text-white px-1 py-2 rounded-5 col-5 mx-auto" style={{ backgroundColor: '#FF6969' }}>{matchDate && months[matchDate.getMonth()] + " " + matchDate.getDate() + " " + matchDate.getFullYear()}</p>
            </div>
            :
            <div className="d-flex justify-content-evenly">
              <div className="d-flex flex-column align-items-center bg-white shadow p-2 p-xl-3 rounded-3">
                <span className="">{viewCrush.views}</span>
                <small className="">Profile Views</small>
              </div>

              <div className="d-flex flex-column align-items-center bg-white shadow p-2 p-xl-3 rounded-3">
                <span className="">{viewCrush.crushes}</span>
                <small className="">No. Of Crush</small>
              </div>
            </div>
          }
        </>

        {/* Div 3 */}
        <div className="sc-lb-div-3 d-flex flex-column align-items-start mx-auto">
          <button className="bg-transparent border-0 mb-5 p-0" onClick={() => nav(`/SoftCorner`)}>
            <img src={`${BASE_URL_STATIC}/SoftCorner/lb-home.png`} className="me-3" alt="home" height={20} width={20} />
            <span>Home</span>
          </button>

          <button className="bg-transparent border-0 mb-5 p-0" disabled={btnEnable} onClick={() => nav(`/SoftCorner/Gallary`)}>
            <img src={`${BASE_URL_STATIC}/SoftCorner/lb-gallary.png`} className="me-3" alt="gallary" height={20} width={20} />
            <span>Gallery</span>
          </button>


          <button className="bg-transparent border-0 mb-5 p-0" disabled={btnEnable} onClick={() => nav(`/SoftCorner/SpecialDays`)}>
            <img src={`${BASE_URL_STATIC}/SoftCorner/lb-specialDays.png`} className="me-3" alt="specialDay" height={20} width={20} />
            <span>Special Days</span>
          </button>

          <button className="bg-transparent border-0 mb-5 p-0" disabled={!isMatched} onClick={() => nav(`/SoftCorner/date-planner`)}>
            <img src={`${BASE_URL_STATIC}/SoftCorner/lb-datePlanner.png`} className="me-3" alt="datePlanner" height={20} width={20} />
            <span>Date Planner</span>
          </button>

          <button className="bg-transparent border-0 p-0" disabled={!isMatched} onClick={() => nav(`/SoftCorner/LifePartner`)}>
            <img src={`${BASE_URL_STATIC}/SoftCorner/lb-lifePartner.png`} className="me-3" alt="lifepartner" height={20} width={20} />
            <span>Life Partner</span>
          </button>
        </div>

        {/* Div 4 */}
        {/* <button className="sc-lb-div-4" disabled={btnEnable} onClick={() => nav(`/SoftCorner/ProposeOption-Gifts`)}>
          <img src={`${BASE_URL_STATIC}/SoftCorner/lb-gift.png`} alt="gift" height="100%" width="100%" />
        </button> */}

        <Link to={`/SoftCorner/ProposeOption-Gifts`} className="">
          <img src={`${BASE_URL_STATIC}/SoftCorner/lb-gift.png`} alt="gift" height="100%" width="100%" />
        </Link>
      </section>
    </>
  );
};

export default LeftBlock;
