import React, { useState, useContext } from "react";
import { ApiContext } from "../UseContext/ApiCall";
import { useEffect } from "react";
import { BASEAPIURL } from "../../../config";
import axios from "axios";
import { checkAccountPrivacy, createNotification } from "../UseContext/ApiHelpers";
import toast from "react-hot-toast";
import FollowRequest from "./FollowRequest";

// --- Status --- 
// 0 = Follow
// 3 = Requested
// 4 = Followback
//   =  Following

const FollowUnfollowButton = ({ otherUserId, type }) => {

  const [delOpen, setDelOpen] = useState(false);
  const [status, setStatus] = useState(0); 

  let action;

  const {fetchFollowStatus, setOtherUserId, config, getFollowerList, profileUserId, getFollowingList} = useContext(ApiContext);

  const handleFollowUnfollow = async (otherUserId) => {
    setOtherUserId(otherUserId);
    //For notification

    checkAccountPrivacy(otherUserId , config)
    .then(async(res) => {
      console.log("status", res, action, status); 
      
      if (res==='private' && action === 'follow') {
        const data = {
          targetId: otherUserId,
          userType: "user",     
          targetEvent: "follow",
          targetType: "follow",
          notificationType: "emilo",
          // targetEventId: postId,
          isSeen: 0,
        };
     
        createNotification(data, config);

        toast.success("Follow request sent");

        await axios.post(`${BASEAPIURL}/user/followuser`, { followingID: otherUserId, action: action }, config)
        .then(async (res) => {
          setStatus( res.data.status);
          getFollowerList(profileUserId);
          getFollowingList(profileUserId);
        })
        .catch((err) => {
          console.log("err : ", err)
          return null;
        });
      } else if(res==='private' && status === 3){
        setDelOpen(true); 
      } else if(res==='private' && status === 1){
        setDelOpen(true); 
      } else {
        await axios.post(`${BASEAPIURL}/user/followuser`, { followingID: otherUserId, action: action }, config)
        .then(async (res) => {
          console.log("res.data.status", res.data.status)

          setStatus(res.data.status);
          getFollowerList(profileUserId);
          getFollowingList(profileUserId);
        })
        .catch((err) => {
          console.log("err : ", err)
          return null;
        });

        toast.success("Followed  successfuly");
      };
    });
  
    if(status === 4){
      action = status ? "follow" : "unfollow" ;
    } else{
      action = status ? "unfollow" : "follow" ;
    };
  };

  useEffect(() => {
   fetchFollowStatus(otherUserId,  setStatus);
  }, [otherUserId]);

  return (
    <>
      <FollowRequest 
        otherUserId={otherUserId} 
        getFollowerList={getFollowerList} 
        getFollowingList={getFollowingList}   
        status={status} setStatus={setStatus} 
        delOpen={delOpen} setDelOpen={setDelOpen} 
      />

      {
        type === 'flix' 
        ?
        <button type="button" className="followUnfollowBtn_flix fs-12" onClick={() => handleFollowUnfollow(otherUserId)}>
          {status === 0 ? "Follow" : status === 4 ? "Followback" : (status=== 3 ? "Requested" : "Following")}
        </button>
        :
        <button type="button" className = { 
          status === 0 
          ?  
          "followUnfollowBtn"  
          :   
          status ===  4  
          ? 
          "followUnfollowBtn"  
          :  
          ( status === 3 
            ? 
            "followUnfollowBtn2" 
            : 
            "followUnfollowBtn3"
          )}  
          disabled ={status === 0 ? false : status === 4 ? false : (status === 3 ? false : false)}    
          onClick={() => handleFollowUnfollow(otherUserId)}>
          {status === 0 ?  "Follow" : status === 4 ? "Followback" : ( status=== 3 ? "Requested" : "Following")}
         </button>
      }
    </>
  );
};

export default FollowUnfollowButton;
